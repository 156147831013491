import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    '../../../assets/css/bootstrap.min.css',
    './forgot-password.component.scss',
  ],
})
export class ForgotPasswordComponent {
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });
  serverErrorMessage?: string = undefined;
  formIsSubmitting = false;
  success = false;

  get emailControl() {
    return this.forgotPasswordForm.get('email') as AbstractControl;
  }

  constructor(
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {}

  onSubmit() {
    this.forgotPasswordForm.markAllAsTouched();
    this.serverErrorMessage = undefined;
    this.success = false;

    if (this.forgotPasswordForm.valid) {
      this.formIsSubmitting = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe({
        error: (res) => {
          if (res.error?.error === 'NOT_FOUND') {
            this.serverErrorMessage = this.translocoService.translate(
              `Die E-Mail wurde nicht gefunden`
            );
          } else {
            this.serverErrorMessage =
              this.translocoService.translate(`Something went wrong`);
          }

          this.formIsSubmitting = false;
        },
        next: () => {
          this.formIsSubmitting = false;
          this.success = true;
        },
      });
    }
  }
}
