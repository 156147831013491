import { Component } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { SectionApprovalModalService } from '../section-approval-modal/section-approval-modal.service';
import { SectionRejectionModalService } from '../section-rejection-modal/section-rejection-modal.service';
import { ProducerPickerModalV4Service } from '../producer-picker-modal-v4/producer-picker-modal-v4.service';
import { UploadProofService } from '../upload-proof-modal/upload-proof.service';

@Component({
  selector: 'app-file-section-actions-bar',
  templateUrl: './file-section-actions-bar.component.html',
  styleUrls: ['./file-section-actions-bar.component.scss'],
})
export class FileSectionActionsBarComponent {
  constructor(
    public requestViewPageService: RequestViewPageService,
    private sectionApprovalModalService: SectionApprovalModalService,
    private sectionRejectionModalService: SectionRejectionModalService,
    private producerPickerModalV4Service: ProducerPickerModalV4Service,
    private uploadProofService: UploadProofService
  ) {}

  downloadCurrentFile() {
    const file =
      this.requestViewPageService.selectedSection!.uploaded_files[
        this.requestViewPageService.selectedUploadFileIndex
      ];
    this.requestViewPageService.downloadFile(file.url, file.title);
  }

  openApprovalModal() {
    this.sectionApprovalModalService.modalEvents.next({ type: 'open' });
  }

  openUploadProofModal() {
    this.uploadProofService.modalEvents.next({ type: 'open' });
  }

  openRejectionModal() {
    this.sectionRejectionModalService.modalEvents.next({ type: 'open' });
  }

  openProducerPickerModal() {
    this.producerPickerModalV4Service.modalEvents.next({ type: 'open' });
  }

  get showProducerButton() {
    return this.requestViewPageService.selectedSection?.status === 2;
  }

  get showUploadProofButton () {
    return (
      this.requestViewPageService.selectedSection?.status === 2 ||
      (this.requestViewPageService.selectedSection?.status === 3 &&
        !!this.requestViewPageService.producerHash)
    );
  }

  get showActionButtons() {
    if (
      this.requestViewPageService.isAgency ||
      this.requestViewPageService.producerHash
    ) {
      return (
        this.requestViewPageService.selectedSection?.status === 2 ||
        (this.requestViewPageService.selectedSection?.status === 3 &&
          !!this.requestViewPageService.producerHash)
      );
    }

    return (
      this.requestViewPageService.selectedSection?.status === 203 ||
      this.requestViewPageService.selectedSection?.status === 303
    );
  }
}
