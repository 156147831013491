import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AppRequest,
  RequestService,
} from '../../backend-services/request.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ClientContact,
  ClientService,
} from '../../backend-services/client.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SendRequestModalService {
  public readonly modalEvents = new Subject<
    { type: 'open'; request: AppRequest } | { type: 'close' }
  >();

  currentRequest?: AppRequest;
  currentContacts?: ClientContact[];

  sendRequestForm = new FormGroup({
    message: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    to: new FormControl([]),
  });

  constructor(
    private clientService: ClientService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.currentRequest = event.request;
          this.fetchContactList();
          break;
        case 'close':
          this.currentRequest = undefined;
          this.resetForm();
      }
    });
  }

  get messageControl() {
    return this.sendRequestForm.get('message')!;
  }

  get toControl() {
    return this.sendRequestForm.get('to')!;
  }

  fetchContactList() {
    this.clientService
      .getClientById(this.currentRequest!.client_id)
      .subscribe((res) => {
        if (res.data.contacts) {
          this.currentContacts = res.data.contacts;
        }
      });
  }

  get remainingContactList(): ClientContact[] {
    return (
      this.currentContacts?.filter(
        (c) => !this.toControl.value.includes(c.email)
      ) || []
    );
  }

  removeRecipient(email: string) {
    const existing: string[] = this.toControl.value;

    const matchingIndex = existing.indexOf(email);
    if (matchingIndex >= 0) {
      existing.splice(matchingIndex, 1);
      this.toControl.setValue(existing);
    }
  }

  resetForm() {
    this.messageControl.reset();
    this.toControl.setValue([]);
  }

  handleSubmit() {
    if (!this.messageControl.valid || this.toControl.value.length === 0) {
      return;
    }

    this.requestService
      .sendEmail({
        request_id: this.currentRequest!.id,
        message: this.messageControl.value,
        recipients: this.toControl.value.join(','),
      })
      .subscribe({
        next: (res) => {
          this.toastr.success(this.translocoService.translate(`Email sent`));

          this.modalEvents.next({ type: 'close' });
        },
        error: (res) => {
          this.toastr.error(res.error.error);
        },
      });
  }
}
