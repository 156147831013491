import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { SortableTableHeaderComponent } from './sortable-table-header/sortable-table-header.component';
import { CompanyNameDisplayComponent } from './company-name-display/company-name-display.component';
import { PublicNavbarComponent } from './public-navbar/public-navbar.component';
import { RouterModule } from '@angular/router';
import { FileValidationStatusComponent } from './file-validation-status/file-validation-status.component';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { RequestListFilePreviewComponent } from './request-list-file-preview/request-list-file-preview.component';
import { AccordianItemComponent } from './accordian-item/accordian-item.component';
import { StatusDisplayComponent } from './status-display/status-display.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PaginationComponent,
    SortableTableHeaderComponent,
    CompanyNameDisplayComponent,
    PublicNavbarComponent,
    FileValidationStatusComponent,
    CustomDropdownComponent,
    RequestListFilePreviewComponent,
    AccordianItemComponent,
    StatusDisplayComponent,
    CustomModalComponent,
  ],
  imports: [CommonModule, RouterModule, TranslocoModule, FormsModule],
  exports: [
    PaginationComponent,
    SortableTableHeaderComponent,
    CompanyNameDisplayComponent,
    PublicNavbarComponent,
    FileValidationStatusComponent,
    CustomDropdownComponent,
    RequestListFilePreviewComponent,
    AccordianItemComponent,
    StatusDisplayComponent,
    CustomModalComponent,
  ],
})
export class SharedComponentsModule {}
