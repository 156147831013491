import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '../../page-not-found/page-not-found.component';
import { ProfileComponent } from '../../account/profile/profile.component';
import { UsersListViewPageComponent } from '../../user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from '../../user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from '../../user-management/customer/manage-single-customer.component';
import { AdminRoutes } from './admin-routes';
import { ClientsListViewPageComponent } from '../../client-management/clients-list-view-page/clients-list-view-page.component';
import { ClientDetailViewPageComponent } from '../../client-management/client-detail-view-page/client-detail-view-page.component';
import { RequestManagementLayoutComponent } from '../../request-management/request-management-layout/request-management-layout.component';
import { RequestsListViewPageComponent } from '../../request-management/requests-list-view-page/requests-list-view-page.component';
import { ClientManagementLayoutComponent } from '../../client-management/client-management-layout/client-management-layout.component';
import { EditRequestPageComponent } from '../../request-management/edit-request-page/edit-request-page.component';
import { CustomerSettingsComponent } from '../../customer-settings/customer-settings.component';
import {
  PackageUpgradeViewComponent
} from "../../package-management/package-upgrade-view/package-upgrade-view.component";

export const AdminLayoutRoutes: Routes = [
  {
    path: AdminRoutes.Account,
    children: [{ path: AdminRoutes.MyProfile, component: ProfileComponent }],
  },
  {
    path: AdminRoutes.UserManagement,
    children: [
      { path: '', component: UsersListViewPageComponent },
      {
        path: `${AdminRoutes.ManageSingleUser}`,
        component: ManageSingleUserComponent,
      },
      {
        path: `${AdminRoutes.ManageSingleUser}/:id`,
        component: ManageSingleUserComponent,
      },
      {
        path: `${AdminRoutes.ManageSingleCustomer}`,
        component: ManageSingleCustomerComponent,
      },
      {
        path: `${AdminRoutes.ManageSingleCustomer}/:id`,
        component: ManageSingleCustomerComponent,
      },
    ],
  },
  {
    path: AdminRoutes.ClientManagement,
    component: ClientManagementLayoutComponent,
    children: [
      { path: '', component: ClientsListViewPageComponent },
      { path: ':id', component: ClientDetailViewPageComponent },
    ],
  },
  {
    path: AdminRoutes.ProducerManagement,
    component: ClientManagementLayoutComponent,
    children: [
      {
        path: '',
        component: ClientsListViewPageComponent,
        data: { isProducer: true },
      },
      {
        path: ':id',
        component: ClientDetailViewPageComponent,
        data: { isProducer: true },
      },
    ],
  },
  {
    path: AdminRoutes.RequestManagement,
    component: RequestManagementLayoutComponent,
    children: [
      { path: '', component: RequestsListViewPageComponent },
      {
        path: 'folders/:request_folder_id',
        component: RequestsListViewPageComponent,
      },
      { path: 'new', component: EditRequestPageComponent },
      { path: 'edit/:id', component: EditRequestPageComponent },
    ],
  },
  {
    path: AdminRoutes.CustomerSettings,
    component: CustomerSettingsComponent,
  },
  {
    path: AdminRoutes.PackageManagement,
    component: PackageUpgradeViewComponent,
  },


  { path: '**', component: PageNotFoundComponent },
];

export declare interface RouteInfo {
  path: string;
  title?: string;
  class: string;
  iconName?: string;
  iconOutlined?: boolean;
  subRoutes?: Array<RouteInfo>;
}
