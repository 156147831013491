import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestViewPageService } from '../request-view-page.service';
import { FormControl, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SectionRejectionModalService {
  modalEvents: Subject<
    | {
        type: 'open';
      }
    | { type: 'close' }
  > = new Subject();

  rejectionCommentControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);

  constructor(
    private requestViewPageService: RequestViewPageService,
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {}

  rejectCurrentSection() {
    const sectionGroupIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionIndex;

    if (
      sectionGroupIndex !== undefined &&
      sectionIndex !== undefined &&
      this.requestViewPageService.requestData &&
      this.requestViewPageService.requestData.section_groups
    ) {
      const today = dayjs().toISOString();
      let title = 'Agency Member';

      if (!!this.requestViewPageService.producerHash) {
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex].status = 302;

        title = this.translocoService.translate(`Producer`);
      } else if (this.requestViewPageService.isAgency) {
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex].status = 202;

        title = `${this.authService.profile.value!.first_name} ${
          this.authService.profile.value!.last_name
        }`;
      } else {
        title = this.translocoService.translate(`Client`);

        if (
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status === 203
        ) {
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status = 204;
        }

        if (
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status === 303
        ) {
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status = 304;
        }
      }

      this.requestViewPageService.requestData.section_groups[
        sectionGroupIndex
      ].sections[sectionIndex].section_comments.push({
        notified: false,
        comment: this.rejectionCommentControl.value,
        request_section_id:
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex]!.id,
        title,
        created_at: today,
        updated_at: today,
      });

      this.requestViewPageService.handleRequestDataChange();
    }

    this.modalEvents.next({ type: 'close' });
  }
}
