<div class="w-full container mx-auto">
  <div class="w-full bg-white drop-shadow-md p-10 rounded-2xl">
    <div class="w-full grid grid-cols-1 gap-5">
      <!--Header-->
      <div class="w-full">
        <h3 class="text-2xl font-black mb-6">
          {{ section?.title }}
        </h3>
        <p class="text-base font-light text-justify">
          {{ section?.description }}
        </p>
      </div>

      <div
        class="w-full flex items-start"
        *ngFor="let uploadedFile of sectionState?.uploadedFiles; index as idx"
        (click)="setPreviewFile(uploadedFile)"
      >
        <img
          [src]="
            sanitizer.bypassSecurityTrustResourceUrl(uploadedFile.thumbnail_url)
          "
          alt="thumbnail"
          class="w-48 h-28 mr-4"
        />
        <div class="w-full">
          <h5 class="text-base text-black font-bold">
            {{
              uploadedFile.file_name.length > 20
                ? uploadedFile.file_name.substring(0, 20) + "..."
                : uploadedFile.file_name
            }}
          </h5>
          <p class="text-black font-light text-xs 2xl:text-sm">
            {{
              uploadedFile.file_size > 1000000
                ? (uploadedFile.file_size / 1000000).toFixed(2) + " MB"
                : (uploadedFile.file_size / 1000).toFixed(2) + " KB"
            }}
          </p>
          <div class="my-2 animate-spin rounded-full h-5 w-5 border-t border-gray-500"></div>
<!--          <button-->
<!--            (click)="downloadImage(uploadedFile.url, uploadedFile.file_name)"-->
<!--            class="text-base text-blue-500 font-bold relative"-->
<!--          >-->
<!--            Download-->
<!--          </button>-->
<!--          <span> | </span>-->
<!--          <button-->
<!--            class="text-base text-[#A3333D] font-bold relative"-->
<!--            (click)="removeTmpFile(idx, uploadedFile.preview_url)"-->
<!--          >-->
<!--            Datei Löschen-->
<!--          </button>-->
        </div>
        <div
          [attr.aria-valid]="uploadedFile.is_valid"
          class="flex group text-white stroke-white aria-[valid=true]:bg-green-500 aria-[valid=false]:bg-red-600 inline-flex items-center justify-center h-5 text-xs 2xl:text-sm font-semibold rounded-full dark:bg-gray-700 dark:text-gray-300 w-full"
        >
          <svg
            class="w-3 h-3 group-aria-[valid=false]:hidden"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 12"
            fill="transparent"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5.917 5.724 10.5 15 1.5"
            />
          </svg>
          <svg
            class="w-3 h-3 hidden group-aria-[valid=false]:block fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="transparent"
          >
            <circle cx="12" cy="19" r="2" />
            <path d="M10 3h4v12h-4z" />
          </svg>
          <p
            class="text-white text-xs ml-4 group-aria-[valid=false]:ml-2"
            *ngIf="uploadedFile.is_valid"
          >
            Datencheck erfolgreich
          </p>
          <p
            class="text-white text-xs ml-4 group-aria-[valid=false]:ml-2"
            *ngIf="!uploadedFile.is_valid"
          >
            Datencheck nicht erfolgreich
          </p>
        </div>
      </div>

      <div
        class="w-full flex items-start"
        *ngFor="let uploadedFile of sectionState?.existingFiles"
        (click)="setPreviewFile(uploadedFile)"
      >
        <img
          [src]="
            sanitizer.bypassSecurityTrustResourceUrl(uploadedFile.thumbnail_url)
          "
          alt="pdf thumbnail"
          class="w-48 h-28 mr-4"
        />
        <div class="w-full">
          <h5 class="text-base text-black font-bold">
            {{
              uploadedFile.title.length > 20
                ? uploadedFile.title.substring(0, 20) + "..."
                : uploadedFile.title
            }}
          </h5>
          <p class="text-black font-light text-xs 2xl:text-sm">
            {{
              uploadedFile.file_size > 1000000
                ? (uploadedFile.file_size / 1000000).toFixed(2) + " MB"
                : (uploadedFile.file_size / 1000).toFixed(2) + " KB"
            }}
          </p>
          <button
            (click)="downloadImage(uploadedFile.url, uploadedFile.title)"
            class="text-base text-blue-500 font-bold relative"
          >
            Download
          </button>
          <span> | </span>
          <button
            class="text-base text-[#A3333D] font-bold relative"
            (click)="
              removeExistingFile(uploadedFile.id, uploadedFile.preview_url)
            "
          >
            Datei Löschen
          </button>
        </div>
        <div
          [attr.aria-valid]="true"
          class="group text-white stroke-white aria-[valid=true]:bg-green-500 aria-[valid=false]:bg-red-600 inline-flex items-center justify-center h-5 text-xs 2xl:text-sm font-semibold rounded-full dark:bg-gray-700 dark:text-gray-300 w-full"
        >
          <svg
            class="w-3 h-3 group-aria-[valid=false]:hidden"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 12"
            fill="transparent"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5.917 5.724 10.5 15 1.5"
            />
          </svg>
          <svg
            class="w-3 h-3 hidden group-aria-[valid=false]:block fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="transparent"
          >
            <circle cx="12" cy="19" r="2" />
            <path d="M10 3h4v12h-4z" />
          </svg>
          <p class="text-white text-xs ml-4 group-aria-[valid=false]:ml-2">
            Datencheck erfolgreich
          </p>
        </div>
      </div>

      <!--Sections-->
      <div class="w-full" data-accordion="collapse">
        <h5
          *ngIf="section?.info_files?.length"
          [id]="'section-' + section!.id + '-accordion-info-files-heading'"
        >
          <button
            type="button"
            [attr.data-accordion-target]="
              '#section-' + section!.id + '-accordion-info-files'
            "
            aria-expanded="false"
            [attr.aria-controls]="
              'section-' + section!.id + '-accordion-info-files'
            "
            class="flex group items-center justify-between w-full py-4 border-t-2 text-black border-gray-200 fill-black focus:bg-white hover:bg-gray-100 gap-3 aria-expanded:text-primaryBtn aria-expanded:fill-primaryBtn"
          >
            <span
              class="text-base group-aria-[expanded=false]:text-black font-bold"
            >
              Infos und Vorlagen
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="group-aria-expanded:hidden"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="hidden group-aria-expanded:block"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13H5v-2h14v2z" />
            </svg>
          </button>
        </h5>
        <div
          *ngIf="section?.info_files?.length"
          [id]="'section-' + section!.id + '-accordion-info-files'"
          class="hidden"
          [attr.aria-labelledby]="
            'section-' + section!.id + '-accordion-info-files-heading'
          "
        >
          <div class="w-full grid grid-cols-1 2xl:grid-cols-2 gap-5 pt-3 pb-5">
            <ng-container *ngFor="let infoFile of section!.info_files">
              <div class="w-full flex items-start justify-start">
                <img
                  class="w-11 h-16"
                  [src]="
                    sanitizer.bypassSecurityTrustResourceUrl(
                      infoFile.fileType.icon_svg!
                    )
                  "
                  alt="{{ infoFile.fileType.name }}"
                />
                <div class="w-full ml-4 lg:ml-2">
                  <h6 class="text-black text-xs 2xl:text-sm font-bold">
                    {{ infoFile.title }}
                  </h6>
                  <p class="text-black font-light text-xs 2xl:text-sm w-full 2xl:w-52">
                    {{ infoFile.description }}
                  </p>
                </div>
                <a
                  [href]="sanitizer.bypassSecurityTrustUrl(infoFile.url)"
                  target="_blank"
                  download
                  class="ml-4 md:ml-2"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="download-cloud-02">
                      <path
                        id="Vector"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.6665 5C7.69594 5 3.6665 9.02944 3.6665 14C3.6665 16.4796 4.66795 18.7234 6.29083 20.3522C6.68064 20.7435 6.67949 21.3766 6.28825 21.7664C5.89701 22.1563 5.26385 22.1551 4.87404 21.7639C2.89291 19.7755 1.6665 17.03 1.6665 14C1.6665 7.92487 6.59137 3 12.6665 3C16.6706 3 20.1738 5.13994 22.0967 8.33389C26.6544 8.38578 30.3332 12.0966 30.3332 16.6667C30.3332 19.2548 29.1521 21.568 27.3033 23.0948C26.8774 23.4465 26.2471 23.3864 25.8955 22.9606C25.5438 22.5347 25.6039 21.9044 26.0297 21.5527C27.4382 20.3895 28.3332 18.633 28.3332 16.6667C28.3332 13.1689 25.4976 10.3333 21.9998 10.3333C21.3633 10.3333 20.7586 10.0017 20.4265 9.43802C18.8601 6.77992 15.9706 5 12.6665 5ZM15.9998 15C16.5521 15 16.9998 15.4477 16.9998 16V25.5858L20.6261 21.9596C21.0166 21.569 21.6498 21.569 22.0403 21.9596C22.4308 22.3501 22.4308 22.9832 22.0403 23.3738L16.7069 28.7071C16.3164 29.0976 15.6833 29.0976 15.2927 28.7071L9.9594 23.3738C9.56887 22.9832 9.56887 22.3501 9.9594 21.9596C10.3499 21.569 10.9831 21.569 11.3736 21.9596L14.9998 25.5858V16C14.9998 15.4477 15.4476 15 15.9998 15Z"
                        fill="#05070B"
                      />
                    </g>
                  </svg>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
        <h5 [id]="'section-' + section!.id + '-accordion-data-check-heading'">
          <button
            type="button"
            [attr.data-accordion-target]="
              '#section-' + section!.id + '-accordion-data-check'
            "
            aria-expanded="false"
            [attr.aria-controls]="
              'section-' + section!.id + '-accordion-data-check'
            "
            class="flex group items-center justify-between w-full py-4 border-t-2 text-black border-gray-200 fill-black focus:bg-white hover:bg-gray-100 gap-3 aria-expanded:text-primaryBtn aria-expanded:fill-primaryBtn"
          >
            <span
              class="text-base group-aria-[expanded=false]:text-black font-bold"
            >
              Datencheck Anforderungen
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="group-aria-expanded:hidden"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="hidden group-aria-expanded:block"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13H5v-2h14v2z" />
            </svg>
          </button>
        </h5>
        <div
          [id]="'section-' + section!.id + '-accordion-data-check'"
          class="hidden"
          [attr.aria-labelledby]="
            'section-' + section!.id + '-accordion-data-check-heading'
          "
        >
          <div class="w-full grid grid-cols-1 gap-5 pt-3 pb-5">
            <app-file-validation-status
              [validationData]="
                sectionState!.validationData.maxFileSizeValidation
              "
            ></app-file-validation-status>
            <app-file-validation-status
              [validationData]="
                sectionState!.validationData.maxFileCountValidation
              "
            ></app-file-validation-status>
            <app-file-validation-status
              [validationData]="
                sectionState!.validationData.expectedFileTypesValidation
              "
            ></app-file-validation-status>

            <div *ngIf="!sectionState!.uploadedFiles?.length">
              <ng-container
                *ngFor="let expectedFileType of section!.expected_files"
              >
                <div
                  class="mb-8"
                  *ngIf="
                    sectionState!.validationData.expectedFileValidations.get(
                      expectedFileType.file_type.name
                    )?.size
                  "
                >
                  <div class="text-center mb-2">
                    {{ expectedFileType.file_type.name }} Validations
                  </div>
                  <app-file-validation-status
                    *ngFor="
                      let entry of sectionState!.validationData.expectedFileValidations.get(
                        expectedFileType.file_type.name
                      )! | keyvalue
                    "
                    [validationData]="{
                      title: entry.value!.title,
                      content: entry.value!.content,
                      isValid: entry.value!.isValid
                    }"
                  ></app-file-validation-status>
                </div>
              </ng-container>
            </div>
            <div *ngIf="sectionState!.uploadedFiles!.length">
              <div
                class="mb-8 flex flex-col gap-y-2"
                *ngFor="let uploadedFile of sectionState!.uploadedFiles"
              >
                <ng-container *ngIf="uploadedFile.file_type?.name">
                  <app-file-validation-status
                    *ngFor="
                      let entry of sectionState!.validationData.expectedFileValidations.get(
                        uploadedFile.file_type!.name
                      )! | keyvalue
                    "
                    [validationData]="{
                      title: entry.value!.title,
                      content: entry.value!.content,
                      isValid: entry.value!.isValid
                    }"
                  ></app-file-validation-status>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <h5
          *ngIf="section?.file_history?.length"
          [id]="'section-' + section!.id + '-accordion-file-versions-heading'"
        >
          <button
            type="button"
            [attr.data-accordion-target]="
              '#section-' + section!.id + '-accordion-file-versions'
            "
            aria-expanded="false"
            [attr.aria-controls]="
              'section-' + section!.id + '-accordion-file-versions'
            "
            class="flex group items-center justify-between w-full py-4 border-t-2 text-black border-gray-200 fill-black focus:bg-white hover:bg-gray-100 gap-3 aria-expanded:text-primaryBtn aria-expanded:fill-primaryBtn"
          >
            <span
              class="text-base group-aria-[expanded=false]:text-black font-bold"
            >
              Versionsverlauf
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="group-aria-expanded:hidden"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              class="hidden group-aria-expanded:block"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 13H5v-2h14v2z" />
            </svg>
          </button>
        </h5>
        <div
          *ngIf="section?.file_history?.length"
          [id]="'section-' + section!.id + '-accordion-file-versions'"
          class="hidden"
          [attr.aria-labelledby]="
            'section-' + section!.id + '-accordion-file-versions-heading'
          "
        >
          <div
            class="w-full grid grid-cols-1 gap-3 pt-3 pb-5 overflow-auto max-h-44 lg:max-h-72"
          >
            <ng-container *ngFor="let fileHistoryItem of section?.file_history">
              <div class="w-full flex items-start">
                <div class="w-2 mr-4 flex flex-col items-center">
                  <div class="rounded-full bg-gray-300 w-2 h-2"></div>
                  <div class="bg-gray-300 w-[2px] h-[70px]"></div>
                </div>
                <div class="w-full">
                  <p class="text-black font-light text-xs 2xl:text-sm">
                    {{ fileHistoryItem.timestamp | date: "dd. MMM, HH:mm" }} |
                    {{ fileHistoryItem.action | titlecase }}
                  </p>
                  <h5
                    class="text-base group-aria-[expanded=false]:text-black font-bold my-1"
                  >
                    {{ fileHistoryItem.title }}
                  </h5>
                  <div class="flex text-black font-light text-xs 2xl:text-sm gap-2">
                    <div>{{ fileHistoryItem.file_type.name }} {{ " | " }}</div>
                    <div>
                      {{ fileHistoryItem.file_size | fileSize }} {{ " | " }}
                    </div>
                    <button
                      (click)="
                        downloadImage(
                          fileHistoryItem.url,
                          fileHistoryItem.title
                        )
                      "
                      class="text-blue-500 font-medium relative"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div></ng-container
            >
          </div>
        </div>
      </div>

      <!--      Image Uploader-->
      <div class="w-full" *ngIf="canUpload">
        <h5 class="text-base font-bold mb-6">Datei hochladen</h5>

        <!--File uploader container-->
        <div class="flex items-center justify-center w-full">
          <label
            class="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-secondary-50 p-6"
            [for]="section?.id + 'file-upload-input'"
          >
            <input
              #fileUploaderRef
              [id]="section?.id + 'file-upload-input'"
              type="file"
              class="opacity-0 absolute left-0 top-0 w-full h-full"
              (change)="onFileChange($event)"
            />
            <div class="flex flex-col items-center justify-center">
              <svg
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="bs:cloud-upload">
                  <g id="Vector">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5225 5.0325C20.2706 1.80051 25.0509 0.0155194 30 0C40.0875 0 48.4613 7.5 49.3725 17.1712C55.3425 18.015 60 23.0137 60 29.1487C60 35.8838 54.3825 41.25 47.5763 41.25H37.5C37.0027 41.25 36.5258 41.0525 36.1742 40.7008C35.8225 40.3492 35.625 39.8723 35.625 39.375C35.625 38.8777 35.8225 38.4008 36.1742 38.0492C36.5258 37.6975 37.0027 37.5 37.5 37.5H47.58C52.4212 37.5 56.25 33.705 56.25 29.1487C56.25 24.5887 52.425 20.7938 47.5763 20.7938H45.7013V18.9188C45.705 10.5938 38.73 3.75 30 3.75C25.9495 3.76618 22.0382 5.22913 18.9713 7.875C16.1325 10.32 14.6475 13.2675 14.6475 15.5813V17.2613L12.9788 17.445C7.74 18.0187 3.75 22.32 3.75 27.4425C3.75 32.9437 8.3625 37.5 14.1788 37.5H22.5C22.9973 37.5 23.4742 37.6975 23.8258 38.0492C24.1775 38.4008 24.375 38.8777 24.375 39.375C24.375 39.8723 24.1775 40.3492 23.8258 40.7008C23.4742 41.0525 22.9973 41.25 22.5 41.25H14.1788C6.405 41.25 0 35.1225 0 27.4425C0 20.8312 4.7475 15.3562 11.0325 13.9688C11.5688 10.7325 13.65 7.5075 16.5225 5.0325Z"
                      fill="#05070B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.6725 15.5475C28.8467 15.3729 29.0536 15.2344 29.2814 15.1398C29.5092 15.0453 29.7534 14.9966 30 14.9966C30.2466 14.9966 30.4908 15.0453 30.7186 15.1398C30.9464 15.2344 31.1533 15.3729 31.3275 15.5475L42.5775 26.7975C42.9296 27.1496 43.1274 27.6271 43.1274 28.125C43.1274 28.6229 42.9296 29.1004 42.5775 29.4525C42.2254 29.8046 41.7479 30.0024 41.25 30.0024C40.7521 30.0024 40.2746 29.8046 39.9225 29.4525L31.875 21.4012V54.375C31.875 54.8723 31.6775 55.3492 31.3258 55.7008C30.9742 56.0525 30.4973 56.25 30 56.25C29.5027 56.25 29.0258 56.0525 28.6742 55.7008C28.3225 55.3492 28.125 54.8723 28.125 54.375V21.4012L20.0775 29.4525C19.7254 29.8046 19.2479 30.0024 18.75 30.0024C18.2521 30.0024 17.7746 29.8046 17.4225 29.4525C17.0704 29.1004 16.8726 28.6229 16.8726 28.125C16.8726 27.6271 17.0704 27.1496 17.4225 26.7975L28.6725 15.5475Z"
                      fill="#05070B"
                    />
                  </g>
                </g>
              </svg>
              <h5 class="text-base font-bold mt-5">Datei hierherziehen oder</h5>
            </div>
            <button
              class="bg-primaryBtn hover:bg-primaryBtnHover text-white w-full rounded-2xl font-bold text-base p-2 mt-3"
              (click)="fileUploaderRef.click()"
            >
              Datei auswählen
            </button>
          </label>
        </div>
      </div>

      <button
        *ngIf="true"
        class="text-black text-base font-light p-3 2xl:p-4 bg-gray-100 w-full mt-2 rounded-2xl"
      >
        Ihre hochgeladenen Daten werden vollautomatisch mit unserem Datencheck
        überprüft.
      </button>
    </div>
  </div>
</div>
