import { Component, ElementRef, ViewChild } from '@angular/core';
import { CreateFolderModalService } from './create-folder-modal.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { RequestFolderService } from '../../backend-services/request-folder.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-folder-modal',
  templateUrl: './create-folder-modal.component.html',
  styleUrls: ['./create-folder-modal.component.scss'],
})
export class CreateFolderModalComponent {
  @ViewChild('toggleCreateFolderModalBtn') toggleCreateFolderModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('showCreateFolderModalBtn') showCreateFolderModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('hideCreateFolderModalBtn') hideCreateFolderModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('requestFolderToggleConfirmDeleteModalBtn')
  requestFolderToggleConfirmDeleteModalBtn: ElementRef | undefined;

  allCustomers: Customer[] = [];
  currentFolderId?: number = undefined;

  constructor(
    public createFolderModalService: CreateFolderModalService,
    private customerService: CustomerService,
    private authService: AuthService,
    public requestFolderService: RequestFolderService,
    private toastr: ToastrService
  ) {
    this.requestFolderService.getRequestFolders();

    this.createFolderModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'edit':
        case 'open':
          this.showCreateFolderModalBtn?.nativeElement?.click();
          break;
        case 'close':
          this.hideCreateFolderModalBtn?.nativeElement?.click();
      }
    });

    this.authService.isSuperAdmin.subscribe((isSuperAdmin) => {
      if (isSuperAdmin) {
        // fetch customers if user is SuperAdmin
        this.customerService.getCustomers().subscribe({
          next: (res) => {
            if (res.success) {
              this.allCustomers = res.data;
            }
          },
          error: (err) => console.log(err),
        });
      }
    });
  }

  closeModal() {
    this.createFolderModalService.modalEvents.next({ type: 'close' });
  }

  changeParentFolderValue(newValue?: number) {
    this.createFolderModalService.parentIdControl.setValue(newValue);
  }

  initDeleteFolder() {
    this.requestFolderToggleConfirmDeleteModalBtn?.nativeElement.click();
  }

  handleFolderDelete() {
    const currentFolderId = parseInt(
      this.createFolderModalService.createFolderForm.get('id')!.value
    );
    if (this.createFolderModalService) {
      this.requestFolderService.deleteRequestFolder(currentFolderId).subscribe({
        next: () => {
          this.toastr.info('Folder deleted');
          this.createFolderModalService.modalEvents.next({ type: 'close' });
          this.createFolderModalService.modalEvents.next({ type: 'deleted' });
        },
      });
    }
  }
}
