import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EditClientModalService } from './edit-client-modal.service';
import { ClientSuccessorPickerModalService } from '../clients-list-view-page/client-successor-picker-modal.service';

@Component({
  selector: 'app-edit-client-modal',
  templateUrl: './edit-client-modal.component.html',
  styleUrls: ['./edit-client-modal.component.scss'],
})
export class EditClientModalComponent implements OnInit {
  @ViewChild('toggleClientModalBtn') toggleClientModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('showClientModalBtn') showClientModalBtn: ElementRef | undefined;
  @ViewChild('hideClientModalBtn') hideClientModalBtn: ElementRef | undefined;

  @ViewChild('showContactModalBtn') showContactModalBtn: ElementRef | undefined;
  @ViewChild('hideContactModalBtn') hideContactModalBtn: ElementRef | undefined;

  @ViewChild('toggleConfirmCloseModalBtn') toggleConfirmCloseModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('toggleConfirmDeleteModalBtn') toggleConfirmDeleteModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('confirmDeleteModal') confirmDeleteModal: ElementRef | undefined;

  constructor(
    public editClientModalService: EditClientModalService,
    private clientSuccessorPickerModalService: ClientSuccessorPickerModalService
  ) {}

  isProducer = false;

  ngOnInit(): void {
    this.editClientModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
        case 'edit':
          this.isProducer = window.location.pathname.startsWith('/producers');
          this.showClientModalBtn?.nativeElement?.click();
          break;
        case 'close':
          this.hideClientModalBtn?.nativeElement?.click();
          break;
      }
    });

    this.editClientModalService.contactModalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
        case 'edit':
          this.hideClientModalBtn?.nativeElement?.click();
          this.showContactModalBtn?.nativeElement?.click();
          break;
        case 'close':
          this.hideContactModalBtn?.nativeElement?.click();

          if (!this.editClientModalService.editNoOpenMode) {
            this.showClientModalBtn?.nativeElement?.click();
          }
      }
    });
  }

  editContact(id: number) {
    this.editClientModalService.contactModalEvents.next({
      type: 'edit',
      id: id,
    });
  }

  addContact() {
    this.editClientModalService.contactModalEvents.next({
      type: 'new',
    });
  }

  initContactModalClose() {
    if (
      JSON.stringify(this.editClientModalService.contactFormInitialValue) !==
      JSON.stringify(this.editClientModalService.contactForm.value)
    ) {
      this.toggleConfirmCloseModalBtn?.nativeElement?.setAttribute(
        'data-close-modal-type',
        'contact'
      );
      this.toggleConfirmCloseModalBtn?.nativeElement?.click();
    } else {
      this.editClientModalService.contactModalEvents.next({ type: 'close' });
    }
  }

  initClientModalClose() {
    if (
      JSON.stringify({
        company_name:
          this.editClientModalService.companyNameControl.value || '',
        contacts: this.editClientModalService.contacts,
      }) !== JSON.stringify(this.editClientModalService.clientInitialValue)
    ) {
      this.toggleConfirmCloseModalBtn?.nativeElement?.setAttribute(
        'data-close-modal-type',
        'client'
      );
      this.toggleConfirmCloseModalBtn?.nativeElement?.click();
    } else {
      this.editClientModalService.modalEvents.next({ type: 'close' });
    }
  }

  handleConfirmCloseClick() {
    const target = this.toggleConfirmCloseModalBtn?.nativeElement?.getAttribute(
      'data-close-modal-type'
    );
    if (target === 'contact') {
      this.editClientModalService.contactModalEvents.next({ type: 'close' });
    } else {
      this.editClientModalService.modalEvents.next({ type: 'close' });
    }
  }

  initDeleteContact(id: number) {
    this.clientSuccessorPickerModalService.modalEvents.next({
      type: 'init',
      client_id: this.editClientModalService.currentClientId!,
      contact_id: id,
    });

    this.editClientModalService.currentContactId = id;
    this.confirmDeleteModal?.nativeElement?.setAttribute(
      'data-delete-type',
      'contact'
    );
    this.toggleConfirmDeleteModalBtn?.nativeElement?.click();
  }

  initDeleteClient() {
    this.confirmDeleteModal?.nativeElement?.setAttribute(
      'data-delete-type',
      'client'
    );
    this.toggleConfirmDeleteModalBtn?.nativeElement?.click();
  }
}
