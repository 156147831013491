import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestService } from 'src/app/backend-services/request.service';
import { ToastrService } from 'ngx-toastr';
import { RequestViewPageService } from '../request-view-page.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class FileDeleteConfirmationModalService {
  modalEvents: Subject<
    | {
        type: 'open';
        data: {
          request_hash: string;
          section_id: number;
          uploaded_file_id: number;
        };
      }
    | { type: 'close' }
  > = new Subject();

  currentData?: {
    request_hash: string;
    section_id: number;
    uploaded_file_id: number;
  };

  constructor(
    private requestService: RequestService,
    private requestViewPageService: RequestViewPageService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          this.currentData = ev.data;
      }
    });
  }

  deleteFile() {
    this.requestService.removeFile(this.currentData!).subscribe({
      next: () => {
        this.toastr.success(this.translocoService.translate(`File Deleted`));
        this.modalEvents.next({ type: 'close' });
        this.requestViewPageService.requestEvents.next({ type: 'updated' });
      },
      error: (res) => {
        this.toastr.error(
          res.error?.error ||
            this.translocoService.translate(`Something went wrong`)
        );
        this.modalEvents.next({ type: 'close' });
      },
    });
  }
}
