import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CommentsModalService} from "./comments-modal.service";

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {
  @ViewChild('showFileUploadModalBtn') showFileUploadModalBtn?: ElementRef;
  @ViewChild('hideFileUploadModalBtn') hideFileUploadModalBtn?: ElementRef;

  constructor(public commentsModalService: CommentsModalService, private cdr: ChangeDetectorRef) {
    this.commentsModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showFileUploadModalBtn!.nativeElement.click();
          break;
        case 'close':
          this.hideFileUploadModalBtn!.nativeElement.click();
          break;
      }
    });
  }


  ngOnInit(): void {
  }

  closeModal() {
    this.commentsModalService.modalEvents.next({ type: 'close' });
  }

}
