<div
  id="comment-modal"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
>
  <div class="relative w-full max-w-2xl max-h-full">
    <!-- Modal content -->
    <div
      class="relative mx-5 md:mx-0 px-10 pt-10 pb-6 bg-white rounded-2xl shadow dark:bg-gray-700"
    >
      <!-- Modal header -->
      <div
        class="flex items-center justify-between p-3 2xl:p-4 md:p-5 border-b rounded-t dark:border-gray-600"
      >
        <h3 class="text-2xl font-bold dark:text-white">Kommentare</h3>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-hide="comment-modal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div
        class="py-4 md:py-5 gap-4 grid grid-cols-1 overflow-auto max-h-96 lg:max-h-72"
      >
        <ng-container *ngFor="let comment of dummy_comments">
          <div class="flex items-start gap-3 px-2">
            <div class="w-15 h-15">
              <div
                class="inline-flex items-center justify-center w-10 h-10 overflow-hidden dark:bg-gray-100 rounded-full bg-gray-600"
              >
                <span class="font-medium dark:text-gray-600 text-gray-300">{{
                    comment.userName
                  }}</span>
              </div>
            </div>
            <div class="w-full content flex flex-col items-start">
              <div
                class="w-full grid grid-cols-1 md:grid-cols-2 gap-1 items-center"
              >
                <div class="opacity-[0.7] font-medium">
                  {{ comment.userName }}
                </div>
                <div class="opacity-50 text-xs font-medium md:text-end">
                  {{ comment.date | date: "dd. MMMM, hh:mm" }}
                </div>
              </div>
              <div
                class="flex items-start text-justify self-stretch pt-1 pb-3 max-w-[460px]"
              >
                {{ comment.comment }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Modal footer -->
      <div
        class="w-full flex items-center pt-4 md:pt-5 border-t border-gray-200 rounded-b dark:border-gray-600"
      >
        <form class="w-full">
          <div class="w-full flex gap-5">
            <input
              type="text"
              id="comment"
              class="border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-2xl block w-full focus:ring-primaryBtn focus:border-primaryBtn p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-primaryBtn dark:focus:ring-primaryBtn"
              placeholder="Kommentar eingeben..."
              required
            />
            <button
              type="button"
              class="text-white bg-primaryBtn hover:bg-primaryBtnHover font-medium rounded-lg text-xs 2xl:text-sm p-5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Right" clip-path="url(#clip0_2168_895)">
                  <path
                    id="Vector"
                    d="M25.7628 0.237249C25.8753 0.349914 25.9522 0.493145 25.984 0.64915C26.0158 0.805155 26.001 0.967059 25.9415 1.11475L16.4857 24.7536C16.4023 24.9618 16.2632 25.143 16.0835 25.2771C15.9039 25.4113 15.6907 25.4934 15.4674 25.5142C15.2441 25.535 15.0195 25.4939 14.8181 25.3952C14.6167 25.2966 14.4464 25.1443 14.326 24.9551L9.16179 16.8382L1.04492 11.674C0.855308 11.5537 0.702613 11.3834 0.60368 11.1818C0.504747 10.9803 0.463423 10.7553 0.484267 10.5317C0.505111 10.3081 0.587313 10.0947 0.721803 9.91487C0.856293 9.73506 1.03784 9.5959 1.24642 9.51275L24.8853 0.0601242C25.033 0.000669153 25.1949 -0.0140854 25.3509 0.0176934C25.5069 0.0494722 25.6501 0.126384 25.7628 0.238874V0.237249ZM10.7835 16.3637L15.2702 23.413L22.9613 4.186L10.7835 16.3637ZM21.8124 3.03712L2.58542 10.7282L9.63629 15.2133L21.814 3.03712H21.8124Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2168_895">
                    <rect width="26" height="26" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span class="sr-only">comment sent icon</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
