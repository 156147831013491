<div class="flex gap-4 items-center min-w-[80px]">
  <div class="flex">
    <img
      [alt]="first"
      *ngFor="let selectedImage of selectedImages; let first = first"
      class="w-8 h-8 2xl:w-12 2xl:h-12 object-contain border shadow-xl rounded-lg bg-transparent"
      [ngClass]="{'-ml-3': !first}"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(selectedImage.thumbnail_url)
      "
    />
  </div>
  <div *ngIf="remainingCount > 0" class="text-base font-medium  text-primary">+ {{remainingCount}}</div>
</div>
