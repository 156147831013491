<button
  data-modal-target="reject-request-modal"
  data-modal-show="reject-request-modal"
  class="hidden"
  #showRejectRequestModalBtn
></button>
<button
  data-modal-target="reject-request-modal"
  data-modal-hide="reject-request-modal"
  class="hidden"
  #hideRejectRequestModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="reject-request-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-1/2 max-w-5xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-t">
        <h3 class="text-2xl font-bold text-gray-900">Reject Request</h3>
        <button
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeModal()"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="w-3 h-3"
            fill="none"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="px-4 md:px-5 w-full">
        <div class="my-4">
          <label
            for="message"
            class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
          >Message</label
          >
          <textarea
            id="message"
            class="border text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 bg-gray-50 border-gray-300  text-gray-900"
            [formControl]="rejectRequestModalService.messageControl"
          ></textarea>
        </div>
      </div>
      <!-- Modal footer -->
      <div
        class="flex justify-between items-center mt-10 p-3 2xl:p-4 md:p-5 rounded-b"
      >
        <button
          type="button"
          (click)="initClientModalClose()"
          [disabled]="rejectRequestModalService.isSubmitting"
          class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="rejectRequestModalService.handleSubmit()"
          [disabled]="rejectRequestModalService.isSubmitting"
          class="border-2 font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center w-36"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
