import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';

@Component({
  selector: 'app-request-section-list-item',
  templateUrl: './request-section-list-item.component.html',
  styleUrls: ['./request-section-list-item.component.scss'],
})
export class RequestSectionListItemComponent {
  @Input() sectionCollection!: FormGroup;
  @Input() sectionCollectionIndex!: number;
  @Input() sectionIndex!: number;

  constructor(public editRequestModalService: EditRequestModalService) {}

  removeSection() {
    (this.sectionCollection.get('sections') as FormArray).removeAt(
      this.sectionIndex,
      {
        emitEvent: true,
      }
    );
  }

  get isSelected() {
    return (
      this.editRequestModalService.selectedCollectionIndex ===
        this.sectionCollectionIndex &&
      this.editRequestModalService.selectedSectionIndex === this.sectionIndex
    );
  }

  setAsSelected() {
    this.editRequestModalService.selectedCollectionIndex = this.sectionCollectionIndex;
    this.editRequestModalService.selectedSectionIndex = this.sectionIndex
  }
}
