import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import {
  ClientContact,
  ClientService,
} from '../../backend-services/client.service';
import { FormControl } from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root',
})
export class ClientSuccessorPickerModalService {
  public readonly modalEvents = new Subject<
    { type: 'init'; client_id: number; contact_id: number } | ModalEvents
  >();

  public availableContacts?: ClientContact[] = [];
  public selectedSuccessorContactId = new FormControl(undefined);
  clientId?: number;
  contactId?: number;
  isSubmitting = false;

  constructor(private clientService: ClientService, private toastr: ToastrService, private translocoService: TranslocoService) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init':
          this.clientService.getClientById(ev.client_id).subscribe((v) => {
            this.availableContacts = v.data.contacts?.filter(
              (c) => c.id !== ev.contact_id
            );
            if (this.availableContacts && this.availableContacts.length > 0) {
              this.selectedSuccessorContactId.setValue(
                this.availableContacts[0].id
              );
            }
          });
          this.clientId = ev.client_id;
          this.contactId = ev.contact_id;
          break;
        case 'init-close':
          this.closeModal();
      }
    });
  }

  closeModal() {
    this.modalEvents.next({ type: 'close' });
    this.clientId =  undefined;
    this.contactId = undefined;
    this.selectedSuccessorContactId.setValue(undefined);
  }

  handleSubmit() {
    this.isSubmitting = true;
    this.clientService.deleteClientContact(this.clientId!, this.contactId!, this.selectedSuccessorContactId.value).subscribe({
      next: () => {
        this.toastr.success(this.translocoService.translate(`The contact has been deleted!`));
        this.isSubmitting = false;
        this.closeModal();
      },
      error: (res) => {
        this.toastr.error(res.error?.message || this.translocoService.translate(`Something went wrong`));
        this.isSubmitting = false;
        this.closeModal();
      },
    });
  }
}
