<div
  class="flex justify-between w-full border-b border-light-blue3 py-4 mb-6 pl-4"
  [ngClass]="{ 'border-b-4 border-orange1': isSelected }"
>
  <div class="cursor-pointer font-semibold text-primary text-base" (click)="setAsSelected()"># Section {{ sectionIndex + 1 }}</div>
  <button
    (click)="removeSection()"
    [disabled]="editRequestModalService.requestFormGroup.disabled"
  >
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      class=""
      xmlns="http://www.w3.org/2000/svg"
      [ngClass]="{
        'fill-red1 stroke-red1': isSelected,
        'fill-light-blue3 stroke-light-blue3': !isSelected
      }"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 4H0V7C0.530433 7 1.03914 7.21071 1.41421 7.58579C1.78929 7.96086 2 8.46957 2 9V13C2 15.828 2 17.243 2.879 18.121C3.757 19 5.172 19 8 19H10C12.829 19 14.243 19 15.121 18.121C16.001 17.243 16.001 15.828 16.001 13V9C16.001 8.46957 16.2117 7.96086 16.5868 7.58579C16.9619 7.21071 17.4706 7 18.001 7L18 4ZM7.5 9C7.5 8.73478 7.39464 8.48043 7.20711 8.29289C7.01957 8.10536 6.76522 8 6.5 8C6.23478 8 5.98043 8.10536 5.79289 8.29289C5.60536 8.48043 5.5 8.73478 5.5 9V14C5.5 14.2652 5.60536 14.5196 5.79289 14.7071C5.98043 14.8946 6.23478 15 6.5 15C6.76522 15 7.01957 14.8946 7.20711 14.7071C7.39464 14.5196 7.5 14.2652 7.5 14V9ZM12.5 9C12.5 8.73478 12.3946 8.48043 12.2071 8.29289C12.0196 8.10536 11.7652 8 11.5 8C11.2348 8 10.9804 8.10536 10.7929 8.29289C10.6054 8.48043 10.5 8.73478 10.5 9V14C10.5 14.2652 10.6054 14.5196 10.7929 14.7071C10.9804 14.8946 11.2348 15 11.5 15C11.7652 15 12.0196 14.8946 12.2071 14.7071C12.3946 14.5196 12.5 14.2652 12.5 14V9Z"
      />
      <path
        d="M7.06836 1.36999C7.18236 1.26399 7.43336 1.16999 7.78336 1.10299C8.18503 1.03156 8.5924 0.997084 9.00036 0.999986C9.44036 0.999986 9.86836 1.03599 10.2174 1.10299C10.5674 1.16999 10.8174 1.26399 10.9324 1.37099"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </button>
</div>
