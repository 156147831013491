import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-comments',
  templateUrl: './request-comments.component.html',
  styleUrls: ['./request-comments.component.scss']
})
export class RequestCommentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  dummy_comments: any[] = [
    {
      userName: 'Vorname Nachname',
      comment: 'Lorem Ipsum dolor sit amet?',
      date: new Date('2023-11-15T14:20:21.296Z'),
    },
    {
      userName: 'Vorname Nachname',
      comment:
        'Lorem Ipsum lorem Ipsum dolor sit ame dolor sit amet. Ipsum lorem Ipsum dolor sit ame dolor sit amet?',
      date: new Date('2023-11-15T16:43:21.296Z'),
    },
    {
      userName: 'Vorname Nachname',
      comment: 'Lorem Ipsum dolor sit amet?',
      date: new Date('2023-12-21T02:20:21.296Z'),
    },
    {
      userName: 'Vorname Nachname',
      comment: 'Lorem Ipsum dolor sit amet?',
      date: new Date('2023-11-15T14:20:21.296Z'),
    },
    {
      userName: 'Vorname Nachname',
      comment:
        'Lorem Ipsum lorem Ipsum dolor sit ame dolor sit amet. Ipsum lorem Ipsum dolor sit ame dolor sit amet?',
      date: new Date('2023-11-15T16:43:21.296Z'),
    },
    {
      userName: 'Vorname Nachname',
      comment: 'Lorem Ipsum dolor sit amet?',
      date: new Date('2023-12-21T02:20:21.296Z'),
    },
  ]

}
