<div
  [attr.aria-valid]="validationData?.isValid"
  class="w-full flex items-start justify-start group aria-[valid=invalid]:border-2 p-2 aria-[valid=invalid]:border-red-600 aria-[valid=invalid]:rounded-2xl"
>
  <div class="mr-2">
    <span
      class="group-aria-[valid=none]:border group-aria-[valid=none]:border-neutral_gray-300 group-aria-[valid=valid]:bg-green-500 group-aria-[valid=invalid]:hidden inline-flex items-center justify-center w-6 h-6 me-2 text-xs 2xl:text-sm font-semibold text-gray-800 rounded-full dark:bg-gray-700 dark:text-gray-300"
    >
      <svg
        class="w-3 h-3 stroke-gray-300 group-aria-[valid=invalid]:hidden group-aria-[valid=valid]:stroke-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 12"
        fill="transparent"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5.917 5.724 10.5 15 1.5"
        />
      </svg>
    </span>
    <svg
      width="24"
      height="24"
      class="hidden group-aria-[valid=invalid]:block"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2213_926)">
        <path
          d="M17.19 0.219C17.0494 0.0787966 16.859 4.21785e-05 16.6605 0L7.3395 0C7.14097 4.21785e-05 6.95056 0.0787966 6.81 0.219L0.219 6.81C0.0787966 6.95056 4.21785e-05 7.14097 0 7.3395L0 16.6605C4.21785e-05 16.859 0.0787966 17.0494 0.219 17.19L6.81 23.781C6.95056 23.9212 7.14097 24 7.3395 24H16.6605C16.859 24 17.0494 23.9212 17.19 23.781L23.781 17.19C23.9212 17.0494 24 16.859 24 16.6605V7.3395C24 7.14097 23.9212 6.95056 23.781 6.81L17.19 0.219ZM8.031 6.969L12 10.9395L15.969 6.969C16.0387 6.89927 16.1215 6.84395 16.2126 6.80621C16.3037 6.76848 16.4014 6.74905 16.5 6.74905C16.5986 6.74905 16.6963 6.76848 16.7874 6.80621C16.8785 6.84395 16.9613 6.89927 17.031 6.969C17.1007 7.03873 17.156 7.12152 17.1938 7.21262C17.2315 7.30373 17.2509 7.40138 17.2509 7.5C17.2509 7.59862 17.2315 7.69627 17.1938 7.78738C17.156 7.87848 17.1007 7.96127 17.031 8.031L13.0605 12L17.031 15.969C17.1007 16.0387 17.156 16.1215 17.1938 16.2126C17.2315 16.3037 17.2509 16.4014 17.2509 16.5C17.2509 16.5986 17.2315 16.6963 17.1938 16.7874C17.156 16.8785 17.1007 16.9613 17.031 17.031C16.9613 17.1007 16.8785 17.156 16.7874 17.1938C16.6963 17.2315 16.5986 17.2509 16.5 17.2509C16.4014 17.2509 16.3037 17.2315 16.2126 17.1938C16.1215 17.156 16.0387 17.1007 15.969 17.031L12 13.0605L8.031 17.031C7.96127 17.1007 7.87848 17.156 7.78738 17.1938C7.69627 17.2315 7.59862 17.2509 7.5 17.2509C7.40138 17.2509 7.30373 17.2315 7.21262 17.1938C7.12152 17.156 7.03873 17.1007 6.969 17.031C6.89927 16.9613 6.84395 16.8785 6.80621 16.7874C6.76848 16.6963 6.74905 16.5986 6.74905 16.5C6.74905 16.4014 6.76848 16.3037 6.80621 16.2126C6.84395 16.1215 6.89927 16.0387 6.969 15.969L10.9395 12L6.969 8.031C6.82817 7.89017 6.74905 7.69916 6.74905 7.5C6.74905 7.30084 6.82817 7.10983 6.969 6.969C7.10983 6.82817 7.30084 6.74905 7.5 6.74905C7.69916 6.74905 7.89017 6.82817 8.031 6.969Z"
          fill="#DC3545"
        />
      </g>
      <defs>
        <clipPath id="clip0_2213_926">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="w-full">
    <h5 class="text-base group-aria-[expanded=false]:text-black font-bold">
      {{ validationData?.title }}
    </h5>
    <p class="text-black font-light text-xs 2xl:text-sm">{{ validationData?.content }}</p>
    <p
      class="text-black mt-2 font-medium text-xs 2xl:text-sm hidden group-aria-[valid=invalid]:block group-aria-[valid=invalid]:text-red-600"
      *ngIf="validationData!.isValid === 'invalid'"
    >
      {{ validationData?.error_message }}
    </p>
  </div>
</div>
