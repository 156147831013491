<button
  data-modal-target="request-file-upload-modal"
  data-modal-toggle="request-file-upload-modal"
  class="hidden"
  #toggleFileUploadModalBtn
></button>
<button
  data-modal-target="request-file-upload-modal"
  data-modal-show="request-file-upload-modal"
  class="hidden"
  #showFileUploadModalBtn
></button>
<button
  data-modal-target="request-file-upload-modal"
  data-modal-hide="request-file-upload-modal"
  class="hidden"
  #hideFileUploadModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="request-file-upload-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-1/3 max-w-xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-t">
        <h3
          class="text-2xl font-bold text-gray-900"
          *ngIf="!fileUploadModalService.showValidationResults"
        >
          Upload läuft...
        </h3>
        <h3
          class="text-2xl font-bold text-gray-900"
          *ngIf="fileUploadModalService.showValidationResults"
        >
          Daten überprüfen
        </h3>
        <button
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeModal()"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="w-3 h-3"
            fill="none"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div
        class="px-4 md:px-5 w-full"
        *ngIf="!fileUploadModalService.showValidationResults"
      >
        <p class="text-lg">
          Der Datencheck startet in Kürze. Browserfenster bitte nicht schließen.
        </p>
        <h3 class="mt-8 mb-4 text-center font-bold">
          Hochladen - {{ currentFileCount }}/{{
            fileUploadModalService.maxFileCount
          }}
          Dateien
        </h3>
        <div class="rounded border border-gray-800 px-4 py-2 mt-2 my-2">
          {{ fileUploadModalService.currentFileName }}
        </div>
        <div
          class="w-full h-6 bg-gray-200 rounded-full"
          *ngIf="fileUploadModalService.fileUploading"
        >
          <div
            class="h-6 bg-primaryBtn rounded-full"
            [style.width.%]="fileUploadModalService.uploadProgress"
          ></div>
        </div>

        <h3
          class="mt-8 mb-4 text-center font-bold"
          *ngIf="fileUploadModalService.uploadedFiles.length"
        >
          Bereits hochgeladen
        </h3>

        <div
          class="rounded border border-gray-800 px-4 py-2 my-2"
          *ngFor="let uploadedFile of fileUploadModalService.uploadedFiles"
        >
          {{ uploadedFile.file_name }}
        </div>
      </div>
      <div
        class="px-4 md:px-5 w-full"
        *ngIf="fileUploadModalService.showValidationResults"
      >
        <p class="text-lg">Wir überprüfen nun Ihre Daten.</p>
        <div class="flex flex-col my-6 gap-y-2">
          <app-file-validation-status
            [validationData]="fileUploadModalService.maxFileSizeValidation"
          ></app-file-validation-status>
          <app-file-validation-status
            [validationData]="
              fileUploadModalService.expectedFileTypesValidation
            "
          ></app-file-validation-status>
          <app-file-validation-status
            *ngFor="
              let entry of fileUploadModalService.fileTypeBasedValidations
                | keyvalue
            "
            [validationData]="{
              title: entry.value!.title,
              content: entry.value!.content,
              isValid: entry.value!.isValid
            }"
          ></app-file-validation-status>
        </div>
      </div>
      <!-- Modal footer -->
      <div
        class="w-full flex items-center justify-center p-3 2xl:p-4 md:p-5 rounded-b dark:border-gray-600"
      >
        <button
          class="text-white bg-primaryBtn hover:bg-primaryBtnHover w-44 focus:ring-4 focus:outline-none focus:ring-primaryBtn font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
          type="button"
          (click)="closeModal()"
        >
          Schließen
        </button>
      </div>
    </div>
  </div>
</div>
