import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type AppPackage = {
  id: number;
  name: string;
  included_storage_space: number;
  max_user_count: number;
  max_project_count: number;
  is_public: boolean;
  have_producers: boolean;
  can_increase_user_count: boolean;
  can_expand_storage: boolean;
  has_whitelabel: boolean;
  yearly_price: string;
  monthly_price: string;
  stripe_ref: string;
  created_at?: string;
  updated_at?: string;
  tagline?: string;
}

export type StorageAddonPackage = {
  id: number;
  name: string;
  included_storage_space: number;
  is_public: boolean;
  yearly_price: string;
  monthly_price: string;
  stripe_ref: string;
  created_at?: string;
  updated_at?: string;
  tagline?: string;
}

export type UserAddonPackage = {
  id: number;
  name: string;
  included_user_count: number;
  is_public: boolean;
  monthly_price: string;
  stripe_ref: string;
  created_at?: string;
  updated_at?: string;
  tagline?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  constructor(private http: HttpClient) {}

  getAvailablePackages() {
    return this.http.get<{
      success: boolean;
      total: number;
      data: AppPackage[];
    }>('/api/packages/app');
  }

  getAvailableStoragePackages() {
    return this.http.get<{
      success: boolean;
      total: number;
      data: StorageAddonPackage[];
    }>('/api/packages/storage');
  }

  getAvailableUserPackages() {
    return this.http.get<{
      success: boolean;
      total: number;
      data: UserAddonPackage[];
    }>('/api/packages/user');
  }

  initPackageSubscription(data: { package_id: number }) {
    return this.http.post<{url: string}>('/api/packages/app/init-subscription', data);
  }

  initStorageSubscription(data: { package_id: number }) {
    return this.http.post<{url: string}>('/api/packages/app/init-storage-subscription', data);
  }

  initUserSubscription(data: { package_id: number }) {
    return this.http.post<{url: string}>('/api/packages/app/init-user-subscription', data);
  }
}
