import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {Client, ClientContact, ClientService} from '../../backend-services/client.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { RequestService } from '../../backend-services/request.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ProducerPickerModalService {
  public readonly modalEvents = new Subject<
    { type: 'open'; id: number } | { type: 'close' }
  >();

  requestId?: number;
  producerControl = new FormControl();

  // contact persons of the producer
  toControl = new FormControl([]);

  producerList: Client[] = [];
  isSubmitting = false;

  currentContacts?: ClientContact[];




  constructor(
    private clientService: ClientService,
    private requestService: RequestService,
    private toastrService: ToastrService
  ) {
    this.clientService.getClients({ is_producer: true }).subscribe((res) => {
      this.producerList = res.data;
    });

    this.modalEvents.subscribe((event) => {
      if (event.type === 'open') {
        this.requestId = event.id;
      }
    });

    this.producerControl.valueChanges.subscribe((producerId) => {
      if (producerId) {
        this.fetchContactList();
        this.toControl.setValue([])
      }
    });
  }

  get remainingContactList(): ClientContact[] {
    return (
      this.currentContacts?.filter(
        (c) => !this.toControl.value.includes(c.email)
      ) || []
    );
  }

  fetchContactList() {
    this.clientService
      .getClientById(this.producerControl.value)
      .subscribe((res) => {
        if (res.data.contacts) {
          this.currentContacts = res.data.contacts;
        }
      });
  }

  get selectedContacts() {
    const selectedIds = this.toControl.value as string[];
    return this.currentContacts?.filter(v => selectedIds.includes( v.id.toString())) || []
  }


  removeRecipient(contactId: string) {
    const existing: string[] = this.toControl.value;

    const matchingIndex = existing.indexOf(contactId);
    if (matchingIndex >= 0) {
      existing.splice(matchingIndex, 1);
      this.toControl.setValue(existing);
    }
  }


  handleSubmit() {
    if (!this.producerControl.value) {
      this.toastrService.error('Please pick a producer');
      return;
    }

    if (!this.toControl.value || !this.toControl.value.length) {
      this.toastrService.error('Please pick a contact person');
      return;
    }

    this.requestService
      .updateRequestStatus(this.requestId!, {
        status_id: 3,
        producer_id: parseInt(this.producerControl.value),
        contact_ids: this.toControl.value.map((v: any) => parseInt(v)),
      })
      .subscribe({
        next: (res) => {
          this.toastrService.success(res.message);
          this.modalEvents.next({ type: 'close' });
        },
        error: (res) => {
          this.toastrService.error(res.error.message);
        },
      });
  }
}
