import { Component, OnInit } from '@angular/core';
import {RequestViewPageService} from "../request-view-page.service";
import dayjs from "dayjs";

@Component({
  selector: 'app-selected-section-comments',
  templateUrl: './selected-section-comments.component.html',
  styleUrls: ['./selected-section-comments.component.scss']
})
export class SelectedSectionCommentsComponent {

  constructor(public requestViewPageService: RequestViewPageService) { }

  formatedDate(dateStr: string) {
    return dayjs(dateStr).format('YYYY-MM-DD hh::mm');
  }
}
