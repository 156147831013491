import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';

const WEB_PUSH_PUBLIC_KEY =
  'BPH85rkFlu8tsf161dK_ObsaG0VmhkIj2aLphnvpGWyP5JYhzrORDZmTjjVBX9dPBK1LG8M6Hf1yNO6qgQJ_dtE';

export const DEVICE_KEY = "device_key";

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private registration: any;
  constructor(private httpClient: HttpClient) {}

  async register() {
    if (navigator['serviceWorker']) {
      this.registration = await navigator.serviceWorker.register(
        'assets/js/sw.js'
      );
    }
  }

  async subscribe() {
    // only perform this action if serviceWorker is supported on the platform
    if (!!this.registration) {
      const subscription = await this.registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: WEB_PUSH_PUBLIC_KEY,
      });

      let device_key = localStorage.getItem(DEVICE_KEY);
      if (!device_key) {
        device_key = uuidv4();
        localStorage.setItem(DEVICE_KEY, device_key!);
      }


      this.httpClient.post("/api/subscribe-to-push-notifications", {...subscription.toJSON(), device_key}).subscribe()

    }
  }
}
