<div class="flex-col" [formGroup]="sectionCollection">
  <h2 class="mt-10 mb-6 font-bold text-2xl" transloco="Requirements">
    Requirements
  </h2>
  <div
    class="flex flex-wrap border-b gap-4 items-center"
    formArrayName="sections"
  >
    <div
      *ngFor="
        let section of $any(sectionCollection.get('sections'))?.controls;
        let index = index;
        let isLast = last
      "
      class="flex gap-4 items-center"
    >
      <div
        class="flex gap-4 px-3 py-2 items-center cursor-pointer transition-all duration-200"
        [ngClass]="{
          'text-primary border-b-2 border-b-orange1':
            index === selectedSectionIndex,
          'text-gray2': index !== selectedSectionIndex
        }"
        (click)="setSelectedSectionIndex(index)"
      >
        <div class="font-medium"># Section {{ index + 1 }}</div>
        <button
          (click)="removeSection(index)"
          [disabled]="editRequestModalService.requestFormGroup.disabled"
        >
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            class=""
            xmlns="http://www.w3.org/2000/svg"
            [ngClass]="{
              'fill-red1 stroke-red1': index === selectedSectionIndex,
              'fill-gray5 stroke-gray5': index !== selectedSectionIndex
            }"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 4H0V7C0.530433 7 1.03914 7.21071 1.41421 7.58579C1.78929 7.96086 2 8.46957 2 9V13C2 15.828 2 17.243 2.879 18.121C3.757 19 5.172 19 8 19H10C12.829 19 14.243 19 15.121 18.121C16.001 17.243 16.001 15.828 16.001 13V9C16.001 8.46957 16.2117 7.96086 16.5868 7.58579C16.9619 7.21071 17.4706 7 18.001 7L18 4ZM7.5 9C7.5 8.73478 7.39464 8.48043 7.20711 8.29289C7.01957 8.10536 6.76522 8 6.5 8C6.23478 8 5.98043 8.10536 5.79289 8.29289C5.60536 8.48043 5.5 8.73478 5.5 9V14C5.5 14.2652 5.60536 14.5196 5.79289 14.7071C5.98043 14.8946 6.23478 15 6.5 15C6.76522 15 7.01957 14.8946 7.20711 14.7071C7.39464 14.5196 7.5 14.2652 7.5 14V9ZM12.5 9C12.5 8.73478 12.3946 8.48043 12.2071 8.29289C12.0196 8.10536 11.7652 8 11.5 8C11.2348 8 10.9804 8.10536 10.7929 8.29289C10.6054 8.48043 10.5 8.73478 10.5 9V14C10.5 14.2652 10.6054 14.5196 10.7929 14.7071C10.9804 14.8946 11.2348 15 11.5 15C11.7652 15 12.0196 14.8946 12.2071 14.7071C12.3946 14.5196 12.5 14.2652 12.5 14V9Z"
            />
            <path
              d="M7.06836 1.36999C7.18236 1.26399 7.43336 1.16999 7.78336 1.10299C8.18503 1.03156 8.5924 0.997084 9.00036 0.999986C9.44036 0.999986 9.86836 1.03599 10.2174 1.10299C10.5674 1.16999 10.8174 1.26399 10.9324 1.37099"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
      <div class="h-full min-h-[24px] bg-gray3 w-[1px]" *ngIf="!isLast"></div>
    </div>
    <div class="py-1">
      <button
        class="rounded border-gray3 border py-1 px-3 text-base font-medium"
        (click)="addNewSection()"
        [disabled]="editRequestModalService.requestFormGroup.disabled"
        transloco=" + Add Section"
      >
        + Add Section
      </button>
    </div>
  </div>
  <ng-container *transloco="let t;">
    <div
      class="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-6"
      [formGroup]="selectedSectionGroup"
      *ngIf="selectedSectionGroup"
    >
      <div class="pt-6 flex flex-col gap-6">
        <div class="flex flex-col gap-2">
          <label
            class="font-medium text-lg"
            for="section-title-{{ selectedSectionIndex }}"
            transloco="Section Title"
            >Section Title</label
          >
          <input
            type="text"
            formControlName="title"
            id="section-title-{{ selectedSectionIndex }}"
            [placeholder]="t('Input section title...')"
            class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full"
            [class.border-red-400]="
              selectedSectionGroup.get('title')?.touched &&
              selectedSectionGroup.get('title')?.invalid
            "
          />
          <div
            class="text-red-400"
            *ngIf="
              selectedSectionGroup.get('title')?.touched &&
              selectedSectionGroup.get('title')?.invalid
            "
          >
            <small
              *ngIf="selectedSectionGroup.get('title')?.errors?.required"
              transloco="required"
            >
              required
            </small>
            <small
              *ngIf="selectedSectionGroup.get('title')?.errors?.maxlength"
              transloco="Must be less than 255 characters"
            >
              Must be less than 255 characters
            </small>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <label
            for="section-description-{{ selectedSectionIndex }}"
            class="font-medium text-lg"
            transloco="Section Description"
            >Section Description</label
          >
          <textarea
            id="section-description-{{ selectedSectionIndex }}"
            formControlName="description"
            rows="4"
            class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full"
            [placeholder]="t('Input description...')"
            [class.border-red-400]="
              selectedSectionGroup.get('description')?.touched &&
              selectedSectionGroup.get('description')?.invalid
            "
          ></textarea>
          <div
            class="text-red-400"
            *ngIf="
              selectedSectionGroup.get('description')?.touched &&
              selectedSectionGroup.get('description')?.invalid
            "
          >
            <small
              *ngIf="selectedSectionGroup.get('description')?.errors?.required"
              transloco="required"
            >
              required
            </small>
            <small
              *ngIf="selectedSectionGroup.get('description')?.errors?.maxlength"
              transloco="Must be less than 1000 characters"
            >
              Must be less than 1000 characters
            </small>
          </div>
        </div>
        <div
          class="mt-6 pt-4 w-full border-t border-gray5"
          formArrayName="info_files"
        >
          <div class="flex justify-between items-center mb-7">
            <h3 class="text-lg font-medium" transloco="Info PDFs">Info PDFs</h3>
            <button
              class="py-3 px-4 rounded border border-gray3 disabled:text-gray3 text-orange1 font-medium text-base hover:bg-gray-100 transition-all duration-200"
              (click)="toggleUploadInfoPDFModal()"
              [disabled]="!canAddInfoFiles(selectedSectionIndex)"
              transloco="+ Add PDFs"
            >
              + Add PDFs
            </button>
          </div>
          <div
            *ngFor="
              let infoFile of currentInfoFiles.controls;
              let index = index
            "
            class="flex items-start gap-4 my-4"
          >
            <div
              class="w-[72px] h-[72px] flex justify-center items-center border border-gray3 rounded-lg"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.20008 0H18.8987L27.9662 9.45128V27.8341C27.9662 30.1369 26.1031 32 23.8083 32H8.20008C5.89724 32 4.03418 30.1369 4.03418 27.8341V4.1659C4.03414 1.86306 5.8972 0 8.20008 0Z"
                  fill="#E5252A"
                />
                <path
                  opacity="0.302"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.8911 0V9.37931H27.9666L18.8911 0Z"
                  fill="white"
                />
                <path
                  d="M8.66406 23.8761V18.031H11.1508C11.7665 18.031 12.2543 18.1989 12.6221 18.5428C12.9899 18.8786 13.1738 19.3344 13.1738 19.9021C13.1738 20.4698 12.9899 20.9256 12.6221 21.2614C12.2543 21.6052 11.7665 21.7731 11.1508 21.7731H10.1593V23.8761H8.66406ZM10.1593 20.5018H10.9829C11.2068 20.5018 11.3827 20.4538 11.5027 20.3419C11.6226 20.2379 11.6866 20.094 11.6866 19.9021C11.6866 19.7102 11.6226 19.5663 11.5027 19.4623C11.3827 19.3504 11.2068 19.3024 10.9829 19.3024H10.1593V20.5018ZM13.7895 23.8761V18.031H15.8605C16.2683 18.031 16.6521 18.087 17.0119 18.2069C17.3717 18.3268 17.6995 18.4948 17.9874 18.7267C18.2753 18.9505 18.5071 19.2544 18.675 19.6382C18.835 20.022 18.9229 20.4618 18.9229 20.9575C18.9229 21.4453 18.835 21.8851 18.675 22.2689C18.5071 22.6527 18.2753 22.9565 17.9874 23.1804C17.6995 23.4123 17.3717 23.5802 17.0119 23.7002C16.6521 23.8201 16.2683 23.8761 15.8605 23.8761H13.7895ZM15.2528 22.6047H15.6845C15.9164 22.6047 16.1323 22.5808 16.3322 22.5248C16.5241 22.4688 16.708 22.3808 16.884 22.2609C17.0519 22.141 17.1878 21.973 17.2837 21.7492C17.3797 21.5253 17.4277 21.2614 17.4277 20.9575C17.4277 20.6457 17.3797 20.3818 17.2837 20.158C17.1878 19.9341 17.0519 19.7662 16.884 19.6462C16.708 19.5263 16.5241 19.4383 16.3322 19.3824C16.1323 19.3264 15.9164 19.3024 15.6845 19.3024H15.2528V22.6047ZM19.6746 23.8761V18.031H23.8325V19.3024H21.1698V20.2379H23.2967V21.5013H21.1698V23.8761H19.6746Z"
                  fill="white"
                />
              </svg>
            </div>
            <div class="flex flex-col grow">
              <h4 class="font-medium text-lg text-primary">
                {{ infoFile.get("title")?.value }}
              </h4>
              <p class="text-xs text-gray4">
                {{ infoFile.get("description")?.value }}
              </p>
            </div>
            <a
              *ngIf="!!infoFile.value?.url"
              [href]="sanitizer.bypassSecurityTrustUrl(infoFile.value?.url)"
              target="_blank"
              class="text-blue-500 hover:underline"
              transloco="View File"
            >
              View File
            </a>
            <button (click)="removeInfoFile(selectedSectionIndex, index)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21 6H3V9C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V15C5 17.828 5 19.243 5.879 20.121C6.757 21 8.172 21 11 21H13C15.829 21 17.243 21 18.121 20.121C19.001 19.243 19.001 17.828 19.001 15V11C19.001 10.4696 19.2117 9.96086 19.5868 9.58579C19.9619 9.21071 20.4706 9 21.001 9L21 6ZM10.5 11C10.5 10.7348 10.3946 10.4804 10.2071 10.2929C10.0196 10.1054 9.76522 10 9.5 10C9.23478 10 8.98043 10.1054 8.79289 10.2929C8.60536 10.4804 8.5 10.7348 8.5 11V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V11ZM15.5 11C15.5 10.7348 15.3946 10.4804 15.2071 10.2929C15.0196 10.1054 14.7652 10 14.5 10C14.2348 10 13.9804 10.1054 13.7929 10.2929C13.6054 10.4804 13.5 10.7348 13.5 11V16C13.5 16.2652 13.6054 16.5196 13.7929 16.7071C13.9804 16.8946 14.2348 17 14.5 17C14.7652 17 15.0196 16.8946 15.2071 16.7071C15.3946 16.5196 15.5 16.2652 15.5 16V11Z"
                  fill="#E92525"
                />
                <path
                  d="M10.0684 3.37005C10.1824 3.26405 10.4334 3.17005 10.7834 3.10305C11.185 3.03162 11.5924 2.99714 12.0004 3.00005C12.4404 3.00005 12.8684 3.03605 13.2174 3.10305C13.5674 3.17005 13.8174 3.26405 13.9324 3.37105"
                  stroke="#E92525"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="rounded-2xl bg-gray6 p-10 flex flex-col">
        <h3 class="text-[22px] font-bold" transloco="Settings Requirements">
          Settings Requirements
        </h3>
        <div
          class="flex justify-between mt-10 flex-wrap border-b border-gray3 pb-6"
        >
          <div class="flex flex-col gap-2">
            <label
              for="allow-multiple-files-checkbox"
              class="font-semibold text-lg"
              transloco="Allow Multiple Files"
              >Allow Multiple Files</label
            >
            <label class="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                formControlName="allow_multiple_files"
                id="allow-multiple-files-checkbox"
              />
              <div
                class="border border-gray3 p-3 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange1 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-orange1 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:bg-orange1 after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-white"
              ></div>
            </label>
          </div>
          <div class="flex flex-col gap-2">
            <label
              for="maximum-file-count-input"
              class="font-semibold text-lg"
              transloco="Number of maximum files allowed"
              >Number of maximum files allowed</label
            >
            <input
              type="number"
              formControlName="max_file_count"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full"
              id="maximum-file-count-input"
            />
          </div>
        </div>
        <div
          class="flex flex-col gap-2 mt-10 flex-wrap border-b border-gray3 pb-6"
        >
          <label
            for="maximum-file-size-input"
            class="font-semibold text-lg"
            transloco="Max File Size"
            >Max File Size</label
          >
          <div class="flex justify-between grow gap-4">
            <input
              type="number"
              formControlName="max_file_size"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full grow max-w-[340px]"
              id="maximum-file-size-input"
            />
            <select
              formControlName="file_size_unit"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full max-w-[128px]"
            >
              <option value="KB">KB</option>
              <option value="MB">MB</option>
            </select>
          </div>
        </div>
        <div class="flex flex-col mt-6">
          <h4 class="font-semibold text-lg" transloco="Expected Files">
            Expected Files
          </h4>
          <div class="mt-4 bg-white">
            <app-custom-dropdown buttonText="Select a File Type">
              <li header>
                <div class="relative min-w-[380px] flex-grow my-3 mx-2">
                  <input
                    type="search"
                    [formControl]="fileTypeFilterText"
                    class="block w-full p-3 2xl:p-4 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
                    placeholder="Search file types..."
                  />
                </div>
              </li>
              <li
                class="text-blue-500 font-medium hover:bg-gray-200 px-1 py-3 cursor-pointer"
                (click)="initAddCustomFileType()"
                transloco="+ Custom File Type"
              >
                + Custom File Type
              </li>
              <li
                *ngFor="
                  let fileTypeGroup of remainingExpectedFileTypeList(
                    selectedSectionIndex,
                    debouncedFilterText
                  ) | keyvalue
                "
              >
                <ul class="my-4">
                  <li class="mb-2 font-bold text-xl">
                    {{ fileTypeGroup.key }}
                  </li>
                  <li
                    *ngFor="let fileType of fileTypeGroup.value"
                    (click)="selectFileType(fileType.id!)"
                    class="flex gap-2 my-1 py-2 cursor-pointer hover:bg-gray-200"
                  >
                    <img
                      *ngIf="fileType.icon_svg"
                      [alt]="fileType.name"
                      [src]="
                        sanitizer.bypassSecurityTrustResourceUrl(
                          fileType.icon_svg
                        )
                      "
                      class="w-8 h-8"
                    />
                    {{ fileType.name }}
                  </li>
                </ul>
              </li>
            </app-custom-dropdown>
          </div>
          <div *ngIf="showCustomFileTypeForm">
            <div
              class="text-xs 2xl:text-sm font-medium mt-8 mb-2"
              transloco="Enter the custom file extension ( eg: pdf )"
            >
              Enter the custom file extension ( eg: pdf )
            </div>
            <form class="flex flex-wrap gap-2">
              <input
                class="border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primaryBtn dark:focus:border-primaryBtn"
                [formControl]="customExtensionControl"
                required
                type="text"
              />
              <button
                type="button"
                class="duration-200 transition-all text-white bg-primary hover:bg-darkBlue1 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-xs 2xl:text-sm px-4 py-2 2xl:px-6 2xl:py-4 text-center"
                (click)="addCustomFileType()"
                transloco="Add custom file type"
              >
                Add custom file type
              </button>
            </form>
          </div>
          <ul formArrayName="expected_files" class="mt-6 my-4">
            <li
              *ngIf="
                selectedSectionGroup.get('expected_files')?.errors &&
                sectionCollection.touched
              "
              class="p-4 mb-4 text-xs 2xl:text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              transloco="At least one expected file is required"
            >
              At least one expected file is required
            </li>
            <li
              *ngFor="
                let expectedFile of $any(selectedSectionGroup).controls
                  .expected_files.controls;
                let expectedFileIndex = index
              "
            >
              <div
                *ngIf="expectedFile.value.is_custom_type"
                class="w-full grid grid-cols-1 lg:grid-cols-2 items-center gap-y-4 gap-x-4 mb-8"
                [formGroupName]="expectedFileIndex"
              >
                <div class="lg:col-span-2 flex gap-6 items-center">
                  <img
                    alt=""
                    class="w-6 h-6"
                    src="/assets/imgs/icons/unknown-file-type.svg"
                  />
                  <div class="grow">{{ expectedFile.value.name }}</div>
                  <button
                    type="button"
                    (click)="
                      removeExpectedFile(
                        selectedSectionIndex,
                        expectedFileIndex
                      )
                    "
                    class=""
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 6H3V9C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V15C5 17.828 5 19.243 5.879 20.121C6.757 21 8.172 21 11 21H13C15.829 21 17.243 21 18.121 20.121C19.001 19.243 19.001 17.828 19.001 15V11C19.001 10.4696 19.2117 9.96086 19.5868 9.58579C19.9619 9.21071 20.4706 9 21.001 9L21 6ZM10.5 11C10.5 10.7348 10.3946 10.4804 10.2071 10.2929C10.0196 10.1054 9.76522 10 9.5 10C9.23478 10 8.98043 10.1054 8.79289 10.2929C8.60536 10.4804 8.5 10.7348 8.5 11V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V11ZM15.5 11C15.5 10.7348 15.3946 10.4804 15.2071 10.2929C15.0196 10.1054 14.7652 10 14.5 10C14.2348 10 13.9804 10.1054 13.7929 10.2929C13.6054 10.4804 13.5 10.7348 13.5 11V16C13.5 16.2652 13.6054 16.5196 13.7929 16.7071C13.9804 16.8946 14.2348 17 14.5 17C14.7652 17 15.0196 16.8946 15.2071 16.7071C15.3946 16.5196 15.5 16.2652 15.5 16V11Z"
                        fill="#E92525"
                      />
                      <path
                        d="M10.0684 3.37005C10.1824 3.26405 10.4334 3.17005 10.7834 3.10305C11.185 3.03162 11.5924 2.99714 12.0004 3.00005C12.4404 3.00005 12.8684 3.03605 13.2174 3.10305C13.5674 3.17005 13.8174 3.26405 13.9324 3.37105"
                        stroke="#E92525"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                *ngIf="
                  fileTypeService.getLocalFileTypeById(
                    expectedFile.value.file_type_id
                  );
                  let expectedFileType
                "
                class="w-full grid grid-cols-1 items-center gap-x-4 mb-8"
                [formGroupName]="expectedFileIndex"
              >
                <div class="lg:col-span-2 flex gap-6 items-center">
                  <img
                    alt=""
                    class="w-6 h-6"
                    [src]="
                      sanitizer.bypassSecurityTrustResourceUrl(
                        expectedFileType.icon_svg || ''
                      )
                    "
                  />
                  <div class="grow">{{ expectedFileType.name }}</div>
                  <button
                    type="button"
                    (click)="
                      removeExpectedFile(
                        selectedSectionIndex,
                        expectedFileIndex
                      )
                    "
                    class=""
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 6H3V9C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V15C5 17.828 5 19.243 5.879 20.121C6.757 21 8.172 21 11 21H13C15.829 21 17.243 21 18.121 20.121C19.001 19.243 19.001 17.828 19.001 15V11C19.001 10.4696 19.2117 9.96086 19.5868 9.58579C19.9619 9.21071 20.4706 9 21.001 9L21 6ZM10.5 11C10.5 10.7348 10.3946 10.4804 10.2071 10.2929C10.0196 10.1054 9.76522 10 9.5 10C9.23478 10 8.98043 10.1054 8.79289 10.2929C8.60536 10.4804 8.5 10.7348 8.5 11V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V11ZM15.5 11C15.5 10.7348 15.3946 10.4804 15.2071 10.2929C15.0196 10.1054 14.7652 10 14.5 10C14.2348 10 13.9804 10.1054 13.7929 10.2929C13.6054 10.4804 13.5 10.7348 13.5 11V16C13.5 16.2652 13.6054 16.5196 13.7929 16.7071C13.9804 16.8946 14.2348 17 14.5 17C14.7652 17 15.0196 16.8946 15.2071 16.7071C15.3946 16.5196 15.5 16.2652 15.5 16V11Z"
                        fill="#E92525"
                      />
                      <path
                        d="M10.0684 3.37005C10.1824 3.26405 10.4334 3.17005 10.7834 3.10305C11.185 3.03162 11.5924 2.99714 12.0004 3.00005C12.4404 3.00005 12.8684 3.03605 13.2174 3.10305C13.5674 3.17005 13.8174 3.26405 13.9324 3.37105"
                        stroke="#E92525"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                </div>

                <div class="ml-6 w-full flex flex-col pt-4">
                  <div
                    class="flex items-center mb-4 w-full"
                    *ngIf="expectedFileType.can_enforce_color_space"
                  >
                    <label class="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                        formControlName="validate_color_space"
                        id="validate-color-space-{{ expectedFileIndex }}"
                      />
                      <div
                        class="border border-gray3 p-3 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange1 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-orange1 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:bg-orange1 after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-white"
                      ></div>
                    </label>
                    <label
                      for="validate-color-space-{{ expectedFileIndex }}"
                      class="ml-2"
                      transloco="Validate Color Space"
                      >Validate Color Space</label
                    >
                  </div>
                  <div
                    class="w-full"
                    *ngIf="expectedFile.value.validate_color_space"
                  >
                    <div class="mb-8">
                      <label
                        class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                        transloco="Color Space"
                        >Color Space</label
                      >
                      <div class="flex">
                        <div class="flex items-center me-4">
                          <input
                            id="cmyk-{{ expectedFileIndex }}"
                            type="radio"
                            value="CMYK"
                            formControlName="color_space"
                            class="w-4 h-4 text-orange1 bg-gray-100 border-gray-300 focus:ring-primary"
                          />
                          <label
                            for="cmyk-{{ expectedFileIndex }}"
                            class="ms-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-gray-300"
                            >CMYK</label
                          >
                        </div>
                        <div class="flex items-center me-4">
                          <input
                            id="rgb-{{ expectedFileIndex }}"
                            type="radio"
                            value="RGB"
                            formControlName="color_space"
                            class="w-4 h-4 text-orange1 bg-gray-100 border-gray-300 focus:ring-primary"
                          />
                          <label
                            for="rgb-{{ expectedFileIndex }}"
                            class="ms-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-gray-300"
                            >RGB</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex items-center mb-4 lg:col-span-2"
                    *ngIf="expectedFileType.can_enforce_resolution"
                  >
                    <label class="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                        formControlName="validate_resolution"
                        id="validate-resolution-{{ expectedFileIndex }}"
                      />
                      <div
                        class="border border-gray3 p-3 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange1 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-orange1 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:bg-orange1 after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-white"
                      ></div>
                    </label>
                    <label
                      for="validate-resolution-{{ expectedFileIndex }}"
                      class="ml-2"
                      transloco="Validate Resolution"
                      >Validate Resolution</label
                    >
                  </div>
                  <div
                    class="w-full lg:col-span-2"
                    *ngIf="expectedFile.value.validate_resolution"
                  >
                    <div class="mb-8">
                      <label
                        class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                        for="resolution-{{ expectedFileIndex }}"
                        transloco="Resolution in DPI"
                        >Resolution in DPI</label
                      >
                      <div class="w-full flex">
                        <input
                          type="number"
                          id="resolution-{{ expectedFileIndex }}"
                          formControlName="resolution"
                          class="block p-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-lg rounded-gray-100 rounded-2 border border-gray-300"
                          [placeholder]="t('Enter resolution')"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex items-center mb-4 lg:col-span-2"
                    *ngIf="expectedFileType.can_enforce_dimensions"
                  >
                    <label class="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        class="sr-only peer"
                        formControlName="validate_dimensions"
                        id="validate-dimensions-{{ expectedFileIndex }}"
                      />
                      <div
                        class="border border-gray3 p-3 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange1 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-orange1 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:bg-orange1 after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-white"
                      ></div>
                    </label>
                    <label
                      for="validate-dimensions-{{ expectedFileIndex }}"
                      class="ml-2"
                      transloco="Validate Dimensions"
                      >Validate Dimensions</label
                    >
                  </div>
                  <div
                    class="w-full"
                    *ngIf="expectedFile.value.validate_dimensions"
                  >
                    <div class="w-full flex gap-x-3 mb-4">
                      <div>
                        <label
                          class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                          for="dimensions-width-{{ expectedFileIndex }}"
                          transloco="Dimensions Width"
                          >Dimensions Width</label
                        >
                        <div class="w-full flex">
                          <input
                            type="number"
                            id="dimensions-width-{{ expectedFileIndex }}"
                            formControlName="dimensions_width"
                            class="block p-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-s-lg rounded-s-gray-100 rounded-s-2 border border-gray-300"
                            placeholder="Enter width"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                          for="dimensions-height-{{ expectedFileIndex }}"
                          transloco="Dimensions Height"
                          >Dimensions Height</label
                        >
                        <div class="w-full flex">
                          <input
                            type="number"
                            id="dimensions-height-{{ expectedFileIndex }}"
                            formControlName="dimensions_height"
                            class="block p-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-s-lg rounded-s-gray-100 rounded-s-2 border border-gray-300"
                            placeholder="Enter height"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                          for="dimensions-unit-{{ expectedFileIndex }}"
                          transloco="Unit"
                          >Unit</label
                        >
                        <select
                          id="dimensions-unit-{{ expectedFileIndex }}"
                          formControlName="dimensions_unit"
                          class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-xs 2xl:text-sm font-medium text-center border border-s-0 border-gray-300"
                        >
                          <option selected value="px">px</option>
                          <option value="cm">cm</option>
                          <option value="in">in</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</div>
