import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RejectRequestModalService} from "./reject-request-modal.service";

@Component({
  selector: 'app-reject-request-modal',
  templateUrl: './reject-request-modal.component.html',
  styleUrls: ['./reject-request-modal.component.scss'],
})
export class RejectRequestModalComponent implements OnInit {
  @ViewChild('showRejectRequestModalBtn')
  showRejectRequestModalBtn?: ElementRef;
  @ViewChild('hideRejectRequestModalBtn')
  hideRejectRequestModalBtn?: ElementRef;

  constructor(public rejectRequestModalService: RejectRequestModalService) {
    this.rejectRequestModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showRejectRequestModalBtn?.nativeElement!.click();
          break;
        case 'close':
          this.hideRejectRequestModalBtn?.nativeElement!.click();
          break;
      }
    });
  }

  ngOnInit(): void {}

  closeModal() {
    this.rejectRequestModalService.modalEvents.next({ type: 'close' });
  }

  initClientModalClose() {
    this.closeModal();
  }
}
