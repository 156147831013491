import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserService } from '../../backend-services/user.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { AdminRoutes } from '../../layouts/admin-layout/admin-routes';

const defaultParams: {
  management_type: string;
} = {
  management_type: 'users',
};

@Component({
  selector: 'app-users-list-view-page',
  templateUrl: './users-list-view-page.component.html',
  styleUrls: ['./users-list-view-page.component.scss'],
})
export class UsersListViewPageComponent {
  queryParams: any = { ...defaultParams };

  public UserManagementRoute = AdminRoutes.UserManagement;
  public ManageSingleUserRoute = AdminRoutes.ManageSingleUser;
  public ManageSingleCustomerRoute = AdminRoutes.ManageSingleCustomer;

  allUsers: User[] = [];
  allCustomers: Customer[] = [];
  totalUsers = 0;
  totalCustomers = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      queryParams.management_type =
        queryParams['management_type'] ?? this.queryParams.management_type;

      this.queryParams = queryParams;
      this.fetchData();
    });

    this.authService.profile.subscribe({
      next: () => {
        this.fetchData();
      },
    });
  }

  changeManagementType(newType: string) {
    this.queryParams.management_type = newType;

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  fetchData() {
    // fetch users
    if (this.queryParams.management_type === 'users') {
      this.userService.getUsers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allUsers = res.data;
            this.totalUsers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }

    if (
      this.queryParams.management_type === 'customers' &&
      this.authService.isSuperAdmin.value
    ) {
      // fetch customers if user is SuperAdmin
      this.customerService.getCustomers().subscribe({
        next: (res) => {
          if (res.success) {
            this.allCustomers = res.data;
            this.totalCustomers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }
  }
}
