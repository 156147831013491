import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import {
  FileType,
  FileTypeService,
} from '../../../backend-services/file-type.service';
import { InfoUploadModalService } from '../info-upload-modal/info-upload-modal.service';
import {debounceTime} from "rxjs";

@Component({
  selector: 'app-request-sections-view',
  templateUrl: './request-sections-view.component.html',
  styleUrls: ['./request-sections-view.component.scss'],
})
export class RequestSectionsViewComponent implements OnInit {
  @Input() sectionCollection!: FormGroup;
  @Input() sectionCollectionIndex!: number;

  selectedSectionIndex = 0;

  showCustomFileTypeForm = false;
  customExtensionControl = new FormControl('');
  fileTypeFilterText = new FormControl('');
  debouncedFilterText?: string = '';

  constructor(
    public editRequestModalService: EditRequestModalService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    public fileTypeService: FileTypeService,
    private infoUploadModalService: InfoUploadModalService
  ) {
    this.fileTypeFilterText.valueChanges
      .pipe(debounceTime(300))
      .subscribe((v: any) => (this.debouncedFilterText = v));
  }

  ngOnInit(): void {
    this.selectedSectionIndex = 0;
  }

  get selectedSectionGroup() {
    return (this.sectionCollection.get('sections') as FormArray).at(
      this.selectedSectionIndex
    ) as FormGroup;
  }

  get sectionsFormArr() {
    return this.sectionCollection.get('sections') as FormArray;
  }

  setSelectedSectionIndex(index: number) {
    this.selectedSectionIndex = index;
  }

  removeSection(index: number) {
    this.selectedSectionIndex = 0;

    (this.sectionCollection.get('sections') as FormArray).removeAt(index, {
      emitEvent: true,
    });
  }

  get currentInfoFiles() {
    return this.selectedSectionGroup.get('info_files') as FormArray;
  }

  toggleUploadInfoPDFModal() {
    this.infoUploadModalService.modalEvents.next({
      type: 'open',
      sectionIndex: this.selectedSectionIndex,
      sectionCollectionIndex: this.sectionCollectionIndex
    });
  }

  initAddCustomFileType() {
    this.showCustomFileTypeForm = true;
  }

  selectFileType(fileTypeId: number) {
    this.resetCustomFileTypeForm();
    this.selectedSectionGroup.get('expected_file_picker')!.setValue(fileTypeId);
  }

  resetCustomFileTypeForm() {
    this.showCustomFileTypeForm = false;
    this.customExtensionControl.setValue('');
  }

  addCustomFileType() {
    const customExtension = this.customExtensionControl.value;
    if (customExtension) {
      (this.selectedSectionGroup.get('expected_files') as FormArray).insert(
        0,
        this.editRequestModalService.buildExpectedFileFormGroup(
          undefined,
          undefined,
          { is_custom_type: true, name: customExtension }
        )
      );
      this.resetCustomFileTypeForm();
    } else {
      this.toastr.error('File extension is required');
    }
  }

  addNewSection() {
    (this.sectionCollection.get('sections') as FormArray).push(
      this.editRequestModalService.buildSectionFormGroup()
    );
  }

  public canAddInfoFiles(sectionIndex: number) {
    return (
      (
        (this.sectionsFormArr.controls[sectionIndex] as FormGroup).controls
          .info_files as FormArray
      ).length < 4
    );
  }

  public removeInfoFile(sectionIndex: number, infoFileIndex: number) {
    (
      (this.sectionsFormArr.controls[sectionIndex] as FormGroup).controls
        .info_files as FormArray
    ).removeAt(infoFileIndex);
  }

  public remainingExpectedFileTypeList(
    sectionIndex: number,
    filterText?: string
  ) {
    const groupedMap: Map<string, FileType[]> = new Map();

    this.editRequestModalService.availableFileTypes
      .filter(
        (fileType) =>
          this.sectionsFormArr.controls[sectionIndex]
            .get('expected_files')
            ?.value.find(
              (expectedFile: any) => expectedFile.file_type_id === fileType.id
            ) === undefined &&
          (filterText
            ? fileType.mime_type
                ?.toLowerCase()
                .includes(filterText.toLowerCase()) ||
              fileType.name.toLowerCase().includes(filterText.toLowerCase()) ||
              fileType.group_name
                .toLowerCase()
                .includes(filterText.toLowerCase())
            : true)
      )
      .forEach((fileType) => {
        if (groupedMap.has(fileType.group_name)) {
          groupedMap.get(fileType.group_name)!.push(fileType);
        } else {
          groupedMap.set(fileType.group_name, [fileType]);
        }
      });

    return groupedMap;
  }

  public removeExpectedFile(sectionIndex: number, expectedFileIndex: number) {
    (
      (this.sectionsFormArr.controls[sectionIndex] as FormGroup).controls
        .expected_files as FormArray
    ).removeAt(expectedFileIndex);
  }
}
