import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export type Customer = {
  id: number;
  enabled: boolean;
  license_id?: number;
  label?: string;
  company?: string;
  vat_nr?: string;
  first_name: string;
  last_name: string;
  email: string;
  billing_street?: string;
  billing_house_number?: string;
  billing_zip?: string;
  billing_city?: string;
  billing_email?: string;
  invoice_by_mail: boolean;
  created_at: string;
  updated_at: string;
  user_count?: number;
};

export type License = {
  validity: {
    start_date: string;
    end_date?: string;
  };
  package: LicensePackage;
  usage: {
    availableStorage: number;
    availableUserCount: number;
    availableProjectCount: number;
    storageUsage: number;
    userCount: number;
    projectCount: number;
    addonStorage: number;
    addonUserCount: number;
  };
};

export type LicensePackage = {
  id: number;
  name: string;
  included_storage_space: number;
  max_user_count: number;
  max_project_count: number;
  is_public: boolean;
  have_producers: boolean;
  can_increase_user_count: boolean;
  can_expand_storage: boolean;
  has_whitelabel: boolean;
  yearly_price: string;
  monthly_price: string;
  stripe_ref: string;
  created_at?: string;
  updated_at?: string;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  // cache get customer by id requests
  customerCacheMap = new Map<number, BehaviorSubject<Customer | undefined>>();

  constructor(private http: HttpClient) {}

  getCustomers() {
    return this.http.get<{ success: boolean; total: number; data: Customer[] }>(
      '/api/customers'
    );
  }

  getCustomerById(id: number) {
    if (this.customerCacheMap.get(id)) {
      return this.customerCacheMap.get(id) as BehaviorSubject<
        Customer | undefined
      >;
    } else {
      return this.http
        .get<{ success: boolean; data: Customer }>(`/api/customers/${id}`)
        .pipe((res) => {
          // create map item
          const ob = new BehaviorSubject<Customer | undefined>(undefined);
          this.customerCacheMap.set(id, ob);

          res.subscribe({
            next: (res) => {
              if (res.success) {
                ob.next(res.data);
              }
            },
            error: (err) => {
              console.log(err);
              ob.error('Error Occurred');
            },
          });

          return ob;
        });
    }
  }

  createCustomer(data: any) {
    return this.http.post<{ success: boolean; data: Customer }>(
      '/api/customers',
      data
    );
  }

  updateCustomer(id: number, data: any) {
    this.customerCacheMap.delete(id);
    return this.http.put<{ success: boolean; data: Customer }>(
      `/api/customers/${id}`,
      data
    );
  }

  getCurrentCustomerLicense() {
    return this.http.get<{ success: boolean; data: License }>(
      '/api/customers/license'
    );
  }
}
