import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../backend-services/auth.service';
import { License } from '../../backend-services/customer.service';
import {
  AppPackage,
  PackageService,
  StorageAddonPackage,
  UserAddonPackage,
} from '../../backend-services/package.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-package-upgrade-view',
  templateUrl: './package-upgrade-view.component.html',
  styleUrls: ['./package-upgrade-view.component.scss'],
})
export class PackageUpgradeViewComponent {
  currentUserLicense: License | undefined;

  availablePackages: AppPackage[] = [];
  availableStorageAddons: StorageAddonPackage[] = [];
  availableUserAddons: UserAddonPackage[] = [];

  subscriptionButtonClicked = false;

  constructor(
    private authService: AuthService,
    private packageService: PackageService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.authService.license.subscribe((v) => {
      this.currentUserLicense = v;
    });

    this.packageService.getAvailablePackages().subscribe((res) => {
      this.availablePackages = res.data;
    });

    this.packageService.getAvailableStoragePackages().subscribe((res) => {
      this.availableStorageAddons = res.data;
    });

    this.packageService.getAvailableUserPackages().subscribe((res) => {
      this.availableUserAddons = res.data;
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      const paymentSuccessParam = params.get('payment_success');
      if (paymentSuccessParam !== undefined && paymentSuccessParam !== null) {
        if (paymentSuccessParam === "true") {
          this.toastr.success('Package activated');
        } else {
          this.toastr.error('Payment unsuccessful');
        }
      }
    });
  }

  initPackageSubscription(package_id: number) {
    this.subscriptionButtonClicked = true;
    this.packageService
      .initPackageSubscription({ package_id })
      .subscribe((res) => {
        window.location.href = res.url;
        this.subscriptionButtonClicked = false;
      });
  }

  initStorageSubscription(package_id: number) {
    this.subscriptionButtonClicked = true;
    this.packageService
      .initStorageSubscription({ package_id })
      .subscribe((res) => {
        window.location.href = res.url;
        this.subscriptionButtonClicked = false;
      });
  }

  initUserSubscription(package_id: number) {
    this.subscriptionButtonClicked = true;
    this.packageService
      .initUserSubscription({ package_id })
      .subscribe((res) => {
        window.location.href = res.url;
        this.subscriptionButtonClicked = false;
      });
  }
}
