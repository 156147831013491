<button
  data-modal-target="section-rejection-modal"
  data-modal-toggle="section-rejection-modal"
  class="hidden"
  #toggleSectionRejectionModalBtn
></button>
<button
  data-modal-target="section-rejection-modal"
  data-modal-show="section-rejection-modal"
  class="hidden"
  #showSectionRejectionModalBtn
></button>
<button
  data-modal-target="section-rejection-modal"
  data-modal-hide="section-rejection-modal"
  class="hidden"
  #hideSectionRejectionModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full font-Montserrat"
  id="section-rejection-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div
    class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-2/4 max-w-[600px] max-h-full"
  >
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow-2xl px-7">
      <!-- Modal header -->
      <div class="flex flex-col py-8">
        <h1
          class="font-bold text-xl leading-6 text-primary mb-6"
          transloco="Datei ablehnen"
        >
          Datei ablehnen
        </h1>
        <p
          class="mb-6 text-base text-primary"
          transloco="Bitte schreiben sie eine kurze Begründung warum Sie die Datei nicht freigeben können."
        >
          Bitte schreiben sie eine kurze Begründung warum Sie die Datei nicht
          freigeben können.
        </p>
        <div class="flex flex-col mb-10">
          <label
            for="section-rejection-modal-comment"
            class="text-primary font-medium mb-1"
            transloco="Begründung"
            >Begründung*</label
          >
          <textarea
            class="rounded-2xl border-gray7"
            id="section-rejection-modal-comment"
            rows="5"
            [formControl]="sectionRejectionModalService.rejectionCommentControl"
          ></textarea>

          <div class="self-end text-primary opacity-60 mt-2">
            {{
              sectionRejectionModalService.rejectionCommentControl.value.length
            }}
            / 250
          </div>
        </div>
        <div class="flex gap-3">
          <button
            class="flex-grow tw-btn-secondary-outline"
            (click)="closeModal()"
            transloco="Abbrechen"
          >
            Abbrechen
          </button>
          <button
            class="flex-grow tw-btn-secondary disabled:opacity-60"
            (click)="sectionRejectionModalService.rejectCurrentSection()"
            [disabled]="
              sectionRejectionModalService.rejectionCommentControl.invalid
            "
            transloco="Freigeben"
          >
            Freigeben
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
