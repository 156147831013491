<!--Info PDF modal-->
<button
  data-modal-target="upload-info-pdf-modal"
  data-modal-toggle="upload-info-pdf-modal"
  class="hidden"
  #toggleUploadInfoPdfModalButton
></button>
<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden drop-shadow-3xl fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="upload-info-pdf-modal"
  tabindex="-1"
>
  <ng-container *transloco="let t;">
    <div
      class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-1/2 max-w-2xl max-h-full"
    >
      <div class="relative bg-white rounded-3xl shadow overflow-hidden">
        <div class="h-2 bg-orange1"></div>
        <form class="p-4 md:p-6">
          <div class="flex items-center justify-between rounded-t">
            <h1 class="text-lg font-semibold" transloco="Add Info PDFs">
              Add Info PDFs
            </h1>
            <button
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              (click)="toggleUploadInfoPDFModal()"
              [disabled]="editRequestModalService.requestFormGroup.disabled"
              type="button"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3002 5.70998C18.2077 5.61728 18.0978 5.54373 17.9768 5.49355C17.8559 5.44337 17.7262 5.41754 17.5952 5.41754C17.4643 5.41754 17.3346 5.44337 17.2136 5.49355C17.0926 5.54373 16.9827 5.61728 16.8902 5.70998L12.0002 10.59L7.11022 5.69998C7.01764 5.6074 6.90773 5.53396 6.78677 5.48385C6.6658 5.43375 6.53615 5.40796 6.40522 5.40796C6.27429 5.40796 6.14464 5.43375 6.02368 5.48385C5.90272 5.53396 5.79281 5.6074 5.70022 5.69998C5.60764 5.79256 5.5342 5.90247 5.4841 6.02344C5.43399 6.1444 5.4082 6.27405 5.4082 6.40498C5.4082 6.53591 5.43399 6.66556 5.4841 6.78652C5.5342 6.90749 5.60764 7.0174 5.70022 7.10998L10.5902 12L5.70022 16.89C5.60764 16.9826 5.5342 17.0925 5.4841 17.2134C5.43399 17.3344 5.4082 17.464 5.4082 17.595C5.4082 17.7259 5.43399 17.8556 5.4841 17.9765C5.5342 18.0975 5.60764 18.2074 5.70022 18.3C5.79281 18.3926 5.90272 18.466 6.02368 18.5161C6.14464 18.5662 6.27429 18.592 6.40522 18.592C6.53615 18.592 6.6658 18.5662 6.78677 18.5161C6.90773 18.466 7.01764 18.3926 7.11022 18.3L12.0002 13.41L16.8902 18.3C16.9828 18.3926 17.0927 18.466 17.2137 18.5161C17.3346 18.5662 17.4643 18.592 17.5952 18.592C17.7262 18.592 17.8558 18.5662 17.9768 18.5161C18.0977 18.466 18.2076 18.3926 18.3002 18.3C18.3928 18.2074 18.4662 18.0975 18.5163 17.9765C18.5665 17.8556 18.5922 17.7259 18.5922 17.595C18.5922 17.464 18.5665 17.3344 18.5163 17.2134C18.4662 17.0925 18.3928 16.9826 18.3002 16.89L13.4102 12L18.3002 7.10998C18.6802 6.72998 18.6802 6.08998 18.3002 5.70998Z"
                  fill="black"
                />
              </svg>

              <span class="sr-only" transloco="Close modal">Close modal</span>
            </button>
          </div>
          <div
            class="flex flex-col my-4 gap-4"
            [formGroup]="infoUploadModalService.infoFileForm"
          >
            <label
              class="font-semibold text-lg"
              for="info-file-title"
              transloco="Title"
              >Title</label
            >
            <input
              id="info-file-title"
              [placeholder]="t('Input title...')"
              formControlName="title"
              type="text"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1"
              [class.border-red-400]="
                infoUploadModalService.infoFileForm.get('title')?.touched &&
                infoUploadModalService.infoFileForm.get('title')?.invalid
              "
            />
            <div
              class="text-red-400"
              *ngIf="
                infoUploadModalService.infoFileForm.get('title')?.touched &&
                infoUploadModalService.infoFileForm.get('title')?.invalid
              "
            >
              <small
                *ngIf="
                  infoUploadModalService.infoFileForm.get('title')?.errors
                    ?.required
                "
                transloco="required"
              >
                required
              </small>
              <small
                *ngIf="
                  infoUploadModalService.infoFileForm.get('title')?.errors
                    ?.maxlength
                "
                transloco="Must be less than 255 characters"
              >
                Must be less than 255 characters
              </small>
            </div>
            <label
              class="font-semibold text-lg mt-4"
              for="info-file-description"
              transloco="Description"
              >Description</label
            >
            <textarea
              id="info-file-description"
              [placeholder]="t('Input description...')"
              formControlName="description"
              type="text"
              rows="4"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1"
              [class.border-red-400]="
                infoUploadModalService.infoFileForm.get('description')
                  ?.touched &&
                infoUploadModalService.infoFileForm.get('description')?.invalid
              "
            ></textarea>
            <div
              class="text-red-400"
              *ngIf="
                infoUploadModalService.infoFileForm.get('description')
                  ?.touched &&
                infoUploadModalService.infoFileForm.get('description')?.invalid
              "
            >
              <small
                *ngIf="
                  infoUploadModalService.infoFileForm.get('description')?.errors
                    ?.required
                "
                transloco="required"
              >
                required
              </small>
              <small
                *ngIf="
                  infoUploadModalService.infoFileForm.get('description')?.errors
                    ?.maxlength
                "
                transloco="Must be less than 1000 characters"
              >
                Must be less than 1000 characters
              </small>
            </div>
            <label
              class="font-semibold text-lg mt-4"
              for="dropzone-info-file"
              transloco="Upload"
            >
              Upload
            </label>
            <div
              class="flex items-center justify-center w-full"
              *ngIf="
                !infoUploadModalService.infoFileForm.get('uploaded_file_blob')
                  ?.value
              "
            >
              <label
                for="dropzone-info-file"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 duration-200"
              >
                <div
                  class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                  <svg
                    width="78"
                    height="78"
                    viewBox="0 0 78 78"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="64"
                      height="64"
                      rx="32"
                      transform="matrix(1 0 0 -1 7 71)"
                      fill="#F6F8FA"
                    />
                    <rect
                      width="76"
                      height="76"
                      rx="38"
                      transform="matrix(1 0 0 -1 1 77)"
                      stroke="#DDE5EF"
                      stroke-dasharray="2 2"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M38.9994 42.7501C39.1983 42.7501 39.3891 42.6711 39.5297 42.5304C39.6704 42.3897 39.7494 42.199 39.7494 42.0001V31.0271L41.4294 32.9881C41.5588 33.1392 41.743 33.2328 41.9414 33.2482C42.1398 33.2636 42.3362 33.1995 42.4874 33.0701C42.6386 32.9406 42.7321 32.7565 42.7475 32.5581C42.7629 32.3596 42.6988 32.1632 42.5694 32.0121L39.5694 28.5121C39.499 28.4297 39.4116 28.3636 39.3132 28.3183C39.2148 28.273 39.1077 28.2495 38.9994 28.2495C38.8911 28.2495 38.784 28.273 38.6856 28.3183C38.5872 28.3636 38.4998 28.4297 38.4294 28.5121L35.4294 32.0121C35.3653 32.0869 35.3166 32.1737 35.286 32.2673C35.2555 32.361 35.2437 32.4598 35.2513 32.5581C35.2589 32.6563 35.2858 32.7521 35.3304 32.8399C35.375 32.9278 35.4365 33.006 35.5114 33.0701C35.5862 33.1342 35.673 33.1829 35.7667 33.2134C35.8603 33.244 35.9591 33.2558 36.0574 33.2482C36.1556 33.2406 36.2514 33.2137 36.3392 33.1691C36.4271 33.1244 36.5053 33.0629 36.5694 32.9881L38.2494 31.0281V42.0001C38.2494 42.4141 38.5854 42.7501 38.9994 42.7501Z"
                      fill="black"
                    />
                    <path
                      d="M43 36C42.298 36 41.947 36 41.694 36.169C41.5852 36.2418 41.4918 36.3352 41.419 36.444C41.25 36.697 41.25 37.048 41.25 37.75V42C41.25 42.5967 41.0129 43.169 40.591 43.591C40.169 44.0129 39.5967 44.25 39 44.25C38.4033 44.25 37.831 44.0129 37.409 43.591C36.9871 43.169 36.75 42.5967 36.75 42V37.75C36.75 37.048 36.75 36.697 36.581 36.444C36.5082 36.3352 36.4148 36.2418 36.306 36.169C36.053 36 35.702 36 35 36C32.172 36 30.757 36 29.879 36.879C29 37.757 29 39.17 29 41.999V42.999C29 45.829 29 47.242 29.879 48.121C30.757 49 32.172 49 35 49H43C45.828 49 47.243 49 48.121 48.121C49 47.242 49 45.828 49 43V42C49 39.171 49 37.757 48.121 36.879C47.243 36 45.828 36 43 36Z"
                      fill="black"
                    />
                  </svg>

                  <p
                    class="mb-2 text-xs 2xl:text-sm text-gray2"
                    transloco="class"
                  >
                    <span class="font-semibold text-semantic-primary-700"
                      >Click to upload</span
                    >
                    or drag and drop the info pdf
                  </p>
                  <p class="text-xs 2xl:text-sm text-semantic-primary-700">
                    PDF (MAX. 100MB)
                  </p>
                </div>
                <input
                  id="dropzone-info-file"
                  type="file"
                  accept="application/pdf"
                  formControlName="file"
                  (change)="infoUploadModalService.onFileChange($event)"
                  class="hidden"
                />
              </label>
            </div>
            <div
              class="flex items-center gap-4 my-4"
              *ngIf="
                infoUploadModalService.infoFileForm.get('uploaded_file_blob')
                  ?.value
              "
            >
              <div
                class="w-[72px] h-[72px] flex justify-center items-center border border-gray3 rounded-lg"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.20008 0H18.8987L27.9662 9.45128V27.8341C27.9662 30.1369 26.1031 32 23.8083 32H8.20008C5.89724 32 4.03418 30.1369 4.03418 27.8341V4.1659C4.03414 1.86306 5.8972 0 8.20008 0Z"
                    fill="#E5252A"
                  />
                  <path
                    opacity="0.302"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.8911 0V9.37931H27.9666L18.8911 0Z"
                    fill="white"
                  />
                  <path
                    d="M8.66406 23.8761V18.031H11.1508C11.7665 18.031 12.2543 18.1989 12.6221 18.5428C12.9899 18.8786 13.1738 19.3344 13.1738 19.9021C13.1738 20.4698 12.9899 20.9256 12.6221 21.2614C12.2543 21.6052 11.7665 21.7731 11.1508 21.7731H10.1593V23.8761H8.66406ZM10.1593 20.5018H10.9829C11.2068 20.5018 11.3827 20.4538 11.5027 20.3419C11.6226 20.2379 11.6866 20.094 11.6866 19.9021C11.6866 19.7102 11.6226 19.5663 11.5027 19.4623C11.3827 19.3504 11.2068 19.3024 10.9829 19.3024H10.1593V20.5018ZM13.7895 23.8761V18.031H15.8605C16.2683 18.031 16.6521 18.087 17.0119 18.2069C17.3717 18.3268 17.6995 18.4948 17.9874 18.7267C18.2753 18.9505 18.5071 19.2544 18.675 19.6382C18.835 20.022 18.9229 20.4618 18.9229 20.9575C18.9229 21.4453 18.835 21.8851 18.675 22.2689C18.5071 22.6527 18.2753 22.9565 17.9874 23.1804C17.6995 23.4123 17.3717 23.5802 17.0119 23.7002C16.6521 23.8201 16.2683 23.8761 15.8605 23.8761H13.7895ZM15.2528 22.6047H15.6845C15.9164 22.6047 16.1323 22.5808 16.3322 22.5248C16.5241 22.4688 16.708 22.3808 16.884 22.2609C17.0519 22.141 17.1878 21.973 17.2837 21.7492C17.3797 21.5253 17.4277 21.2614 17.4277 20.9575C17.4277 20.6457 17.3797 20.3818 17.2837 20.158C17.1878 19.9341 17.0519 19.7662 16.884 19.6462C16.708 19.5263 16.5241 19.4383 16.3322 19.3824C16.1323 19.3264 15.9164 19.3024 15.6845 19.3024H15.2528V22.6047ZM19.6746 23.8761V18.031H23.8325V19.3024H21.1698V20.2379H23.2967V21.5013H21.1698V23.8761H19.6746Z"
                    fill="white"
                  />
                </svg>
              </div>
              <button
                type="button"
                (click)="
                  infoUploadModalService.infoFileForm
                    .get('uploaded_file_blob')
                    ?.reset()
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 6H3V9C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V15C5 17.828 5 19.243 5.879 20.121C6.757 21 8.172 21 11 21H13C15.829 21 17.243 21 18.121 20.121C19.001 19.243 19.001 17.828 19.001 15V11C19.001 10.4696 19.2117 9.96086 19.5868 9.58579C19.9619 9.21071 20.4706 9 21.001 9L21 6ZM10.5 11C10.5 10.7348 10.3946 10.4804 10.2071 10.2929C10.0196 10.1054 9.76522 10 9.5 10C9.23478 10 8.98043 10.1054 8.79289 10.2929C8.60536 10.4804 8.5 10.7348 8.5 11V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V11ZM15.5 11C15.5 10.7348 15.3946 10.4804 15.2071 10.2929C15.0196 10.1054 14.7652 10 14.5 10C14.2348 10 13.9804 10.1054 13.7929 10.2929C13.6054 10.4804 13.5 10.7348 13.5 11V16C13.5 16.2652 13.6054 16.5196 13.7929 16.7071C13.9804 16.8946 14.2348 17 14.5 17C14.7652 17 15.0196 16.8946 15.2071 16.7071C15.3946 16.5196 15.5 16.2652 15.5 16V11Z"
                    fill="#E92525"
                  />
                  <path
                    d="M10.0684 3.37005C10.1824 3.26405 10.4334 3.17005 10.7834 3.10305C11.185 3.03162 11.5924 2.99714 12.0004 3.00005C12.4404 3.00005 12.8684 3.03605 13.2174 3.10305C13.5674 3.17005 13.8174 3.26405 13.9324 3.37105"
                    stroke="#E92525"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
            <div
              class="w-full flex items-center justify-end mt-12 gap-4 flex-wrap"
            >
              <button
                class="transition-all duration-200 text-primary bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
                type="button"
                (click)="toggleUploadInfoPDFModal()"
                [disabled]="editRequestModalService.requestFormGroup.disabled"
                transloco="Cancel"
              >
                Cancel
              </button>
              <button
                class="duration-200 transition-all text-white bg-primary hover:bg-darkBlue1 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-xs 2xl:text-sm px-4 py-2 2xl:px-6 2xl:py-4 text-center"
                type="button"
                (click)="infoUploadModalService.handleCreateInfoFile()"
                [disabled]="editRequestModalService.requestFormGroup.disabled"
                transloco=" Upload"
              >
                Upload
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</div>
