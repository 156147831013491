<div class="flex justify-end gap-2">
  <button
    class="w-2 h-2 rounded-full bg-gray7"
    [ngClass]="{
      'opacity-70':
        gSection.section.id !== requestViewPageService.selectedSection?.id,
      'bg-red3':
        gSection.section.id === requestViewPageService.selectedSection?.id &&
        requestViewPageService.sectionHasDataErrors(gSection.section)
    }"
    (click)="requestViewPageService.setSelectedSection(gSection.section)"
    *ngFor="let gSection of requestViewPageService.globalSectionList"
  ></button>
</div>
<div class="flex mt-7">
  <div *ngIf="sectionThumbnail" class="mr-8">
    <img
      class="w-36 h-28 object-fill border rounded-xl"
      [src]="sectionThumbnail"
      alt=""
    />
  </div>
  <div>
    <h3
      class="text-primary font-bold text-28px leading-33.6px -tracking-0.022em"
    >
      {{ requestViewPageService.selectedSection?.title }}
    </h3>
    <p class="mt-5 font-normal text-sm leading-22.4px text-primary">
      {{ requestViewPageService.selectedSection?.description }}
    </p>
    <app-status-display
      [statusId]="requestViewPageService.selectedSectionStatusId()"
      [variant]="3"
    ></app-status-display>
  </div>
</div>
<app-file-section-actions-bar
  *ngIf="showFileSectionActionBar"
></app-file-section-actions-bar>
