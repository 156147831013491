import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestViewPageService } from '../request-view-page.service';

@Injectable({
  providedIn: 'root',
})
export class SectionApprovalModalService {
  modalEvents: Subject<
    | {
        type: 'open';
      }
    | { type: 'close' }
  > = new Subject();

  constructor(private requestViewPageService: RequestViewPageService) {}

  approveCurrentSection() {
    const sectionGroupIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionIndex;

    if (
      sectionGroupIndex !== undefined &&
      sectionIndex !== undefined &&
      this.requestViewPageService.requestData &&
      this.requestViewPageService.requestData.section_groups
    ) {
      if (!!this.requestViewPageService.producerHash) {
        if (
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status === 303
        ) {
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status = 305;
        } else {
          this.requestViewPageService.requestData.section_groups[
            sectionGroupIndex
          ].sections[sectionIndex].status = 401;
        }
      } else if (this.requestViewPageService.isAgency) {
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex].status = 4;
      } else if (
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex].status === 203
      ) {
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex].status = 205;
      }

      this.requestViewPageService.handleRequestDataChange();
    }

    this.modalEvents.next({ type: 'close' });
  }
}
