import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileType } from './file-type.service';

export type TmpFileUploadResponse = {
  file_type?: FileType;
  path: string;
  url: string;
  thumbnail_url: string;
  preview_url: string;
  file_name: string;
  file_size: number;
  is_valid?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class TmpFilesService {
  constructor(private http: HttpClient) {}

  tmpFileUpload(file: any) {
    const data = new FormData();
    data.append('file', file);
    data.append('file_name', file.name);
    return this.http.post<FileType>('/api/tmp_files', data, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
