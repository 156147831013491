import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  Client,
  ClientContact,
  ClientService,
} from '../../backend-services/client.service';
import { FormControl } from '@angular/forms';
import { RequestService } from '../../backend-services/request.service';
import { ToastrService } from 'ngx-toastr';
import { RequestViewPageService } from '../request-view-page.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ProducerPickerModalV4Service {
  public readonly modalEvents = new Subject<
    { type: 'open' } | { type: 'close' }
  >();

  requestId?: number;
  producerControl = new FormControl();

  // contact persons of the producer
  toControl = new FormControl([]);

  producerList: Client[] = [];
  isSubmitting = false;

  currentContacts?: ClientContact[];

  constructor(
    private clientService: ClientService,
    private toastrService: ToastrService,
    private requestViewPageService: RequestViewPageService,
    private translocoService: TranslocoService
  ) {
    this.clientService.getClients({ is_producer: true }).subscribe((res) => {
      this.producerList = res.data;
    });

    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          const selectedProducerId =
            this.requestViewPageService.selectedSection?.producer_id;
          if (selectedProducerId) {
            this.producerControl.setValue(selectedProducerId);
          }

          const contactList =
            this.requestViewPageService.selectedSection?.producer_contacts?.map(
              (contact) => contact.contact_id.toString()
            );
          this.toControl.setValue(contactList);
      }
    });

    this.producerControl.valueChanges.subscribe((producerId) => {
      if (producerId) {
        this.fetchContactList();
        this.toControl.setValue([]);
      }
    });
  }

  get remainingContactList(): ClientContact[] {
    return (
      this.currentContacts?.filter(
        (c) => !this.toControl.value.includes(c.email)
      ) || []
    );
  }

  fetchContactList() {
    this.clientService
      .getClientById(this.producerControl.value)
      .subscribe((res) => {
        if (res.data.contacts) {
          this.currentContacts = res.data.contacts;
        }
      });
  }

  get selectedContacts() {
    const selectedIds = this.toControl.value as string[];
    return (
      this.currentContacts?.filter((v) =>
        selectedIds.includes(v.id.toString())
      ) || []
    );
  }

  removeRecipient(contactId: string) {
    const existing: string[] = this.toControl.value;

    const matchingIndex = existing.indexOf(contactId);
    if (matchingIndex >= 0) {
      existing.splice(matchingIndex, 1);
      this.toControl.setValue(existing);
    }
  }

  handleSubmit() {
    if (!this.producerControl.value) {
      this.toastrService.error(
        this.translocoService.translate(`Please pick a producer`)
      );
      return;
    }

    if (!this.toControl.value || !this.toControl.value.length) {
      this.toastrService.error(
        this.translocoService.translate(`Please pick a contact person`)
      );
      return;
    }

    const producer_id = parseInt(this.producerControl.value);
    const contact_ids = this.toControl.value.map((v: any) => parseInt(v));

    const groupIndex =
      this.requestViewPageService.selectedSectionMetaData!.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData!.sectionIndex;

    this.requestViewPageService.requestData!.section_groups![
      groupIndex
    ].sections[sectionIndex]!.producer_id = producer_id;
    this.requestViewPageService.requestData!.section_groups![
      groupIndex
    ].sections[sectionIndex]!.new_producer_contacts = contact_ids;
    this.requestViewPageService.requestData!.section_groups![
      groupIndex
    ].sections[sectionIndex]!.status = 3;

    this.requestViewPageService.handleRequestDataChange();

    this.modalEvents.next({ type: 'close' });
  }
}
