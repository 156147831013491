import { Component, OnInit } from '@angular/core';
import {RequestViewPageService} from "../request-view-page.service";

@Component({
  selector: 'app-selected-section-header',
  templateUrl: './selected-section-header.component.html',
  styleUrls: ['./selected-section-header.component.scss']
})
export class SelectedSectionHeaderComponent {

  constructor(public requestViewPageService: RequestViewPageService) { }

  get showFileSectionActionBar() {
    if (
      this.requestViewPageService.isAgency ||
      this.requestViewPageService.producerHash
    ) {
      return (
        this.requestViewPageService.requestData &&
        this.requestViewPageService.requestData.request_status_id > 1
      );
    }
    return (
      this.requestViewPageService.requestData &&
      (this.requestViewPageService.requestData.request_status_id === 203 ||
        this.requestViewPageService.requestData.request_status_id === 303)
    );
  }

  get sectionThumbnail() {
    if(this.requestViewPageService.selectedSection) {
      return this.requestViewPageService.getSectionThumbnail(this.requestViewPageService.selectedSection);
    }
    return undefined;
  }
}
