import { Component, OnInit } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { RequestUploadedFile } from '../../backend-services/request.service';

@Component({
  selector: 'app-uploaded-file-validation-data',
  templateUrl: './uploaded-file-validation-data.component.html',
  styleUrls: ['./uploaded-file-validation-data.component.scss'],
})
export class UploadedFileValidationDataComponent {
  constructor(public requestViewPageService: RequestViewPageService) {}

  get selectedUploadedFile(): RequestUploadedFile | undefined {
    let selectedFile =
      this.requestViewPageService.selectedSection?.uploaded_files[
        this.requestViewPageService.selectedUploadFileIndex
      ];

    if (this.requestViewPageService.selectedSection?.proof_file) {
      if (this.requestViewPageService.selectedUploadFileIndex === 0) {
        selectedFile = undefined;
      } else {
        selectedFile =
          this.requestViewPageService.selectedSection?.uploaded_files[
            this.requestViewPageService.selectedUploadFileIndex - 1
          ];
      }
    }

    return selectedFile;
  }
}
