import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SectionRejectionModalService } from './section-rejection-modal.service';

@Component({
  selector: 'app-section-rejection-modal',
  templateUrl: './section-rejection-modal.component.html',
  styleUrls: ['./section-rejection-modal.component.scss'],
})
export class SectionRejectionModalComponent {
  @ViewChild('toggleSectionRejectionModalBtn')
  toggleSectionRejectionModalBtn?: ElementRef;
  @ViewChild('showSectionRejectionModalBtn')
  showSectionRejectionModalBtn?: ElementRef;
  @ViewChild('hideSectionRejectionModalBtn')
  hideSectionRejectionModalBtn?: ElementRef;

  constructor(
    public sectionRejectionModalService: SectionRejectionModalService
  ) {
    this.sectionRejectionModalService.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          this.showSectionRejectionModalBtn?.nativeElement!.click();
          break;
        case 'close':
          this.hideSectionRejectionModalBtn?.nativeElement!.click();
          break;
      }
    });
  }

  closeModal() {
    this.sectionRejectionModalService.modalEvents.next({type: 'close'})
  }
}
