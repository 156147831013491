<ng-container *ngIf="selectedUploadedFile">
  <div class="mt-10 flex gap-2">
    <h3 class="font-semibold text-xl text-primary leading-28.4px flex gap-2">
      <div *ngIf="selectedUploadedFile?.validation_data?.is_valid === false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2224_53145)">
            <path
              d="M13.4726 2.34917C13.3241 2.09052 13.11 1.87562 12.8519 1.72618C12.5938 1.57674 12.3008 1.49805 12.0026 1.49805C11.7043 1.49805 11.4113 1.57674 11.1532 1.72618C10.8951 1.87562 10.681 2.09052 10.5326 2.34917L0.247053 19.8497C-0.438447 21.0167 0.383553 22.5002 1.71705 22.5002H22.2866C23.6201 22.5002 24.4436 21.0152 23.7566 19.8497L13.4726 2.34917ZM11.9996 7.50017C12.8021 7.50017 13.4306 8.19317 13.3496 8.99267L12.8246 14.2532C12.8069 14.4598 12.7124 14.6523 12.5596 14.7926C12.4068 14.9329 12.207 15.0108 11.9996 15.0108C11.7921 15.0108 11.5923 14.9329 11.4395 14.7926C11.2867 14.6523 11.1922 14.4598 11.1746 14.2532L10.6496 8.99267C10.6307 8.80402 10.6516 8.61351 10.7108 8.43342C10.7701 8.25333 10.8664 8.08764 10.9936 7.94704C11.1207 7.80643 11.276 7.69403 11.4492 7.61706C11.6225 7.5401 11.81 7.50028 11.9996 7.50017ZM12.0026 16.5002C12.4004 16.5002 12.7819 16.6582 13.0632 16.9395C13.3445 17.2208 13.5026 17.6023 13.5026 18.0002C13.5026 18.398 13.3445 18.7795 13.0632 19.0608C12.7819 19.3421 12.4004 19.5002 12.0026 19.5002C11.6047 19.5002 11.2232 19.3421 10.9419 19.0608C10.6606 18.7795 10.5026 18.398 10.5026 18.0002C10.5026 17.6023 10.6606 17.2208 10.9419 16.9395C11.2232 16.6582 11.6047 16.5002 12.0026 16.5002Z"
              fill="#EF3054"
            />
          </g>
          <defs>
            <clipPath id="clip0_2224_53145">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div *ngIf="selectedUploadedFile?.validation_data?.is_valid !== false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2224_52241)">
            <path
              d="M3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 21C0 21.7956 0.316071 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.316071 21.7956 0 21 0L3 0ZM18.045 7.455C18.2533 7.66313 18.3718 7.94465 18.3749 8.23913C18.3779 8.53361 18.2654 8.81755 18.0615 9.03L12.0735 16.515C11.9706 16.6258 11.8464 16.7148 11.7083 16.7766C11.5702 16.8383 11.4211 16.8716 11.2699 16.8744C11.1186 16.8772 10.9684 16.8494 10.8281 16.7928C10.6879 16.7363 10.5604 16.652 10.4535 16.545L6.486 12.576C6.37547 12.473 6.28682 12.3488 6.22533 12.2108C6.16384 12.0728 6.13078 11.9238 6.12811 11.7728C6.12545 11.6217 6.15323 11.4717 6.20982 11.3316C6.2664 11.1915 6.35061 11.0643 6.45744 10.9574C6.56427 10.8506 6.69152 10.7664 6.8316 10.7098C6.97169 10.6532 7.12173 10.6254 7.27278 10.6281C7.42384 10.6308 7.57281 10.6638 7.71081 10.7253C7.84881 10.7868 7.97301 10.8755 8.076 10.986L11.217 14.1255L16.4265 7.488C16.5292 7.37695 16.6533 7.28776 16.7912 7.22575C16.9292 7.16375 17.0783 7.1302 17.2295 7.12712C17.3807 7.12404 17.531 7.15149 17.6714 7.20782C17.8118 7.26416 17.9394 7.34823 18.0465 7.455H18.045Z"
              fill="#2BB259"
            />
          </g>
          <defs>
            <clipPath id="clip0_2224_52241">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
     <span transloco="Datencheck"
     >Datencheck (Anforderungen):</span>
    </h3>
    <img
      title="Datencheck"
      src="assets/imgs/icons/bs-question-circle.svg"
      alt="question"
      class="-mt-4"
    />
  </div>
  <div class="grid grid-cols-2 mt-6 gap-4">
    <div *ngIf="requestViewPageService.selectedSection?.requirement_title">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco="Anforderung Titel:"
      >
        Anforderung Titel:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!!selectedUploadedFile?.validation_data"
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3': !!selectedUploadedFile?.validation_data,
            'text-primary': !selectedUploadedFile?.validation_data
          }"
        >
          {{ requestViewPageService.selectedSection?.requirement_title }}
        </div>
      </div>
    </div>
    <div *ngIf="requestViewPageService.selectedSection?.dimensions">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco=" Dokumentformat oder Abmessungen:"
      >
        Dokumentformat oder Abmessungen:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data?.dimensions"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.dimensions?.valid === true
          "
        />
        <img
          src="assets/imgs/icons/bs-x-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.dimensions?.valid === false
          "
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3':
              selectedUploadedFile?.validation_data?.dimensions?.valid === true,
            'text-red3':
              selectedUploadedFile?.validation_data?.dimensions?.valid ===
              false,
            'text-primary': !selectedUploadedFile?.validation_data?.dimensions
          }"
        >
          {{ requestViewPageService.selectedSection?.dimensions }}
        </div>
      </div>
    </div>
    <div *ngIf="requestViewPageService.selectedSection?.allowed_file_formats">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco="Erlaubte Dateiformate:"
      >
        Erlaubte Dateiformate:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data?.file_type"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.file_type?.valid === true
          "
        />
        <img
          src="assets/imgs/icons/bs-x-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.file_type?.valid === false
          "
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3':
              selectedUploadedFile?.validation_data?.file_type?.valid === true,
            'text-red3':
              selectedUploadedFile?.validation_data?.file_type?.valid === false,
            'text-primary': !selectedUploadedFile?.validation_data?.file_type
          }"
        >
          {{ requestViewPageService.selectedSection?.allowed_file_formats }}
        </div>
      </div>
    </div>
    <div *ngIf="requestViewPageService.selectedSection?.resolution">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco="Minimale Auflösung:"
      >
        Minimale Auflösung:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data?.resolution"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.resolution?.valid === true
          "
        />
        <img
          src="assets/imgs/icons/bs-x-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.resolution?.valid === false
          "
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3':
              selectedUploadedFile?.validation_data?.resolution?.valid === true,
            'text-red3':
              selectedUploadedFile?.validation_data?.resolution?.valid ===
              false,
            'text-primary': !selectedUploadedFile?.validation_data?.resolution
          }"
        >
          {{ requestViewPageService.selectedSection?.resolution }}
        </div>
      </div>
    </div>
    <div *ngIf="requestViewPageService.selectedSection?.color_space">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco="Farbraum:"
      >
        Farbraum:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data?.color_space"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.color_space?.valid === true
          "
        />
        <img
          src="assets/imgs/icons/bs-x-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.color_space?.valid === false
          "
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3':
              selectedUploadedFile?.validation_data?.color_space?.valid ===
              true,
            'text-red3':
              selectedUploadedFile?.validation_data?.color_space?.valid ===
              false,
            'text-primary': !selectedUploadedFile?.validation_data?.color_space
          }"
        >
          {{ requestViewPageService.selectedSection?.color_space }}
        </div>
      </div>
    </div>
    <div *ngIf="requestViewPageService.selectedSection?.max_file_size">
      <div
        class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
        transloco="Maximale Dateigröße:"
      >
        Maximale Dateigröße:
      </div>
      <div class="flex gap-1 items-center">
        <img
          src="assets/imgs/icons/right-arrow.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="!selectedUploadedFile?.validation_data?.file_size"
        />
        <img
          src="assets/imgs/icons/bs-check-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.file_size?.valid === true
          "
        />
        <img
          src="assets/imgs/icons/bs-x-square-fill.svg"
          alt=""
          class="h-4 w-4"
          *ngIf="
            selectedUploadedFile?.validation_data?.file_size?.valid === false
          "
        />
        <div
          class="font-semibold text-sm leading-22.4px"
          [ngClass]="{
            'text-green3':
              selectedUploadedFile?.validation_data?.file_size?.valid === true,
            'text-red3':
              selectedUploadedFile?.validation_data?.file_size?.valid === false,
            'text-primary': !selectedUploadedFile?.validation_data?.file_size
          }"
        >
          {{
            requestViewPageService.selectedSection!.max_file_size * 1024
              | fileSize
          }}
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="selectedUploadedFile?.validation_data?.is_valid === false"
    class="mt-11 mb-6 rounded-lg bg-red3 text-white px-3 py-2 font-medium text-sm leading-22.4px"
    transloco="Ihre Daten entsprechen nicht den Anforderungen. Bitte überprüfen Sie Ihre Daten."
  >
    Ihre Daten entsprechen nicht den Anforderungen. Bitte überprüfen Sie Ihre
    Daten.
  </div>
</ng-container>
