import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SectionGroup } from '../../backend-services/request.service';
import { RequestViewPageService } from '../request-view-page.service';

@Component({
  selector: 'app-section-list-item',
  templateUrl: './section-list-item.component.html',
  styleUrls: ['./section-list-item.component.scss'],
})
export class SectionListItemComponent {
  isExpanded = false;
  @Input() sectionGroup!: SectionGroup;
  @Input() sectionGroupIndex!: number;

  constructor(public requestViewPageService: RequestViewPageService) {
    this.requestViewPageService.selectedSectionChangedEvent.subscribe(() => {
      if (
        this.requestViewPageService.selectedSectionMetaData
          ?.sectionGroupIndex == this.sectionGroupIndex
      ) {
        this.isExpanded = true;
      }
    });
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
