import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { InfoUploadModalService } from './info-upload-modal.service';

@Component({
  selector: 'app-info-upload-modal',
  templateUrl: './info-upload-modal.component.html',
  styleUrls: ['./info-upload-modal.component.scss'],
})
export class InfoUploadModalComponent {
  @ViewChild('toggleUploadInfoPdfModalButton')
  toggleUploadInfoPdfModalButton!: ElementRef;
  constructor(
    public editRequestModalService: EditRequestModalService,
    public infoUploadModalService: InfoUploadModalService
  ) {
    this.infoUploadModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
        case 'close':
          this.toggleUploadInfoPdfModalButton.nativeElement.click();
          break;
      }
    });
  }

  toggleUploadInfoPDFModal() {
    this.toggleUploadInfoPdfModalButton.nativeElement.click();
  }
}
