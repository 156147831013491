import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsListViewPageComponent } from './clients-list-view-page/clients-list-view-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { EditClientModalComponent } from './edit-client-modal/edit-client-modal.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ClientDetailViewPageComponent } from './client-detail-view-page/client-detail-view-page.component';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../directives/directives.module';
import { ClientManagementLayoutComponent } from './client-management-layout/client-management-layout.component';
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
  declarations: [
    ClientsListViewPageComponent,
    EditClientModalComponent,
    ClientDetailViewPageComponent,
    ClientManagementLayoutComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    SharedComponentsModule,
    RouterModule,
    DirectivesModule,
    TranslocoModule,
  ],
  exports: [EditClientModalComponent],
})
export class ClientManagementModule {}
