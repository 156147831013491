<button
  data-modal-target="request-comment-modal"
  data-modal-toggle="request-comment-modal"
  class="hidden"
  #toggleFileUploadModalBtn
></button>
<button
  data-modal-target="request-comment-modal"
  data-modal-show="request-comment-modal"
  class="hidden"
  #showFileUploadModalBtn
></button>
<button
  data-modal-target="request-comment-modal"
  data-modal-hide="request-comment-modal"
  class="hidden"
  #hideFileUploadModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="request-comment-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-1/3 max-w-xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow">
      <!-- Modal header -->
      <div class="flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-t">
        <h3 class="text-2xl font-bold text-gray-900">Kommentare</h3>
        <button
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeModal()"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="w-3 h-3"
            fill="none"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="px-4 md:px-5 w-full">
        <div class="flex flex-col gap-6 max-h-[300px] overflow-y-auto p-3 2xl:p-4">
          <div
            class="flex gap-4 w-full"
            *ngFor="let comment of commentsModalService.comments"
          >
            <div>
              <app-profile-picture
                [firstName]="comment.first_name"
                [lastName]="comment.last_name"
              ></app-profile-picture>
            </div>
            <div class="flex flex-col w-full gap-2">
              <div class="flex justify-between w-full">
                <div class="text-lightBlack text-gray-800 text-xs 2xl:text-sm">
                  {{ comment.first_name }} {{ comment.last_name }}
                </div>
                <div class="text-gray-400 font-medium text-xs">
                  {{ comment.created_at | transformTimestamp }}
                </div>
              </div>
              <div>{{ comment.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <form class="flex flex-col w-full pt-4">
        <div class="flex flex-wrap gap-4 px-4" *ngIf="commentsModalService.showNameInput">
          <div class="flex flex-col">
            <label>Vorname</label>
            <input
              type="text"
              class="px-4 py-2 border border-gray-400 rounded-xl flex-grow"
              [formControl]="commentsModalService.firstNameControl"
              placeholder="Vorname"
            />
            <div
              class="text-red-400"
              *ngIf="
                commentsModalService.firstNameControl.errors &&
                (commentsModalService.firstNameControl.touched ||
                  commentsModalService.firstNameControl.dirty)
              "
            >
              <small>required</small>
            </div>
          </div>
          <div class="flex flex-col">
            <label>Nachname</label>
            <input
              type="text"
              class="px-4 py-2 border border-gray-400 rounded-xl flex-grow"
              [formControl]="commentsModalService.lastNameControl"
              placeholder="Nachname"
            />
            <div
              class="text-red-400"
              *ngIf="
                commentsModalService.lastNameControl.errors &&
                (commentsModalService.lastNameControl.touched ||
                  commentsModalService.lastNameControl.dirty)
              "
            >
              <small>required</small>
            </div>
          </div>
        </div>
        <div
          class="w-full flex items-center justify-between p-3 2xl:p-4 md:p-5 rounded-b dark:border-gray-600 gap-4"
        >
          <div class="flex-grow ">
            <textarea
              class="px-4 py-4 border border-gray-400 rounded-xl w-full"
              [formControl]="commentsModalService.contentControl"
              placeholder="Kommentar eingeben..."
            ></textarea>
            <div
              class="text-red-400"
              *ngIf="
                commentsModalService.contentControl.errors &&
                (commentsModalService.contentControl.touched ||
                  commentsModalService.contentControl.dirty)
              "
            >
              <small>required</small>
            </div>
          </div>
          <button
            class="text-white bg-primaryBtn hover:bg-primaryBtnHover focus:ring-4 focus:outline-none focus:ring-primaryBtn font-medium rounded-xl text-xs 2xl:text-sm p-5 text-center dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            type="button"
            [disabled]="commentsModalService.commentForm.disabled"
            (click)="commentsModalService.submitComment()"
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.7628 0.237249C25.8753 0.349914 25.9522 0.493145 25.984 0.64915C26.0158 0.805155 26.001 0.967059 25.9415 1.11475L16.4857 24.7536C16.4023 24.9618 16.2632 25.143 16.0835 25.2771C15.9039 25.4113 15.6907 25.4934 15.4674 25.5142C15.2441 25.535 15.0195 25.4939 14.8181 25.3952C14.6167 25.2966 14.4464 25.1443 14.326 24.9551L9.16179 16.8382L1.04492 11.674C0.855308 11.5537 0.702613 11.3834 0.60368 11.1818C0.504747 10.9803 0.463423 10.7553 0.484267 10.5317C0.505111 10.3081 0.587313 10.0947 0.721803 9.91487C0.856293 9.73506 1.03784 9.5959 1.24642 9.51275L24.8853 0.0601242C25.033 0.000669153 25.1949 -0.0140854 25.3509 0.0176934C25.5069 0.0494722 25.6501 0.126384 25.7628 0.238874V0.237249ZM10.7835 16.3637L15.2702 23.413L22.9613 4.186L10.7835 16.3637ZM21.8124 3.03712L2.58542 10.7282L9.63629 15.2133L21.814 3.03712H21.8124Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
