import { Injectable } from '@angular/core';
import {RequestUploadedFile} from "../backend-services/request.service";
import {TmpFileUploadResponse} from "../backend-services/tmp-files.service";

@Injectable({
  providedIn: 'root'
})
export class RequestFilePreviewService {
  public selectedFile?: RequestUploadedFile | TmpFileUploadResponse;

  constructor() { }

}
