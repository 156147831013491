import { Component, OnInit } from '@angular/core';
import {
  AppRequest,
  RequestService,
  RequestStatus,
} from '../../backend-services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { EditRequestModalService } from '../edit-request-modal/edit-request-modal.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { SendRequestModalService } from '../send-request-modal/send-request-modal.service';
import { CreateFolderModalService } from '../create-folder-modal/create-folder-modal.service';
import {
  RequestFolder,
  RequestFolderService,
} from '../../backend-services/request-folder.service';
import { TranslocoService } from '@ngneat/transloco';

export interface InfoDoc {
  title: string;
  description: string;
  fileType: string;
  date: Date;
  fileSize: number;
  fileSizeType: string;
}

export interface Validation {
  title: boolean | string;
  colorSpace: boolean | string;
  allowedFileFormats: boolean | string;
  dpi: boolean | string;
  dimensions: boolean | string;
  maxFileCount: boolean | string;
  maxFileSize: boolean | string;
}

export interface FileUpload {
  name: string;
  fileType: string;
  fileSize: number;
  fileSizeType: string;
  thumbnail: string;
}

export interface Section {
  id: number;
  title: string;
  description: string;
  infoDocs: InfoDoc[];
  colorSpace: string;
  allowedFileFormats: string[];
  allowedFonts: string[];
  dpi: number;
  dimensions: string;
  maxFileCount: number;
  maxFileSize: number;
  fileSizeType: string;
  dimensionUnit: string;
  validation: Validation;
  overallValidation: boolean | string;
  fileUploaded: boolean;
  fileUpload?: FileUpload | undefined;
}

@Component({
  selector: 'app-requests-list-view-page',
  templateUrl: './requests-list-view-page.component.html',
  styleUrls: ['./requests-list-view-page.component.scss'],
})
export class RequestsListViewPageComponent implements OnInit {
  queryParams: any = {};

  status_list: RequestStatus[] = [];
  statusFilterControl = new FormControl('all');
  currentFolderId?: number = undefined;
  currentFolderData?: RequestFolder = undefined;

  constructor(
    private requestService: RequestService,
    private requestFolderService: RequestFolderService,
    private route: ActivatedRoute,
    private router: Router,
    private editRequestModalService: EditRequestModalService,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private sendRequestModalService: SendRequestModalService,
    private createFolderModalService: CreateFolderModalService,
    public translocoService: TranslocoService
  ) {
    this.requestService.getRequestStatusList().subscribe((res) => {
      this.status_list = res.data;
    });

    this.editRequestModalService.modalEvents.subscribe((ev) => {
      if (ev.type === 'close' || ev.type === 'updated') {
        this.handleRequestFetch();
      }
    });

    this.createFolderModalService.modalEvents.subscribe((ev) => {
      if (ev.type === 'updated') {
        this.handleRequestFetch();
        this.fetchCurrentFolder();
      }

      if (ev.type === 'deleted') {
        // go back one step if folder is deleted
        if (
          this.currentFolderData &&
          this.currentFolderData.path!.length >= 2
        ) {
          const parentId = this.currentFolderData.path!.slice(-2, 1)[0].id;
          this.router.navigate(['requests', 'folders', parentId], {
            replaceUrl: true,
          });
        } else {
          this.router.navigate(['requests'], { replaceUrl: true });
        }
      }
    });

    // listen to changes in the search control
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.queryParams.search = value;
        } else {
          this.queryParams.search = undefined;
        }

        this.handleQueryParamChange();
      });

    // listen to filter changes
    this.statusFilterControl.valueChanges.subscribe((v) => {
      if (!isNaN(v)) {
        this.queryParams.filter_status_id = v;
        this.handleQueryParamChange();
      } else {
        this.queryParams.filter_status_id = undefined;
        this.handleQueryParamChange();
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const folderId = params.get('request_folder_id');
      if (folderId) {
        this.currentFolderId = parseInt(folderId);
        this.fetchCurrentFolder();
      } else {
        this.currentFolderData = undefined;
        this.currentFolderId = undefined;
      }

      this.route.queryParamMap.subscribe((params) => {
        let queryParams: Record<string, string | null> = {};
        params.keys.forEach((k) => {
          queryParams[k] = params.get(k);
        });

        this.queryParams = queryParams;

        this.searchControl.setValue(this.queryParams.search);
        this.statusFilterControl.setValue(
          this.queryParams.filter_status_id || 'all'
        );

        this.handleRequestFetch();
      });
    });
  }

  requests: AppRequest[] = [];
  requestFolders: RequestFolder[] = [];
  totalRequestsCount = 0;

  searchControl = new FormControl('');

  fetchRequestFolders() {
    this.requestFolderService
      .filterRequestFolders({
        ...this.queryParams,
        currentFolderId: this.currentFolderId || undefined,
      })
      .subscribe((c) => {
        this.requests = c.data.requests;
        this.requestFolders = c.data.folders;
        this.totalRequestsCount = c.data.total;
      });
  }

  fetchCurrentFolder() {
    if (this.currentFolderId) {
      this.requestFolderService.getFolderById(this.currentFolderId).subscribe({
        next: (res) => {
          this.currentFolderData = res.data;
        },
        error: () => {
          this.currentFolderData = undefined;
          this.toastr.error(
            this.translocoService.translate(`'Request folder not found`)
          );
        },
      });
    }
  }

  handleRequestFetch() {
    this.fetchRequestFolders();
  }

  doSearch() {
    if (this.searchControl.value) {
      this.queryParams.search = this.searchControl.value;
    } else {
      this.queryParams.search = undefined;
    }

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  copyPublicLinkToClipboard(hash: string) {
    const url = `${window.origin}/request/${hash}`;
    this.clipboard.copy(url);
    this.toastr.info(this.translocoService.translate(`Link copied`));
  }

  openSendModal(request: AppRequest) {
    this.sendRequestModalService.modalEvents.next({
      type: 'open',
      request,
    });
  }

  async openCreateFolderModal() {
    while (this.currentFolderId && !this.currentFolderData) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    this.createFolderModalService.modalEvents.next({
      type: 'open',
      currentFolder: this.currentFolderId
        ? {
            id: this.currentFolderId,
            customer_id: this.currentFolderData!.customer_id,
          }
        : undefined,
    });
  }

  get selectedStatusTitle() {
    if (this.statusFilterControl.value === 'all') {
      return this.translocoService.translate(`All Status`);
    }
    const selectedStatus = this.status_list.find(
      (s) => s.id === parseInt(this.statusFilterControl.value)
    );

    return selectedStatus?.title;
  }

  openFolderEditModal() {
    this.createFolderModalService.modalEvents.next({
      type: 'edit',
      folder_id: this.currentFolderId!,
    });
  }
}
