<app-public-navbar></app-public-navbar>
<section class="mb-20 duration-200 bg-white font-Montserrat">
  <div
    class="container mx-auto text-white py-3 my-6 text-center font-medium text-base leading-46px -tracking-0.022em rounded-3xl {{
      topNotificationBanner?.bgClass
    }}"
    *ngIf="topNotificationBanner.show"
    [ngClass]="{ hidden: collapsed }"
  >
    {{ topNotificationBanner.message }}
  </div>
  <div
    class="container mx-auto grid grid-cols-1 xl:grid-cols-10 py-16 gap-6"
    [ngClass]="{ 'py-16': !collapsed, 'py-4': collapsed }"
  >
    <div>
      <img
        src="assets/imgs/grow_logo.png"
        alt="grow-logo"
        class="w-full rounded-2xl border"
        [ngClass]="{ 'max-w-[140px]': !collapsed, 'max-w-[80px]': collapsed }"
      />
    </div>
    <div
      class="flex flex-col xl:col-span-4"
      [ngClass]="{ 'py-1': collapsed, 'py-6': !collapsed }"
    >
      <h2
        class="text-primary opacity-60 font-semibold text-sm tracking-0.6px leading-22.4px mb-3"
        transloco="Datenanfrage für:"
      >
        Datenanfrage für:
      </h2>
      <h1
        class="text-primary font-bold tracking-0.022em mb-4"
        [ngClass]="{
          'text-2xl leading-28.4px': collapsed,
          'text-[40px] leading-48px': !collapsed
        }"
      >
        {{ requestData?.title }}
      </h1>
      <p class="text-15px font-normal leading-6" *ngIf="!collapsed">
        {{ requestData?.description }}
      </p>
      <a
        *ngIf="
          requestViewPageService.isAgency || requestViewPageService.producerHash
        "
        [href]="'/api/requests/download_files/' + requestData?.public_hash"
        class="tw-btn-secondary max-w-[500px] flex items-center gap-3 justify-center font-bold text-15px leading-6"
        [ngClass]="{
          'my-1': collapsed,
          'my-6': !collapsed,
          'pointer-events-none opacity-60':
            requestViewPageService.waitingDownload
        }"
        (click)="requestViewPageService.addFakeDownloadDelay()"
      >
        <span transloco="Daten gesammelt herunterladen"
          >Daten gesammelt herunterladen</span
        >
        ({{ requestViewPageService.globalSectionList.length }})
        <img src="assets/imgs/icons/download_icon.svg" alt="" />
      </a>
    </div>
    <div
      class="xl:col-span-3 border rounded-3xl p-6 w-full flex flex-col justify-center"
    >
      <div
        class="flex justify-between border-b pb-4 border-neutral_gray-300"
        [ngClass]="{ 'pt-4': !collapsed }"
      >
        <div
          class="text-primary opacity-60 font-semibold text-sm leading-22.4px min-w-[140px]"
        >
          Auftraggeber:
        </div>
        <div class="text-black font-bold text-sm leading-22.4px">
          {{ requestData?.client?.company_name }}
        </div>
      </div>
      <div
        class="flex justify-between border-b py-4 border-neutral_gray-300"
        *ngIf="!collapsed"
      >
        <div
          class="text-primary opacity-60 font-semibold text-sm leading-22.4px min-w-[140px]"
        >
          Kunde:
        </div>
        <div class="text-black font-bold text-sm leading-22.4px">
          {{ requestData?.client?.customer?.label }}
        </div>
      </div>
      <div
        class="flex justify-between border-b py-4 border-neutral_gray-300"
        *ngIf="!collapsed"
      >
        <div
          class="text-primary opacity-60 font-semibold text-sm leading-22.4px min-w-[140px]"
        >
          Angefordert am:
        </div>
        <div class="text-black font-bold text-sm leading-22.4px">
          {{ requestData?.created_at | date: "dd, MMMM yyyy - HH:mm" }} Uhr
        </div>
      </div>
      <div
        class="flex justify-between border-b pt-4 border-neutral_gray-300"
        [ngClass]="{ 'border-b pb-4': !collapsed }"
      >
        <div
          class="text-primary opacity-60 font-semibold text-sm leading-22.4px min-w-[140px]"
        >
          Abgabedatum:
        </div>
        <div
          class="text-semantic-danger-500 font-bold text-sm leading-22.4px"
          *ngIf="requestData?.due_date"
        >
          {{ requestData?.due_date | date: "dd, MMMM yyyy - HH:mm" }} Uhr
        </div>
        <div
          class="text-black font-bold text-sm leading-22.4px"
          *ngIf="!requestData?.due_date"
        >
          -
        </div>
      </div>
      <div class="flex justify-between pt-4" *ngIf="!collapsed">
        <div
          class="text-primary opacity-60 font-semibold text-sm leading-22.4px min-w-[140px]"
        >
          Projektnummer:
        </div>
        <div class="text-black font-bold text-sm leading-22.4px">
          {{
            requestData?.project_number !== undefined
              ? requestData?.project_number
              : "-"
          }}
        </div>
      </div>
    </div>
    <div
      class="xl:col-span-2 border rounded-3xl py-4 px-6 w-full flex flex-col justify-center"
    >
      <div
        class="text-primary opacity-60 font-semibold text-sm leading-22.4px"
        transloco="Gesamtstatus:"
      >
        Gesamtstatus:
      </div>
      <app-status-display
        [variant]="1"
        [statusId]="requestData?.request_status_id!"
        *ngIf="requestData?.request_status_id"
      ></app-status-display>
      <ng-container *ngIf="!collapsed">
        <div
          class="mt-5 text-primary opacity-60 font-semibold text-sm leading-22.4px tracking-0.6px uppercase pb-2 border-b border-neutral_gray-300"
          transloco="Statistik"
        >
          Statistik
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-gray7"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Warten auf Upload"
            >
              Warten auf Upload
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[1] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-orange1"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Bereit für Versand"
            >
              Bereit für Versand
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[101] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-light-blue2"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Warten auf Agentur"
            >
              Warten auf Agentur
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ requestViewPageService.globalSectionStatusCounter[2] || 0 }}
          </div>
        </div>
        <div class="flex justify-between pt-3 opacity-40">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-primaryBtn"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Bürstenabzug"
            >
              Bürstenabzug
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ proofFileSectionsCount }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-green2"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Freigabe"
            >
              Freigabe
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ successSectionsCount }}
          </div>
        </div>
        <div class="flex justify-between pt-3">
          <div class="flex items-center gap-2">
            <div class="w-3 h-3 rounded-full bg-red3"></div>
            <div
              class="text-xs text-primary font-normal leading-19.2px"
              transloco="Daten Fehler"
            >
              Daten Fehler
            </div>
          </div>
          <div class="text-xs text-primary font-semibold leading-19.2px">
            {{ errorSectionsCount }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="flex gap-2 items-center">
    <hr class="flex-grow border-none h-[2px] bg-gray13" />
    <button
      class="flex justify-center items-center p-4 border rounded-full hover:bg-gray-50 transition-transform duration-200"
      (click)="toggleCollapsed()"
      [ngClass]="{ 'rotate-180': !collapsed }"
    >
      <img
        src="/assets/imgs/icons/bs-chevron-down.svg"
        alt="expand / collapse"
      />
    </button>
    <hr class="flex-grow border-none h-[2px] bg-gray13" />
  </div>
</section>
