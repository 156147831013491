import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { initFlowbite } from 'flowbite';
import {RequestSection, RequestService, RequestUploadedFile} from '../../backend-services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadModalService } from '../file-upload-modal/file-upload-modal.service';
import {TmpFileUploadResponse} from "../../backend-services/tmp-files.service";
import {SectionState} from "../request-view-page.component";
import {RequestFilePreviewService} from "../request-file-preview.service";
import {HttpClient} from "@angular/common/http";

export type RequestValidationData = {
  title: string;
  content: string;
  isValid: 'none' | 'valid' | 'invalid';
  error_message?: string;
};

@Component({
  selector: 'app-request-section',
  templateUrl: './request-section.component.html',
  styleUrls: ['./request-section.component.scss'],
})
export class RequestSectionComponent implements OnInit, AfterViewInit {
  @Input() section: RequestSection | undefined;
  @Input() requestPublicHash: string = '';
  @Input() sectionState: SectionState | undefined;

  @Output() removeFileEvent = new EventEmitter();


  constructor(
    public sanitizer: DomSanitizer,
    public fileUploadModalService: FileUploadModalService,
    public requestFilePreviewService: RequestFilePreviewService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.fileUploadModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'upload_file':
        case 'file_validated':
          this.cdr.detectChanges();
          break;
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    initFlowbite();
  }

  onFileChange(event: any) {
    const newFile = event.target.files[0];

    this.fileUploadModalService.modalEvents.next({
      type: 'upload_file',
      file: newFile,
      data: {
        section: this.section!,
        request_hash: this.requestPublicHash,
        uploadedFiles: this.sectionState!.uploadedFiles,
      },
    });
  }

  removeExistingFile(uploaded_file_id: number, preview_url: string) {
    if (this.requestFilePreviewService.selectedFile?.preview_url === preview_url) {
      this.requestFilePreviewService.selectedFile = undefined;
    }

    this.removeFileEvent.emit({
      type: 'existing',
      section_id: this.section?.id,
      uploaded_file_id
    })
  }

  removeTmpFile(fileIndex: number, preview_url: string) {
    if (this.requestFilePreviewService.selectedFile?.preview_url === preview_url) {
      this.requestFilePreviewService.selectedFile = undefined;
    }

    this.removeFileEvent.emit({
      type: 'tmp',
      section_id: this.section?.id,
      uploaded_file_id: fileIndex
    })
  }

  setPreviewFile(file: TmpFileUploadResponse | RequestUploadedFile) {
      this.requestFilePreviewService.selectedFile = file;
  }

  downloadImage(imageUrl: string, filename: string) {
    this.http.get(imageUrl, { responseType: 'blob' })
      .subscribe(blob => {
        // 1. Create a temporary download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // 2. Simulate a click on the link
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        // 3. Cleanup
        setTimeout(() => { // Allow download to start
          window.URL.revokeObjectURL(url);
          link.remove();
        }, 100);
      });
  }

  get canUpload() {
    const fileCount = this.sectionState!.existingFiles!.length + this.sectionState!.uploadedFiles!.length;
    if(this.section!.max_file_count > fileCount) {
      return true;
    }

    let hasValidFiles = true;

    // check if section is valid and max file count reached
    if (
      this.sectionState?.uploadedFiles.length === 0 &&
      this.sectionState?.existingFiles.length === 0
    ) {
      hasValidFiles = false;
    }

    if (
      this.sectionState?.validationData.maxFileCountValidation.isValid ===
      'invalid'
    ) {
      hasValidFiles = false;
    }

    if (
      this.sectionState?.validationData.maxFileSizeValidation.isValid ===
      'invalid'
    ) {
      hasValidFiles = false;
    }

    if (
      this.sectionState?.validationData.expectedFileTypesValidation.isValid ===
      'invalid'
    ) {
      hasValidFiles = false;
    }

    this.sectionState?.validationData.expectedFileValidations.forEach(
      (fileValidations, fileType) => {
        fileValidations.forEach((validationData, validationType) => {
          if (validationData.isValid === 'invalid') {
            hasValidFiles = false;
          }
        });
      }
    );

    return !hasValidFiles;

  }

}
