import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  TmpFilesService,
  TmpFileUploadResponse,
} from '../../backend-services/tmp-files.service';
import { HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {
  RequestSection,
  RequestService,
  RequestUploadedFile,
  RequestValidationResult,
} from '../../backend-services/request.service';
import { RequestValidationData } from '../request-section/request-section.component';

@Injectable({
  providedIn: 'root',
})
export class FileUploadModalService {
  public readonly modalEvents = new Subject<
    | { type: 'open' }
    | { type: 'close' }
    | {
        type: 'upload_file';
        file: File;
        data: {
          request_hash: string;
          section: RequestSection;
          uploadedFiles: TmpFileUploadResponse[];
        };
      }
    | {
        type: 'file_validated';
        data: {
          section_id: number;
          uploadResponse: TmpFileUploadResponse;
          validationResult: RequestValidationResult;
        };
      }
  >();
  request_hash: string = '';

  fileUploading: boolean = false;
  uploadProgress?: number = 0;

  uploadResponse?: any;

  currentFileName = '';
  maxFileCount = 0;
  uploadedFiles: TmpFileUploadResponse[] = [];
  existingFiles: RequestUploadedFile[] = [];
  section_id = -1;

  showValidationResults: boolean = false;

  maxFileSizeValidation: RequestValidationData = {
    title: 'Maximale Dateigröße:',
    content: '',
    isValid: 'none',
  };
  expectedFileTypesValidation: RequestValidationData = {
    title: 'Erlaubte Dateiformate:',
    content: '',
    isValid: 'none',
  };
  fileTypeBasedValidations = new Map<string, RequestValidationData>();

  constructor(
    private tmpFilesService: TmpFilesService,
    private toastr: ToastrService,
    private requestService: RequestService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'close':
          this.section_id = -1;
          this.uploadResponse = undefined;
          this.fileUploading = false;
          this.uploadProgress = 0;
          this.maxFileCount = 0;
          this.request_hash = '';
          this.currentFileName = '';
          this.uploadedFiles = [];
          this.existingFiles = [];
          this.showValidationResults = false;

          this.maxFileSizeValidation = {
            title: 'Maximale Dateigröße:',
            content: '',
            isValid: 'none',
          };
          this.expectedFileTypesValidation = {
            title: 'Erlaubte Dateiformate:',
            content: '',
            isValid: 'none',
          };
          this.fileTypeBasedValidations = new Map<
            string,
            RequestValidationData
          >();
          break;

        case 'upload_file':
          this.modalEvents.next({ type: 'open' });

          this.section_id = event.data.section.id;
          this.maxFileCount = event.data.section.max_file_count;
          this.request_hash = event.data.request_hash;
          this.uploadedFiles = event.data.uploadedFiles;
          this.existingFiles = event.data.section.uploaded_files;
          this.fileUploading = true;

          this.currentFileName = event.file.name;

          this.tmpFilesService.tmpFileUpload(event.file).subscribe((data) => {
            if (data.type === HttpEventType.UploadProgress) {
              const total = data.total || 1;
              this.uploadProgress = Math.round((100 * data.loaded) / total);
            }

            if (data.type === HttpEventType.Response) {
              this.fileUploading = false;

              if (data.status === 200) {
                this.uploadResponse = (data.body as any).data;

                // add the initial file validations
                this.maxFileSizeValidation.content =
                  event.data.section!.max_file_size > 2048
                    ? `${event.data.section!.max_file_size / 1024} MB`
                    : `${event.data.section!.max_file_size} KB`;
                this.expectedFileTypesValidation.content = event.data
                  .section!.expected_files.map((f) => f.file_type.name)
                  .join(', ');

                if (this.uploadResponse.file_type) {
                  const matchingExpectedFile =
                    event.data.section!.expected_files.find(
                      (expectedFile) =>
                        expectedFile.file_type_id ===
                        this.uploadResponse.file_type.id
                    );

                  if (matchingExpectedFile) {
                    if (matchingExpectedFile.color_space) {
                      this.fileTypeBasedValidations.set('color_space', {
                        title: 'Farbraum:',
                        content: matchingExpectedFile.color_space,
                        isValid: 'none',
                      });
                    }

                    if (
                      matchingExpectedFile.dimensions_height &&
                      matchingExpectedFile.dimensions_width &&
                      matchingExpectedFile.dimensions_unit
                    ) {
                      this.fileTypeBasedValidations.set('dimensions', {
                        title: 'Datenformat oder Abmessungen:',
                        content: `${matchingExpectedFile.dimensions_width} x ${matchingExpectedFile.dimensions_height} ${matchingExpectedFile.dimensions_unit}`,
                        isValid: 'none',
                      });
                    }

                    if (matchingExpectedFile.resolution) {
                      this.fileTypeBasedValidations.set('resolution', {
                        title: 'Minimale Auflösung:',
                        content: `${matchingExpectedFile.resolution} dpi`,
                        isValid: 'none',
                      });
                    }
                  }
                }

                this.showValidationResults = true;

                // check the file validations
                this.requestService
                  .validateRequestFile({
                    file_path: this.uploadResponse.path,
                    request_hash: this.request_hash,
                    section_id: this.section_id,
                  })
                  .subscribe((data) => {
                    //set the validation values
                    this.maxFileSizeValidation.isValid = data.data.file_size
                      .valid
                      ? 'valid'
                      : 'invalid';
                    if (!data.data.file_size.valid) {
                      this.maxFileSizeValidation.error_message =
                        data.data.file_size.message;
                    }

                    this.expectedFileTypesValidation.isValid = data.data
                      .file_type.valid
                      ? 'valid'
                      : 'invalid';
                    if (!data.data.file_type.valid) {
                      this.expectedFileTypesValidation.error_message =
                        data.data.file_type.message;
                    }

                    if (
                      this.fileTypeBasedValidations.get('color_space') &&
                      data.data.color_space
                    ) {
                      this.fileTypeBasedValidations.get(
                        'color_space'
                      )!.isValid = data.data.color_space.valid
                        ? 'valid'
                        : 'invalid';
                      if (!data.data.color_space.valid) {
                        this.fileTypeBasedValidations.get(
                          'color_space'
                        )!.error_message = data.data.color_space.message;
                      }
                    }

                    if (
                      this.fileTypeBasedValidations.get('dimensions') &&
                      data.data.dimensions
                    ) {
                      this.fileTypeBasedValidations.get('dimensions')!.isValid =
                        data.data.dimensions.valid ? 'valid' : 'invalid';
                      if (!data.data.dimensions.valid) {
                        this.fileTypeBasedValidations.get(
                          'dimensions'
                        )!.error_message = data.data.dimensions.message;
                      }
                    }

                    if (
                      this.fileTypeBasedValidations.get('resolution') &&
                      data.data.resolution
                    ) {
                      this.fileTypeBasedValidations.get('resolution')!.isValid =
                        data.data.resolution.valid ? 'valid' : 'invalid';
                      if (!data.data.resolution.valid) {
                        this.fileTypeBasedValidations.get(
                          'resolution'
                        )!.error_message = data.data.resolution.message;
                      }
                    }

                    this.uploadResponse.is_valid = data.data.is_valid;

                    this.modalEvents.next({
                      type: 'file_validated',
                      data: {
                        section_id: this.section_id,
                        uploadResponse: this.uploadResponse,
                        validationResult: data.data,
                      },
                    });
                  });
              } else {
                this.toastr.error(
                  'Something went wrong when uploading the file'
                );
                console.error(data);
              }
            }
          });
      }
    });
  }
}
