import { Component } from '@angular/core';
import { SectionCollectionEditModalService } from './section-collection-edit-modal.service';

@Component({
  selector: 'app-section-collection-edit-modal',
  templateUrl: './section-collection-edit-modal.component.html',
  styleUrls: ['./section-collection-edit-modal.component.scss'],
})
export class SectionCollectionEditModalComponent {
  constructor(public modalService: SectionCollectionEditModalService) {}

  protected readonly SectionCollectionEditModalService = SectionCollectionEditModalService;
}
