<div>
  <div class="w-full flex justify-end gap-4" *hasCapability="'MANAGE_USERS'">
    <div>
      <a
        [routerLink]="[ManageSingleUserRoute]"
        *ngIf="queryParams.management_type !== 'customers'"
        class="text-orange1 border-orange1 border rounded-lg focus:outline-1 outline-orange1 font-semibold py-2 px-4 2xl:py-4 2xl:px-6 hover:text-white hover:bg-orange1 duration-200 transition-all"
        transloco="+ New User"
      >
        + New User
      </a>
    </div>
    <div *isSuperAdmin>
      <a
        [routerLink]="[ManageSingleCustomerRoute]"
        class="text-orange1 border-orange1 border rounded-lg focus:outline-1 outline-orange1 font-semibold py-2 px-4 2xl:py-4 2xl:px-6 hover:text-white hover:bg-orange1 duration-200 transition-all"
        *ngIf="queryParams.management_type === 'customers'"
        transloco="+ New Customer"
        >+ New Customer</a
      >
    </div>
  </div>

  <div
    class="flex w-full border-b-2 gap-4 transition-all duration-500 text-gray4 font-semibold"
    *isSuperAdmin
  >
    <button
      class="px-6 py-2 border-orange1"
      [ngClass]="{
        'border-b-4 text-orange1': queryParams.management_type === 'users'
      }"
      (click)="changeManagementType('users')"
      type="button"
      role="tab"
      aria-controls="a-tab-pane"
      transloco="Benutzer"
    >
      Benutzer
    </button>
    <button
      class="px-6 py-2 border-orange1"
      [ngClass]="{
        'border-b-4 text-orange1': queryParams.management_type === 'customers'
      }"
      (click)="changeManagementType('customers')"
      data-bs-toggle="tab"
      data-bs-target="#b-tab-pane"
      type="button"
      role="tab"
      aria-controls="b-tab-pane"
      transloco="Kunden"
    >
      Kunden
    </button>
  </div>

  <div class="pt-4" *transloco="let t;">
    <div *ngIf="queryParams.management_type === 'users'">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500 ">
          <thead class="text-xs 2xl:text-sm text-black uppercase">
            <tr>
              <th scope="col" class="border-l border-t border-b w-8"></th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 text-start">
                <app-sortable-table-header
                  [displayName]="t('Firstname')"
                  fieldName="first_name"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 ">
                <app-sortable-table-header
                  [displayName]="t('Lastname')"
                  fieldName="last_name"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 ">
                <app-sortable-table-header
                  [displayName]="t('Email')"
                  fieldName="email"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 ">
                <app-sortable-table-header
                  [displayName]="t('Role')"
                  fieldName="role_id"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 text-center" *isSuperAdmin>
                <app-sortable-table-header
                  [displayName]="t('Kunde')"
                  fieldName="customer_id"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3 ">
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="w-6 border-t border-b border-r border-gray3 pr-6 py-3 "></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of allUsers"
              class="bg-white text-xs 2xl:text-sm border-b hover:bg-gray-50"
            >
              <td class="px-2">
                <div class="py-2 px-2">
                  <app-profile-picture
                    [firstName]="user.first_name"
                    [lastName]="user.last_name"
                  ></app-profile-picture>
                </div>
              </td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <app-role-name-display
                  [roleId]="user.role_id"
                ></app-role-name-display>
              </td>
              <td *isSuperAdmin>
                <app-company-name-display
                  [customerId]="user.customer_id"
                ></app-company-name-display>
              </td>
              <td>
                <span class="uppercase bg-orange1 text-white px-3 py-2" *ngIf="user.enabled" transloco="Active"
                  >Active</span
                >
                <span class="status uppercase bg-gray-100 text-darkBlue2 px-3 py-2" *ngIf="!user.enabled" transloco="Inactive"
                  >Inactive</span
                >
              </td>
              <td>
                <a
                  class="user-edit link-icon-svg-black"
                  routerLink="/user-management/user/{{ user.id }}"
                  *hasCapability="'MANAGE_USERS'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                    />
                  </svg>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalUsers"></app-pagination>
    </div>
    <div *ngIf="queryParams.management_type === 'customers'">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500 ">
          <thead class="text-xs 2xl:text-sm text-black uppercase">
            <tr>
              <th scope="col" class="border-l border-t border-b w-8"></th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  class="justify-start"
                  [displayName]="t('Name')"
                  fieldName="label"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Lizenz')"
                  fieldName="licence"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Laufzeitende')"
                  fieldName="licence_data"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Benutzer')"
                  fieldName="user_count"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="w-16 border-t border-b border-r border-gray3 pr-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of allCustomers">
              <td>
                <div class="w-[60px] h-[60px] flex justify-center items-center">
                  <img
                    src="/assets/imgs/dummy/company4.png"
                    alt=""
                    class="company-icon"
                  />
                </div>
              </td>
              <td>{{ customer.label }}</td>
              <td>(no data)</td>
              <td>
                <span class="status status-active" *ngIf="customer.enabled" transloco="Aktiv"
                  >Aktiv</span
                >
                <span class="status status-deactive" *ngIf="!customer.enabled" transloco="Deaktiviert"
                  >Deaktiviert</span
                >
              </td>
              <td transloco="(no data)">(no data)</td>
              <td>{{ customer.user_count }}</td>
              <td>
                <div class="flex gap-x-2">
                  <a
                    class="user-edit d-inline-block link-icon-svg-black"
                    routerLink="/user-management/customer/{{ customer.id }}"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                      />
                    </svg>
                  </a>
                  <a
                    class="add-user d-inline-block link-icon-svg-black"
                    href="/user-management/user?customer_id={{ customer.id }}"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM15 6C16.1 6 17 6.9 17 8C17 9.1 16.1 10 15 10C13.9 10 13 9.1 13 8C13 6.9 13.9 6 15 6ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14ZM9 18C9.22 17.28 12.31 16 15 16C17.7 16 20.8 17.29 21 18H9ZM6 15V12H9V10H6V7H4V10H1V12H4V15H6Z"
                      />
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalCustomers"></app-pagination>
    </div>
  </div>
</div>
