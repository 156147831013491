import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../backend-services/request.service';

@Injectable({
  providedIn: 'root',
})
export class RejectRequestModalService {
  public readonly modalEvents = new Subject<
    | {
        type: 'open';
        id: number;
        producer_hash?: string;
        new_status_id?: number;
      }
    | { type: 'close' }
  >();
  requestId?: number;
  producerHash?: string;
  messageControl = new FormControl();
  isSubmitting = false;
  new_status_id: number | null = null;

  constructor(
    private toastrService: ToastrService,
    private requestService: RequestService
  ) {
    this.modalEvents.subscribe((event) => {
      if (event.type === 'open') {
        this.requestId = event.id;
        this.producerHash = event.producer_hash;
        if (event.new_status_id) {
          this.new_status_id = event.new_status_id;
        } else if (this.producerHash) {
          this.new_status_id = 2;
        } else {
          this.new_status_id = 1;
        }
      }
    });
  }

  handleSubmit() {
    if (!this.messageControl.value) {
      this.toastrService.error('Please enter a message');
      return;
    }

    this.requestService
      .updateRequestStatus(this.requestId!, {
        status_id: this.new_status_id || 0,
        message: this.messageControl.value,
        producer_hash: this.producerHash,
      })
      .subscribe({
        next: (res) => {
          this.toastrService.success(res.message);
          this.modalEvents.next({ type: 'close' });
        },
        error: (res) => {
          this.toastrService.error(res.error.message);
        },
      });
  }
}
