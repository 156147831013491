import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TmpFilesService } from '../../backend-services/tmp-files.service';
import { HttpEventType } from '@angular/common/http';
import {
  RequestService,
  RequestValidationResult,
} from '../../backend-services/request.service';
import { ToastrService } from 'ngx-toastr';
import { RequestViewPageService } from '../request-view-page.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class FileUploadModalV4Service {
  modalEvents: Subject<
    | { type: 'open' }
    | {
        type: 'upload-file';
        file: File;
        request_hash: string;
        section_id: number;
        auto_close: boolean;
      }
    | {
        type: 'close';
        from_auto_close?: boolean;
      }
    | { type: 'init-upload-cancel' }
    | { type: 'upload-cancel' }
  > = new Subject();

  uploadStatus: 'idle' | 'uploading' | 'uploaded' | 'error' = 'idle';
  validationStatus: 'idle' | 'validating' | 'failed' | 'success' = 'idle';

  uploadProgress = 0;
  currentFileName?: string;

  validationResults?: RequestValidationResult = undefined;

  constructor(
    private tmpFilesService: TmpFilesService,
    private requestService: RequestService,
    private requestViewPageService: RequestViewPageService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'upload-file':
          this.uploadProgress = 0;
          this.uploadStatus = 'uploading';
          this.currentFileName = event.file.name;
          this.modalEvents.next({ type: 'open' });

          this.tmpFilesService.tmpFileUpload(event.file).subscribe((data) => {
            if (data.type === HttpEventType.UploadProgress) {
              const total = data.total || 1;
              this.uploadProgress = Math.min(
                99,
                Math.floor((100 * data.loaded) / total)
              );
            }

            if (data.type === HttpEventType.Response) {
              this.uploadProgress = 100;
              if (data.status === 200) {
                this.uploadStatus = 'uploaded';
                const uploadResponse = (data.body as any).data;

                this.validationStatus = 'validating';

                // cache files
                this.requestService
                  .cacheRequestFiles({
                    request_hash: event.request_hash,
                    files: [
                      {
                        file_path: uploadResponse.path,
                        section_id: event.section_id.toString(),
                      },
                    ],
                  })
                  .subscribe({
                    next: (data: any) => {
                      this.validationStatus = data.data[0].is_valid
                        ? 'success'
                        : 'failed';
                      this.validationResults = data.data[0];
                      if (event.auto_close) {
                        this.modalEvents.next({
                          type: 'close',
                          from_auto_close: true,
                        });
                      } else {
                        this.requestViewPageService.requestEvents.next({
                          type: 'updated',
                        });
                      }
                    },
                    error: (e) => {
                      console.log(e);
                      this.toastr.error(
                        this.translocoService.translate(
                          `Unknown error occurred`
                        )
                      );
                    },
                  });
              } else {
                this.uploadStatus = 'error';
              }
            }
          });
          break;

        case 'close':
          this.reset();
          break;
      }
    });
  }

  reset() {
    this.uploadProgress = 0;
    this.uploadStatus = 'idle';
    this.validationStatus = 'idle';
  }
}
