import { Injectable } from '@angular/core';
import {
  AppRequest,
  RequestSection,
  RequestService,
  SectionGroup,
} from '../backend-services/request.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../backend-services/auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class RequestViewPageService {
  requestPublicHash: string = '';
  producerHash: string | null = null;

  requestData?: AppRequest;

  selectedSection?: RequestSection;
  selectedSectionChangedEvent: Subject<string>;
  selectedSectionMetaData?: {
    sectionGroupIndex: number;
    sectionIndex: number;
    globalSectionIndex: number;
  };

  selectedUploadFileIndex = 0;

  globalSectionList: {
    section: RequestSection;
    sectionGroupIndex: number;
    sectionIndex: number;
  }[] = [];
  globalSectionStatusCounter: Record<number, number> = {};

  requestEvents: Subject<{ type: 'updated' }> = new Subject();
  isSubmitting = false;

  waitingDownload = false;

  // information about current user
  isAgency = false;

  constructor(
    private requestService: RequestService,
    private toastr: ToastrService,
    private authService: AuthService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService
  ) {
    this.requestEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'updated':
          this.fetchRequest();
      }
    });

    this.selectedSectionChangedEvent = new Subject();

    this.authService.profile.subscribe(async (profile) => {
      if (profile) {
        while (!this.requestData) {
          await new Promise((r) => setTimeout(r, 100));
        }
        if (
          !profile.customer_id ||
          this.requestData.customer_id === profile.customer_id
        ) {
          this.isAgency = true;
        }
      } else {
        this.isAgency = false;
      }
    });
  }

  sectionHasDataErrors(section: RequestSection) {
    let hasErrors = false;
    section.uploaded_files.forEach((f) => {
      if (f.validation_data?.is_valid === false) {
        hasErrors = true;
      }
    });

    return hasErrors;
  }

  setSelectedSection(selectedSection: RequestSection): void {
    this.selectedSection = undefined;

    this.selectedSectionMetaData = undefined;
    for (let i = 0; i < this.globalSectionList.length; i++) {
      const globalSectionListElement = this.globalSectionList[i];

      if (selectedSection.id === globalSectionListElement.section.id) {
        this.selectedSectionMetaData = {
          sectionIndex: globalSectionListElement.sectionIndex,
          sectionGroupIndex: globalSectionListElement.sectionGroupIndex,
          globalSectionIndex: i,
        };
        break;
      }
    }

    // this is due to issues with slider
    setTimeout(() => {
      this.selectedSection = selectedSection;
      this.selectedSectionChangedEvent.next('changed');
    }, 100);
  }

  fetchRequest() {
    this.requestService
      .getRequestByPublicHash(this.requestPublicHash)
      .subscribe((data) => {
        this.requestData = data.data;
        this.handleRequestDataChange();
      });
  }

  handleRequestDataChange() {
    this.updateGlobalSectionList();

    const tmpSelectedSection = this.selectedSection;
    if (tmpSelectedSection) {
      this.selectedSection = undefined;
      // update the selected section with fresh data

      setTimeout(() => {
        let found = false;
        mainLoop: for (let group of this.requestData!.section_groups!) {
          for (let section of group.sections) {
            if (section.id === tmpSelectedSection.id) {
              this.selectedSection = section;
              found = true;
              break mainLoop;
            }
          }
        }

        if (!found) {
          this.setDefaultSection();
        }
      }, 100);
    } else {
      this.setDefaultSection();
    }
  }

  setDefaultSection() {
    for (let group of this.requestData!.section_groups!) {
      if (group.sections[0]) {
        this.setSelectedSection(group.sections[0]);
        break;
      }
    }
  }

  selectedSectionGroupStatusId(sectionGroup: SectionGroup) {
    let status = 0;
    sectionGroup.sections.forEach((section) => {
      const currentStatusId = this.selectedSectionStatusId(section);

      if (currentStatusId === 1) {
        status = currentStatusId;
      }

      // i don't like how this looks, but it is how it is
      if (status !== 1) {
        if (currentStatusId === 102) {
          status = currentStatusId;
        }
        if (status !== 102) {
          if (currentStatusId === 101) {
            status = currentStatusId;
          }

          if (status !== 101) {
            if (currentStatusId === 202) {
              status = currentStatusId;
            }

            if (status !== 202) {
              if (currentStatusId === 302) {
                status = currentStatusId;
              }

              if (status !== 302) {
                status = currentStatusId;
              }
            }
          }
        }
      }

      if (status !== 1 && currentStatusId > status) {
        status = currentStatusId;
      }
    });

    return status;
  }

  selectedSectionStatusId(section?: RequestSection): number {
    const selectedSection = section || this.selectedSection;
    return selectedSection?.status || 0;
  }

  updateGlobalSectionList() {
    this.globalSectionList = [];
    this.globalSectionStatusCounter = {};

    const { statusCounter, globalSectionList } = this.buildGlobalSectionList();

    this.globalSectionList = globalSectionList;
    this.globalSectionStatusCounter = statusCounter;
  }

  buildGlobalSectionList() {
    const globalSectionList: {
      section: RequestSection;
      sectionGroupIndex: number;
      sectionIndex: number;
    }[] = [];
    const statusCounter: Record<number, number> = {};

    this.requestData?.section_groups?.forEach(
      (sectionGroup, sectionGroupIndex) => {
        sectionGroup.sections.forEach((section, sectionIndex) => {
          globalSectionList.push({
            section,
            sectionGroupIndex,
            sectionIndex,
          });

          // add status counter
          const statusId = this.selectedSectionStatusId(section);

          if (statusCounter[statusId]) {
            statusCounter[statusId] += 1;
          } else {
            statusCounter[statusId] = 1;
          }
        });
      }
    );

    return {
      globalSectionList,
      statusCounter,
    };
  }

  get showFloatingNavBar() {
    // first check the user type
    if (this.producerHash) {
      // this is for producers
      return this.requestData?.request_status_id === 3;
    } else if (this.authService.isAuthenticated.value) {
      // this is for agency
      return this.requestData?.request_status_id === 2;
    } else {
      // this is for clients
      return (
        this.requestData?.request_status_id === 1 ||
        this.requestData?.request_status_id === 203 ||
        this.requestData?.request_status_id === 303
      );
    }
  }

  get canSubmit() {
    if (this.isSubmitting) {
      return false;
    }

    // first check the user type
    if (this.producerHash) {
      // this is for producers
      return this.canSubmitAsProducer();
    } else if (this.authService.isAuthenticated.value) {
      // this is for agency
      return this.canSubmitAsAgency();
    } else {
      // this is for clients
      return this.canSubmitAsClient();
    }
  }

  canSubmitAsClient() {
    const { globalSectionList: originalSections } =
      this.buildGlobalSectionList();

    // if brand new request
    if (this.requestData?.request_status_id === 1) {
      // check if it is a fresh request
      const isFreshRequest =
        originalSections.filter(
          (section) =>
            section.section.status === 1 ||
            section.section.status === 101 ||
            section.section.status === 102
        ).length === originalSections.length;

      if (isFreshRequest) {
        // allow to submit if all files are uploaded and without errors
        return (
          this.globalSectionStatusCounter[101] === this.globalSectionList.length
        );
      } else {
        // this path is for re-uploading rejected requests
        const pendingSections = originalSections.filter(
          (section) =>
            section.section.status === 202 ||
            section.section.status === 302 ||
            section.section.status === 101 ||
            section.section.status === 102
        );

        if (!pendingSections.length) {
          return false;
        }

        // check if new files are uploaded for rejected requests
        let allValid = true;
        pendingSections.forEach((section) => {
          if (
            this.requestData?.section_groups![section.sectionGroupIndex]
              ?.sections[section.sectionIndex]?.status !== 101
          ) {
            allValid = false;
          }
        });

        return allValid;
      }
    } else if (
      this.requestData?.request_status_id === 203 ||
      this.requestData?.request_status_id === 303
    ) {
      return (
        originalSections.filter(
          (section) =>
            section.section.status === 303 || section.section.status === 203
        ).length === 0
      );
    }

    return false;
  }

  canSubmitAsAgency() {
    if (this.requestData?.request_status_id === 2) {
      let canSubmit = true;
      this.globalSectionList.forEach((section) => {
        if (
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 202 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 203 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 4 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 3
        ) {
          canSubmit = false;
        }
      });

      return canSubmit;
    }

    return false;
  }

  canSubmitAsProducer() {
    if (this.requestData?.request_status_id === 3) {
      let canSubmit = true;
      this.globalSectionList.forEach((section) => {
        if (
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 302 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 303 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 4 &&
          this.requestData?.section_groups![section.sectionGroupIndex].sections[
            section.sectionIndex
          ].status !== 401
        ) {
          canSubmit = false;
        }
      });

      return canSubmit;
    }

    return false;
  }

  submit() {
    if (this.canSubmit) {
      this.isSubmitting = true;

      const sections = this.globalSectionList
        .filter(
          (s) =>
            s.section.status === 204 ||
            s.section.status === 205 ||
            s.section.status === 304 ||
            s.section.status === 305
        )
        .map((section) => {
          const comments = section.section.section_comments
            .filter((c) => !c.id)
            .map((comment) => ({
              title: comment.title,
              comment: comment.comment,
            }));

          return {
            id: section.section.id,
            status: section.section.status,
            section_comments: comments,
          };
        });

      this.requestService
        .submitRequestFilesV4({
          request_hash: this.requestPublicHash,
          sections: sections,
        })
        .subscribe({
          next: () => {
            this.toastr.success(
              this.translocoService.translate(`Request Submitted`)
            );
            this.fetchRequest();
            this.isSubmitting = false;
          },
          error: ({ error }) => {
            console.log(error);
            this.toastr.error(error.message);
          },
        });
    }
  }

  submitAsAgent() {
    if (this.canSubmit) {
      this.isSubmitting = true;

      const sections = this.globalSectionList.map((section) => {
        const comments = section.section.section_comments
          .filter((c) => !c.id)
          .map((comment) => ({
            title: comment.title,
            comment: comment.comment,
          }));

        const proof_file = section.section.proof_file?.is_new
          ? section.section.proof_file.path
          : undefined;

        return {
          id: section.section.id,
          status: section.section.status,
          section_comments: comments,
          producer_id: section.section.producer_id,
          producer_contacts: section.section.new_producer_contacts,
          proof_file,
        };
      });

      this.requestService
        .submitRequestAsAgency(this.requestPublicHash, {
          sections,
        })
        .subscribe({
          next: () => {
            this.toastr.success(
              this.translocoService.translate(`Request Submitted`)
            );
            this.fetchRequest();
            this.isSubmitting = false;
          },
          error: ({ error }) => {
            console.log(error);
            this.toastr.error(
              typeof error?.message === 'string'
                ? error.message
                : JSON.stringify(error)
            );
          },
        });
    }
  }

  submitAsProducer() {
    if (this.canSubmit) {
      this.isSubmitting = true;

      const sections = this.globalSectionList
        .map((section) => {
          const comments = section.section.section_comments
            .filter((c) => !c.id)
            .map((comment) => ({
              title: comment.title,
              comment: comment.comment,
            }));

          return {
            id: section.section.id,
            status: section.section.status,
            section_comments: comments,
            producer_id: section.section.producer_id,
            producer_contacts: section.section.new_producer_contacts,
          };
        })
        .filter((s) => s.status === 302 || s.status === 401);

      this.requestService
        .submitRequestAsProducer(this.requestPublicHash, {
          producer_hash: this.producerHash!,
          sections,
        })
        .subscribe({
          next: () => {
            this.toastr.success(
              this.translocoService.translate(`Request Submitted`)
            );
            this.fetchRequest();
            this.isSubmitting = false;
          },
          error: ({ error }) => {
            console.log(error);
            this.toastr.error(
              typeof error?.message === 'string'
                ? error.message
                : JSON.stringify(error)
            );
          },
        });
    }
  }

  addFakeDownloadDelay() {
    this.waitingDownload = true;

    setTimeout(() => {
      this.waitingDownload = false;
    }, 3000);
  }

  downloadFile(fileUrl: string, filename: string) {
    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((blob) => {
      // 1. Create a temporary download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;

      // 2. Simulate a click on the link
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // 3. Cleanup
      setTimeout(() => {
        // Allow download to start
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);
    });
  }

  getSectionThumbnail(section: RequestSection) {
    if (section.proof_file) {
      return this.sanitizer.bypassSecurityTrustUrl(
        section.proof_file.thumbnail_url
      );
    }
    if (section.uploaded_files[0]) {
      return this.sanitizer.bypassSecurityTrustUrl(
        section.uploaded_files[0].thumbnail_url
      );
    }

    return undefined;
  }
}
