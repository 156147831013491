<ng-container *transloco="let t">
  <section class="mb-16" *ngIf="currentUserLicense">
    <h2 class="text-2xl font-semibold" transloco="Your Package">Your Package</h2>
    <div
      class="my-4 border p-8 rounded-xl hover:bg-gray-50 transition-all duration-300 w-fit"
    >
      <div class="font-bold text-xl mb-6">
        {{ currentUserLicense!.package?.name }}
      </div>
      <div>
        {{ t("Users:") }}
        <span class="font-bold">{{
          currentUserLicense!.usage?.userCount
        }}</span>
        {{ t("of") }}
        {{ currentUserLicense!.usage.availableUserCount }}
      </div>
      <div>
        {{ t("Projects:") }}
        <span class="font-bold">{{
          currentUserLicense!.usage.projectCount
        }}</span>
        {{ t("of") }}
        {{ currentUserLicense!.usage.availableProjectCount }}
      </div>
      <div>
        {{ t("Storage:") }}
        <span class="font-bold">{{
          currentUserLicense.usage.storageUsage | fileSize: 1
        }}</span>
        /
        {{ currentUserLicense.usage.availableStorage | fileSize }}
      </div>
    </div>
  </section>
  <section class="mb-16" *hasCapability="'EDIT_CUSTOMER'">
    <h2 class="text-2xl font-semibold" transloco="Change Package">
      Change Package
    </h2>
    <div class="flex gap-4 flex-wrap">
      <div
        class="my-4 border p-8 rounded-xl w-[300px] hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availablePackages"
      >
        <div>
          <div class="mb-4 min-h-[25px]">
            <div
              *ngIf="p.tagline"
              class="w-fit px-2 py-1 bg-gradient-to-r from-white to-gray-100 text-xs font-medium text-gray4"
            >
              {{ p.tagline }}
            </div>
          </div>
          <div class="font-bold text-xl min-h-[100px]">{{ p.name }}</div>
          <div class="flex gap-2">
            <div class="font-bold text-3xl">€ {{ p.monthly_price }}</div>
            <div class="text-xs text-gray4 font-medium">
              {{ t("per") }}<br />{{ t("month") }}
            </div>
          </div>
          <button
            class="tw-btn-secondary w-full mt-6 mb-4"
            (click)="initPackageSubscription(p.id)"
            transloco="Subscribe"
            [disabled]="subscriptionButtonClicked"
          >
            Subscribe
          </button>
          <div>
            <div class="font-medium mb-2" transloco="This includes:">
              This includes:
            </div>
            <ul class="font-medium text-sm">
              <li class="my-1">
                <span class="text-gray4 mr-1">
                  <mat-icon class="check-icon">check_circle</mat-icon>
                </span>
                {{ p.included_storage_space | fileSize }}
                {{ t("Speicherplatz") }}
              </li>
              <li class="my-1">
                <span class="text-gray4 mr-1">
                  <mat-icon class="check-icon">check_circle</mat-icon>
                </span>
                {{ p.max_user_count }} {{ t("User") }}
              </li>
              <li class="my-1">
                <span class="text-gray4 mr-1">
                  <mat-icon class="check-icon">check_circle</mat-icon>
                </span>
                {{ p.max_user_count }} {{ t("Aktiv Projekte") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mb-16" *hasCapability="'EDIT_CUSTOMER'">
    <h2 class="text-2xl font-semibold" transloco="Addons">Addons</h2>
    <div class="flex gap-4 flex-wrap">
      <div
        class="my-4 border p-8 rounded-xl w-[300px] hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availableStorageAddons"
      >
        <div>
          <div class="mb-4 min-h-[25px]">
            <div
              *ngIf="p.tagline"
              class="w-fit px-2 py-1 bg-gradient-to-r from-white to-gray-100 text-xs font-medium text-gray4"
            >
              {{ p.tagline }}
            </div>
          </div>
          <div class="font-bold text-xl min-h-[100px]">{{ p.name }}</div>
          <div class="flex gap-2">
            <div class="font-bold text-3xl">€ {{ p.monthly_price }}</div>
            <div class="text-xs text-gray4 font-medium">
              {{ t("per") }}<br />{{ t("month") }}
            </div>
          </div>
          <button
            class="tw-btn-secondary w-full mt-6 mb-4"
            (click)="initStorageSubscription(p.id)"
            transloco="Subscribe"
            [disabled]="subscriptionButtonClicked"
          >
            Subscribe
          </button>
          <div>
            <div class="font-medium mb-2" transloco="This includes:">
              This includes:
            </div>
            <ul class="font-medium text-sm">
              <li class="my-1">
                <span class="text-gray4 mr-1">
                  <mat-icon class="check-icon">check_circle</mat-icon>
                </span>
                {{ p.included_storage_space | fileSize }}
                {{ t("Speicherplatz") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 flex-wrap">
      <div
        class="my-4 border p-8 rounded-xl w-[300px] hover:bg-gray-50 transition-all duration-300"
        *ngFor="let p of availableUserAddons"
      >
        <div>
          <div class="mb-4 min-h-[25px]">
            <div
              *ngIf="p.tagline"
              class="w-fit px-2 py-1 bg-gradient-to-r from-white to-gray-100 text-xs font-medium text-gray4"
            >
              {{ p.tagline }}
            </div>
          </div>
          <div class="font-bold text-xl min-h-[100px]">{{ p.name }}</div>
          <div class="flex gap-2">
            <div class="font-bold text-3xl">€ {{ p.monthly_price }}</div>
            <div class="text-xs text-gray4 font-medium">
              {{ t("per") }}<br />{{ t("month") }}
            </div>
          </div>
          <button
            class="tw-btn-secondary w-full mt-6 mb-4"
            (click)="initUserSubscription(p.id)"
            transloco="Subscribe"
            [disabled]="subscriptionButtonClicked"
          >
            Subscribe
          </button>
          <div>
            <div class="font-medium mb-2" transloco="This includes:">
              This includes:
            </div>
            <ul class="font-medium text-sm">
              <li class="my-1">
                <span class="text-gray4 mr-1">
                  <mat-icon class="check-icon">check_circle</mat-icon>
                </span>
                {{ p.included_user_count }} {{ t("User") }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
