import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestsListViewPageComponent } from './requests-list-view-page/requests-list-view-page.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SendRequestModalComponent } from './send-request-modal/send-request-modal.component';
import { RequestManagementLayoutComponent } from './request-management-layout/request-management-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { CreateFolderModalComponent } from './create-folder-modal/create-folder-modal.component';
import {DirectivesModule} from "../directives/directives.module";
import { EditRequestPageComponent } from './edit-request-page/edit-request-page.component';
import { RequestSectionsViewComponent } from './edit-request-page/request-sections-view/request-sections-view.component';
import { InfoUploadModalComponent } from './edit-request-page/info-upload-modal/info-upload-modal.component';
import {TranslocoModule} from "@ngneat/transloco";
import { RequestSectionListItemComponent } from './edit-request-page/request-section-list-item/request-section-list-item.component';
import { SectionCollectionEditModalComponent } from './edit-request-page/section-collection-edit-modal/section-collection-edit-modal.component';
import { SelectedSectionViewComponent } from './edit-request-page/selected-section-view/selected-section-view.component';

const requestManagementRoutes: Routes = [
  {
    path: '',
    component: RequestManagementLayoutComponent,
    children: [{ path: '', component: RequestsListViewPageComponent }],
  },
];

@NgModule({
  declarations: [
    RequestsListViewPageComponent,
    SendRequestModalComponent,
    RequestManagementLayoutComponent,
    CreateFolderModalComponent,
    EditRequestPageComponent,
    RequestSectionsViewComponent,
    InfoUploadModalComponent,
    RequestSectionListItemComponent,
    SectionCollectionEditModalComponent,
    SelectedSectionViewComponent,
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    RouterModule.forChild(requestManagementRoutes),
    DirectivesModule,
    TranslocoModule,
  ],
  exports: [SendRequestModalComponent, CreateFolderModalComponent],
})
export class RequestManagementModule {}
