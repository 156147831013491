import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AuthModule } from '../../../auth/auth.module';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SideDrawerComponent } from './side-drawer/side-drawer.component';
import {PipesModule} from "../../../pipes/pipes.module";

@NgModule({
  declarations: [FooterComponent, NavBarComponent, SideDrawerComponent],
  exports: [FooterComponent, NavBarComponent, SideDrawerComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AuthModule,
    TranslocoModule,
    PipesModule,
  ],
})
export class ComponentsModule {}
