<button
  data-modal-target="send-request-modal"
  data-modal-toggle="send-request-modal"
  class="hidden"
  #toggleSendRequestModalBtn
></button>
<button
  data-modal-target="send-request-modal"
  data-modal-show="send-request-modal"
  class="hidden"
  #showSendRequestModalBtn
></button>
<button
  data-modal-target="send-request-modal"
  data-modal-hide="send-request-modal"
  class="hidden"
  #hideSendRequestModalBtn
></button>
<!--Send Request per Mail Model-->
<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden drop-shadow-3xl fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="send-request-modal"
  tabindex="-1"
>
  <ng-container *transloco="let t;">
    <div class="relative p-3 2xl:p-4 w-full max-w-5xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-3xl shadow overflow-hidden">
        <div class="h-2 bg-orange1"></div>
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-3 2xl:p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h1 class="text-4xl font-bold text-gray-900 dark:text-white">
            {{ sendRequestModalService.currentRequest?.title }}
          </h1>
          <button
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            (click)="closeModal()"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="w-3 h-3"
              fill="none"
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
            <span class="sr-only" transloco="Close modal">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5 w-full">
          <form [formGroup]="sendRequestModalService.sendRequestForm">
            <div class="w-full">
              <label
                for="message"
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                transloco="Message"
                >Message</label
              >
              <textarea
                id="message"
                rows="6"
                formControlName="message"
                class="block p-2.5 w-full text-xs 2xl:text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primaryBtn focus:border-primaryBtn dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primaryBtn dark:focus:border-primaryBtn"
                [placeholder]="t('Enter your message...')"
              ></textarea>
            </div>
            <div class="w-full mt-4">
              <label
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                for="recipient"
                transloco="Add Recipients"
                >Add Recipients</label
              >
              <select
                id="recipient"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-xs 2xl:text-sm rounded-lg focus:ring-primaryBtn focus:border-primaryBtn block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primaryBtn dark:focus:border-primaryBtn"
                [formControl]="contactSelectInput"
              >
                <option disabled [value]="''" selected transloco="Select">
                  Select
                </option>
                <option
                  *ngFor="
                    let contact of sendRequestModalService.remainingContactList
                  "
                  [value]="contact.email"
                >
                  {{ contact.first_name }} {{ contact.last_name }} -
                  {{ contact.email }}
                </option>
              </select>
            </div>
            <div class="flex flex-wrap gap-4 py-2">
              <div
                *ngFor="let email of emailList"
                class="bg-white px-4 py-2 rounded-lg border-2"
              >
                {{ email }}
                <button
                  class="p-2"
                  (click)="sendRequestModalService.removeRecipient(email)"
                >
                  x
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- Modal footer -->
        <div
          class="w-full flex items-center justify-between p-3 2xl:p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600"
        >
          <button
            class="ms-3 text-gray-500 bg-white w-44 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primaryBtn rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            type="button"
            (click)="closeModal()"
            transloco="Cancel"
          >
            Cancel
          </button>
          <button
            class="text-white bg-primaryBtn hover:bg-primaryBtnHover w-44 focus:ring-4 focus:outline-none focus:ring-primaryBtn font-medium rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            type="button"
            (click)="sendRequestModalService.handleSubmit()"
            transloco="Send"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
