import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FileUploadModalService } from './file-upload-modal.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss'],
})
export class FileUploadModalComponent implements OnInit {
  @ViewChild('showFileUploadModalBtn') showFileUploadModalBtn?: ElementRef;
  @ViewChild('hideFileUploadModalBtn') hideFileUploadModalBtn?: ElementRef;

  constructor(
    public fileUploadModalService: FileUploadModalService,
    private cdr: ChangeDetectorRef
  ) {
    this.fileUploadModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showFileUploadModalBtn!.nativeElement.click();
          break;
        case 'close':
          this.hideFileUploadModalBtn!.nativeElement.click();
          break;
        case 'upload_file':
        case 'file_validated':
          this.cdr.detectChanges();
          break;
      }
    });
  }

  ngOnInit(): void {}

  get currentFileCount() {
    return (
      this.fileUploadModalService.existingFiles.length +
      this.fileUploadModalService.uploadedFiles.length +
      1
    );
  }

  closeModal() {
    this.fileUploadModalService.modalEvents.next({ type: 'close' });
  }
}
