import { Injectable } from '@angular/core';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {info} from "autoprefixer";

@Injectable({
  providedIn: 'root',
})
export class InfoUploadModalService {
  infoFileForm = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    description: new FormControl('', [Validators.maxLength(1000)]),
    uploaded_file_blob: new FormControl('', [Validators.required]),
    file: new FormControl('', []),
  });

  selectedSectionCollectionIndex = 0;
  selectedSectionIndex = 0;

  modalEvents: Subject<
    | { type: 'open'; sectionCollectionIndex: number; sectionIndex: number }
    | { type: 'close' }
  > = new Subject();

  constructor(
    private editRequestModalService: EditRequestModalService,
    private toastr: ToastrService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.selectedSectionCollectionIndex = event.sectionCollectionIndex;
          this.selectedSectionIndex = event.sectionIndex;
          break;
      }
    });
  }

  // handle info pdf file upload
  onFileChange(event: any) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      this.infoFileForm.get('uploaded_file_blob')?.setValue(file);
    }
  }

  handleCreateInfoFile() {
    if (this.infoFileForm.invalid) {
      this.infoFileForm.markAllAsTouched();

      if (this.infoFileForm.get('uploaded_file_blob')?.invalid) {
        this.toastr.error('File is required');
      }
      return;
    }

    const data = this.infoFileForm.value;

    const infoFg = this.editRequestModalService.buildInfoFilesFormGroup(data);
    infoFg.get('file')?.setValue(data.file);
    infoFg.get('uploaded_file_blob')?.setValue(data.uploaded_file_blob);

    const sectionCollection =  (
      this.editRequestModalService.requestFormGroup.get(
        'section_collections'
      ) as FormArray
    ).at(this.selectedSectionCollectionIndex) as FormGroup;

    const section = (sectionCollection.get('sections') as FormArray).at(this.selectedSectionIndex) as FormGroup;
    (section.controls.info_files as FormArray).push(infoFg);

    this.closeInfoUploadModal();
    this.infoFileForm.reset();
  }

  closeInfoUploadModal() {
    this.modalEvents.next({ type: 'close' });
  }
}
