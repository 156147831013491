import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SectionApprovalModalService } from './section-approval-modal.service';

@Component({
  selector: 'app-section-approval-modal',
  templateUrl: './section-approval-modal.component.html',
  styleUrls: ['./section-approval-modal.component.scss'],
})
export class SectionApprovalModalComponent {
  @ViewChild('toggleSectionApprovalModalBtn')
  toggleSectionApprovalModalBtn?: ElementRef;
  @ViewChild('showSectionApprovalModalBtn')
  showSectionApprovalModalBtn?: ElementRef;
  @ViewChild('hideSectionApprovalModalBtn')
  hideSectionApprovalModalBtn?: ElementRef;

  constructor(
    public sectionApprovalModalService: SectionApprovalModalService
  ) {
    this.sectionApprovalModalService.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          this.showSectionApprovalModalBtn?.nativeElement!.click();
          break;
        case 'close':
          this.hideSectionApprovalModalBtn?.nativeElement!.click();
          break;
      }
    });
  }

  closeModal() {
    this.sectionApprovalModalService.modalEvents.next({type: 'close'})
  }
}
