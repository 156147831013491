import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ProducerPickerModalService} from "./producer-picker-modal.service";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-producer-picker-modal',
  templateUrl: './producer-picker-modal.component.html',
  styleUrls: ['./producer-picker-modal.component.scss'],
})
export class ProducerPickerModalComponent implements OnInit {
  @ViewChild('showProducerPickerModalBtn')
  showProducerPickerModalBtn?: ElementRef;
  @ViewChild('hideProducerPickerModalBtn')
  hideProducerPickerModalBtn?: ElementRef;

  contactSelectInput = new FormControl('');

  constructor(public producerPickerModalService: ProducerPickerModalService) {
    this.producerPickerModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showProducerPickerModalBtn?.nativeElement!.click();
          break;
        case 'close':
          this.hideProducerPickerModalBtn?.nativeElement!.click();
          break;
      }
    });

    this.contactSelectInput.valueChanges.subscribe((val) => {
      if (val) {
        this.contactSelectInput.setValue('');
        const existingList = this.producerPickerModalService.toControl.value;
        existingList.push(val);
        this.producerPickerModalService.toControl.setValue(existingList);
      }
    });
  }

  ngOnInit(): void {}

  closeModal() {
    this.producerPickerModalService.modalEvents.next({ type: 'close' });
  }

  initClientModalClose() {
    this.closeModal();
  }
}
