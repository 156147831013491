import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AppRequest,
  RequestSection,
  RequestService,
  RequestUploadedFile,
} from '../backend-services/request.service';
import { FileUploadModalService } from './file-upload-modal/file-upload-modal.service';
import { TmpFileUploadResponse } from '../backend-services/tmp-files.service';
import { RequestValidationData } from './request-section/request-section.component';
import { ToastrService } from 'ngx-toastr';
import { CommentsModalService } from './comments-modal/comments-modal.service';
import { AuthService } from '../backend-services/auth.service';
import { ProducerPickerModalService } from './producer-picker-modal/producer-picker-modal.service';
import { RequestFilePreviewService } from './request-file-preview.service';
import { RejectRequestModalService } from './reject-request-modal/reject-request-modal.service';
import dayjs from 'dayjs';
import { CustomerSettingsService } from '../backend-services/customer-settings.service';

export type SectionState = {
  existingFiles: RequestUploadedFile[];
  uploadedFiles: TmpFileUploadResponse[];
  validationData: {
    expectedFileValidations: Map<string, Map<string, RequestValidationData>>;
    maxFileCountValidation: RequestValidationData;
    maxFileSizeValidation: RequestValidationData;
    expectedFileTypesValidation: RequestValidationData;
  };
};

@Component({
  selector: 'app-request-view-page',
  templateUrl: './request-view-page.component.html',
  styleUrls: ['./request-view-page.component.scss'],
})
export class RequestViewPageComponent implements OnInit {
  requestPublicHash: string = '';
  producerHash: string | null = null;

  requestData?: AppRequest;
  collapseTopBar: boolean = false;
  collapsedTimeStamp?: number;

  // uploaded file data
  sectionStates = new Map<number, SectionState>();
  isSubmitting = false;

  waitingDownload = false;

  constructor(
    private route: ActivatedRoute,
    private requestService: RequestService,
    private fileUploadModalService: FileUploadModalService,
    private commentsModalService: CommentsModalService,
    private toastr: ToastrService,
    public authService: AuthService,
    private producerPickerModalService: ProducerPickerModalService,
    public requestFilePreviewService: RequestFilePreviewService,
    private rejectRequestModalService: RejectRequestModalService,
    private toastrService: ToastrService,
    public customerSettingsService: CustomerSettingsService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.producerHash =
        this.route.snapshot.queryParamMap.get('producer_hash');

      this.requestPublicHash = params.get('publicHash') || '';
      this.fetchRequest();
    });

    this.fileUploadModalService.modalEvents.subscribe((event) => {
      if (
        event.type === 'file_validated' &&
        event.data.validationResult.is_valid
      ) {
        // set the validation values
        const sectionState = this.sectionStates.get(event.data.section_id);

        if (!sectionState) {
          return;
        }

        //set the validation values
        sectionState.validationData.maxFileSizeValidation.isValid = event.data
          .validationResult.file_size.valid
          ? 'valid'
          : 'invalid';
        if (!event.data.validationResult.file_size.valid) {
          sectionState.validationData.maxFileSizeValidation.error_message =
            event.data.validationResult.file_size.message;
        }

        sectionState.validationData.expectedFileTypesValidation.isValid = event
          .data.validationResult.file_type.valid
          ? 'valid'
          : 'invalid';
        if (!event.data.validationResult.file_type.valid) {
          sectionState.validationData.expectedFileTypesValidation.error_message =
            event.data.validationResult.file_type.message;
        }

        // validate max file count
        const currentSection = this.requestData?.sections?.find(
          (section) => section.id === event.data.section_id
        );
        currentSection?.max_file_count;

        if (
          currentSection &&
          currentSection.max_file_count >
            sectionState.uploadedFiles.length +
              sectionState.existingFiles.length
        ) {
          sectionState.validationData.maxFileCountValidation.isValid = 'valid';
        } else {
          sectionState.validationData.maxFileCountValidation.isValid =
            'invalid';
          sectionState.validationData.maxFileCountValidation.error_message = `Maximum file count of ${
            currentSection!.max_file_count
          } exceeded`;
        }

        const currentFileType = event.data.uploadResponse.file_type;

        if (currentFileType) {
          const currentFileValidations =
            sectionState.validationData.expectedFileValidations.get(
              currentFileType.name
            );

          if (
            currentFileValidations &&
            event.data.validationResult.color_space
          ) {
            currentFileValidations.get('color_space')!.isValid = event.data
              .validationResult.color_space.valid
              ? 'valid'
              : 'invalid';
            if (!event.data.validationResult.color_space.valid) {
              currentFileValidations.get('color_space')!.error_message =
                event.data.validationResult.color_space.message;
            }
          }

          if (
            currentFileValidations &&
            event.data.validationResult.dimensions
          ) {
            currentFileValidations.get('dimensions')!.isValid = event.data
              .validationResult.dimensions.valid
              ? 'valid'
              : 'invalid';
            if (!event.data.validationResult.dimensions.valid) {
              currentFileValidations.get('dimensions')!.error_message =
                event.data.validationResult.dimensions.message;
            }
          }

          if (
            currentFileValidations &&
            event.data.validationResult.resolution
          ) {
            currentFileValidations.get('resolution')!.isValid = event.data
              .validationResult.resolution.valid
              ? 'valid'
              : 'invalid';
            if (!event.data.validationResult.resolution.valid) {
              currentFileValidations.get('resolution')!.error_message =
                event.data.validationResult.resolution.message;
            }
          }
        }

        sectionState.uploadedFiles.push(event.data.uploadResponse);
        this.submitRequest(true);
      }
    });

    this.commentsModalService.modalEvents.subscribe((event) => {
      if (event.type === 'refresh_data') {
        this.fetchRequest();
      }
    });

    this.producerPickerModalService.modalEvents.subscribe((event) => {
      if (event.type === 'close') {
        this.fetchRequest();
      }
    });

    this.rejectRequestModalService.modalEvents.subscribe((event) => {
      if (event.type === 'close') {
        this.fetchRequest();
      }
    });
  }

  fetchRequest() {
    this.requestService
      .getRequestByPublicHash(this.requestPublicHash)
      .subscribe((data) => {
        this.requestData = data.data;

        // update comments modal data
        this.commentsModalService.modalEvents.next({
          type: 'data_updated',
          request_hash: this.requestPublicHash,
          comments: data.data.request_comments,
        });

        data.data.sections!.forEach((section) => {
          const validationData = this.buildSectionValidationInitData(section);

          this.sectionStates.set(section.id, {
            existingFiles: section.uploaded_files || [],
            uploadedFiles: [],
            validationData,
          });
        });
      });
  }

  buildSectionValidationInitData(section: RequestSection): {
    expectedFileValidations: Map<string, Map<string, RequestValidationData>>;
    maxFileCountValidation: RequestValidationData;
    maxFileSizeValidation: RequestValidationData;
    expectedFileTypesValidation: RequestValidationData;
  } {
    const maxFileCountValidation: RequestValidationData = {
      title: 'Max. Anzahl Dateien:',
      content: section.max_file_count.toString(),
      isValid: 'none',
    };
    const maxFileSizeValidation: RequestValidationData = {
      title: 'Maximale Dateigröße:',
      content:
        section.max_file_size > 2048
          ? `${section.max_file_size / 1024} MB`
          : `${section.max_file_size} KB`,
      isValid: 'none',
    };
    const expectedFileTypesValidation: RequestValidationData = {
      title: 'Erlaubte Dateiformate:',
      content: section.expected_files.map((f) => f.file_type.name).join(', '),
      isValid: 'none',
    };

    const expectedFileValidations = new Map<
      string,
      Map<string, RequestValidationData>
    >();

    section.expected_files.forEach((expectedFile) => {
      const fileValidations = new Map<string, RequestValidationData>();

      if (expectedFile.color_space) {
        fileValidations.set('color_space', {
          title: 'Farbraum:',
          content: expectedFile.color_space,
          isValid: 'none',
        });
      }

      if (
        expectedFile.dimensions_height &&
        expectedFile.dimensions_width &&
        expectedFile.dimensions_unit
      ) {
        fileValidations.set('dimensions', {
          title: 'Datenformat oder Abmessungen:',
          content: `${expectedFile.dimensions_width} x ${expectedFile.dimensions_height} ${expectedFile.dimensions_unit}`,
          isValid: 'none',
        });
      }

      if (expectedFile.resolution) {
        fileValidations.set('resolution', {
          title: 'Minimale Auflösung:',
          content: `${expectedFile.resolution} dpi`,
          isValid: 'none',
        });
      }

      expectedFileValidations.set(expectedFile.file_type.name, fileValidations);
    });

    return {
      maxFileCountValidation,
      maxFileSizeValidation,
      expectedFileTypesValidation,
      expectedFileValidations,
    };
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    const collapseHeight = 200;
    if (verticalOffset > collapseHeight && !this.collapseTopBar) {
      this.collapsedTimeStamp = Date.now();
      this.collapseTopBar = true;
    } else if (verticalOffset <= 0 && this.collapseTopBar) {
      if (
        this.collapsedTimeStamp &&
        Date.now() - this.collapsedTimeStamp < 1000
      ) {
        return;
      }

      this.collapseTopBar = false;
      this.collapsedTimeStamp = Date.now();
    }
  }

  ngOnInit(): void {}

  openFileUploadModal() {
    this.fileUploadModalService.modalEvents.next({ type: 'open' });
  }

  removeUploadedFile(data: {
    type: 'tmp' | 'existing';
    section_id: number;
    uploaded_file_id: number;
  }) {
    if (data.type === 'tmp') {
      this.sectionStates
        .get(data.section_id)
        ?.uploadedFiles.splice(data.uploaded_file_id, 1);
    } else {
      this.requestService
        .removeFile({
          request_hash: this.requestPublicHash,
          uploaded_file_id: data.uploaded_file_id,
          section_id: data.section_id,
        })
        .subscribe({
          next: (res) => {
            this.fetchRequest();
            this.toastr.info('File Removed');
          },
          error: ({ error }) => {
            this.toastr.error(error.message);
          },
        });
    }
  }

  get completedSections() {
    let completedSections = 0;

    this.requestData?.sections?.forEach((section) => {
      const sectionState = this.sectionStates.get(section.id);
      let hasValidFiles = true;
      if (sectionState) {
        if (
          sectionState.uploadedFiles.length === 0 &&
          sectionState.existingFiles.length === 0
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.maxFileCountValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.maxFileSizeValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.expectedFileTypesValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        sectionState.validationData.expectedFileValidations.forEach(
          (fileValidations, fileType) => {
            fileValidations.forEach((validationData, validationType) => {
              if (validationData.isValid === 'invalid') {
                hasValidFiles = false;
              }
            });
          }
        );
      } else {
        hasValidFiles = false;
      }

      if (hasValidFiles) {
        completedSections++;
      }
    });

    return completedSections;
  }

  hasValidFiles() {
    let hasValidFiles = true;

    this.requestData?.sections?.forEach((section) => {
      const sectionState = this.sectionStates.get(section.id);
      if (!sectionState) {
        hasValidFiles = false;
      } else {
        if (
          sectionState.uploadedFiles.length === 0 &&
          sectionState.existingFiles.length === 0
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.maxFileCountValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.maxFileSizeValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        if (
          sectionState.validationData.expectedFileTypesValidation.isValid ===
          'invalid'
        ) {
          hasValidFiles = false;
        }

        sectionState.validationData.expectedFileValidations.forEach(
          (fileValidations, fileType) => {
            fileValidations.forEach((validationData, validationType) => {
              if (validationData.isValid === 'invalid') {
                hasValidFiles = false;
              }
            });
          }
        );
      }
    });

    return hasValidFiles;
  }

  get isPastDueDate() {
    if (!this.requestData?.due_date) {
      return false;
    }
    const today = dayjs().endOf('date');
    const dueDate = dayjs(this.requestData?.due_date);

    return dueDate.diff(today, 'date') < 0;
  }

  submitRequest(isCacheOnly = false) {
    let uploadData: any = {
      request_hash: this.requestPublicHash,
      files: [],
    };

    // check if all files are valid
    if (!this.hasValidFiles()) {
      return;
    }

    for (let section of this.requestData!.sections!) {
      const sectionState = this.sectionStates.get(section.id);

      if (!sectionState) {
        return;
      }

      uploadData.files = uploadData.files.concat(
        sectionState.uploadedFiles.map((f) => ({
          section_id: section.id,
          file_path: f.path,
        }))
      );
    }

    this.isSubmitting = true;
    if (!isCacheOnly) {
      this.requestService.submitRequestFiles(uploadData).subscribe({
        next: (data) => {
          this.toastr.success('Request Submitted');
          this.fetchRequest();
          this.isSubmitting = false;
        },
        error: ({ error }) => {
          this.toastr.error(error.message);
          this.isSubmitting = false;
        },
      });
    } else {
      this.requestService.cacheRequestFiles(uploadData).subscribe({
        error: ({ error }) => {
          this.toastr.error(error.message);
          this.isSubmitting = false;
        },
        next: () => {
          this.fetchRequest();
          this.isSubmitting = false;
        },
      });
    }
  }

  /**
   * Only available to logged in users
   * this will change the status id to 1
   */
  rejectRequest() {
    this.rejectRequestModalService.modalEvents.next({
      type: 'open',
      id: this.requestData!.id,
    });
  }

  /**
   * only available to logged in users
   */
  openProducerPickerModal() {
    this.producerPickerModalService.modalEvents.next({
      type: 'open',
      id: this.requestData!.id!,
    });
  }

  directlyApproveRequestAsCustomer() {
    this.isSubmitting = true;
    this.requestService
      .updateRequestStatus(this.requestData!.id, {
        status_id: 4,
      })
      .subscribe({
        next: (res) => {
          this.toastrService.success(res.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
        error: (res) => {
          this.toastrService.error(res.error.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
      });
  }

  // this will change the status id to 2
  rejectRequestAsProducer() {
    this.rejectRequestModalService.modalEvents.next({
      type: 'open',
      producer_hash: this.producerHash || '',
      id: this.requestData!.id,
    });
  }

  // this will change the status id to 2
  rejectRequestAsClient() {
    this.rejectRequestModalService.modalEvents.next({
      type: 'open',
      id: this.requestData!.id,
      new_status_id: 2,
    });
  }

  approveRequestAsProducer() {
    this.isSubmitting = true;
    this.requestService
      .updateRequestStatus(this.requestData!.id, {
        status_id: 4,
        producer_hash: this.producerHash!,
      })
      .subscribe({
        next: (res) => {
          this.toastrService.success(res.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
        error: (res) => {
          this.toastrService.error(res.error.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
      });
  }

  approveRequestAsClient() {
    this.isSubmitting = true;
    this.requestService
      .updateRequestStatus(this.requestData!.id, {
        status_id: 5,
      })
      .subscribe({
        next: (res) => {
          this.toastrService.success(res.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
        error: (res) => {
          this.toastrService.error(res.error.message);
          this.isSubmitting = false;
          this.fetchRequest();
        },
      });
  }

  openCommentsModal() {
    this.commentsModalService.modalEvents.next({
      type: 'open',
    });
  }

  get errorCount(): number {
    let errorCount = 0;
    this.sectionStates.forEach((section) => {
      if (section.validationData.maxFileCountValidation.isValid === 'invalid') {
        errorCount++;
      }
      if (section.validationData.maxFileSizeValidation.isValid === 'invalid') {
        errorCount++;
      }
      if (
        section.validationData.expectedFileTypesValidation.isValid === 'invalid'
      ) {
        errorCount++;
      }

      section.validationData.expectedFileValidations.forEach((f) => {
        f.forEach((validationData) => {
          if (validationData.isValid === 'invalid') {
            errorCount++;
          }
        });
      });
    });

    return errorCount;
  }

  addFakeDownloadDelay() {
    this.waitingDownload = true;

    setTimeout(() => {
      this.waitingDownload = false;
    }, 3000);
  }
}
