<div
  class="bg-primary p-4 rounded-xl text-white grid grid-cols-2 gap-8 fixed bottom-16 left-1/2 -translate-x-1/2 z-30 w-[1000px]"
  *ngIf="requestViewPageService.showFloatingNavBar"
>
  <div class="flex justify-between items-center gap-2">
    <div class="flex items-center font-medium text-17px leading-27.2px">
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="-rotate-90"
      >
        <circle opacity="0.3" cx="24" cy="24" r="24" fill="#F2F5F7" />
        <circle cx="24" cy="24" r="12" fill="red" fill-opacity="0" stroke="#FF8811" stroke-width="24" stroke-dasharray="80" [attr.stroke-dashoffset]="navigatorDashOffset"  />
      </svg>

      <div class="ml-2">
        {{
          (requestViewPageService.selectedSectionMetaData?.globalSectionIndex ||
            0) + 1
        }}
        /{{ requestViewPageService.globalSectionList.length }}
      </div>
    </div>
    <div class="flex gap-3 font-medium text-xs leading-19.2px">
      <button
        class="flex gap-2 items-center disabled:opacity-60"
        [disabled]="
          requestViewPageService.selectedSectionMetaData?.globalSectionIndex ===
          0
        "
        (click)="toPreviousSection()"
      >
        <span transloco="Vorherige">Vorherige</span>
        <img src="assets/imgs/icons/rounded-arrow-left.svg" alt="" />
      </button>
      <button
        class="flex gap-2 items-center disabled:opacity-60"
        [disabled]="
          requestViewPageService.selectedSectionMetaData?.globalSectionIndex ===
          requestViewPageService.globalSectionList.length - 1
        "
        (click)="toNextSection()"
      >
        <img src="assets/imgs/icons/rounded-arrow-right.svg" alt="" />
        <span transloco="Nächste">Nächste</span>
      </button>
    </div>
  </div>
  <button
    class="tw-btn-secondary flex justify-center w-full gap-2 disabled:opacity-60"
    [disabled]="!requestViewPageService.canSubmit"
    (click)="doSubmit()"
  >
    <span transloco="An Auftraggeber senden">An Auftraggeber senden</span>
    <img src="assets/imgs/icons/send.svg" alt="" />
  </button>
</div>
