import {Component, Input, OnInit} from '@angular/core';
import {RequestValidationData} from "../../request-view-page/request-section/request-section.component";

@Component({
  selector: 'app-file-validation-status',
  templateUrl: './file-validation-status.component.html',
  styleUrls: ['./file-validation-status.component.scss']
})
export class FileValidationStatusComponent implements OnInit {
  @Input() validationData?: RequestValidationData;

  constructor() { }

  ngOnInit(): void {
  }

}
