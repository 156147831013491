import { Component, OnInit } from '@angular/core';
import {initFlowbite} from "flowbite";

@Component({
  selector: 'app-request-management-layout',
  templateUrl: './request-management-layout.component.html',
  styleUrls: ['./request-management-layout.component.scss']
})
export class RequestManagementLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    initFlowbite();
  }

}
