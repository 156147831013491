import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../backend-services/user.service';
import { Role, RoleService } from '../../backend-services/role.service';
import { ToastrService } from 'ngx-toastr';

import { Location } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-user',
  templateUrl: './manage-single-user.component.html',
  styleUrls: [
    '../../../assets/css/bootstrap.min.css',
    './manage-single-user.component.scss',
  ],
})
export class ManageSingleUserComponent {
  availableRoles: Role[] = [];
  allCustomers: Customer[] = [];
  // filter roles according to customer
  filteredRoles: Role[] = [];

  // track if there are unsaved data
  initialData: any = undefined;

  @ViewChild('passwordElement') passwordElement: ElementRef | undefined;
  @ViewChild('customerIdElement') customerIdElement: ElementRef | undefined;
  @ViewChild('confirmBackModalTrigger') confirmBackModalTrigger:
    | ElementRef
    | undefined;

  userForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    enabled: new FormControl(true),
    set_password: new FormControl(true),
    role_id: new FormControl(3),
    customer_id: new FormControl('', [this.customerIdValidator()]),
  });

  passwordControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
  ]);

  isSuperAdmin = false;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private customerService: CustomerService,
    private authService: AuthService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    // set initial data as form value
    this.initialData = this.userForm.value;

    this.route.queryParamMap.subscribe((params) => {
      const customerIdParam = params.get('customer_id');
      if (customerIdParam) {
        this.customerIdControl.setValue(parseInt(customerIdParam));
        this.initialData = this.userForm.value;
      }
    });

    this.route.paramMap.subscribe({
      next: (val) => {
        const id = val.get('id');
        if (id) {
          this.id = parseInt(id);
          this.fetchUserData();
        }
      },
    });

    this.authService.isSuperAdmin.subscribe({
      next: (isSuperAdmin) => {
        this.isSuperAdmin = isSuperAdmin;
        this.filterRoles();

        // get customer list if logged in user is SuperAdmin
        if (isSuperAdmin) {
          this.customerService.getCustomers().subscribe({
            next: (res) => {
              if (res.success) {
                this.allCustomers = res.data;
              }
            },
          });
        }
      },
    });

    // fetch available roles
    this.roleService.getRoles().subscribe({
      next: (res) => {
        if (res.success) {
          this.availableRoles = res.data;
          this.filterRoles();
        }
      },
      error: (res) => {
        console.log(res.error);
      },
    });

    // filter roles on customer id change
    this.customerIdControl.valueChanges.subscribe({
      next: () => {
        this.filterRoles();
      },
    });

    // validate customerIdControl on role change
    this.roleIdControl.valueChanges.subscribe({
      next: () => {
        this.customerIdControl.updateValueAndValidity();
      },
    });

    this.passwordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });
  }

  get firstNameControl() {
    return this.userForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.userForm.get('last_name') as AbstractControl;
  }

  get emailControl() {
    return this.userForm.get('email') as AbstractControl;
  }

  get enabledControl() {
    return this.userForm.get('enabled') as AbstractControl;
  }

  get setPasswordControl() {
    return this.userForm.get('set_password') as AbstractControl;
  }

  get roleIdControl() {
    return this.userForm.get('role_id') as AbstractControl;
  }

  get customerIdControl() {
    return this.userForm.get('customer_id') as AbstractControl;
  }

  get selectedRole(): Role | null {
    const roleIdControl = this.userForm?.get('role_id');
    if (roleIdControl && roleIdControl.value) {
      return (
        this.availableRoles.find((r) => r.id == this.roleIdControl.value) ??
        null
      );
    }

    return null;
  }

  userFormLoading = false;

  id: number | null = null;

  fetchUserData() {
    if (this.id) {
      this.userFormLoading = true;
      this.userForm.disable();

      this.userService.getUserById(this.id).subscribe({
        next: (res) => {
          if (res) {
            this.userFormLoading = false;

            // set form values
            this.firstNameControl.setValue(res.first_name);
            this.lastNameControl.setValue(res.last_name);
            this.emailControl.setValue(res.email);
            this.enabledControl.setValue(res.enabled);
            this.roleIdControl.setValue(res.role_id);
            this.customerIdControl.setValue(res.customer_id);
            this.setPasswordControl.setValue(false);

            this.userForm.enable();

            this.initialData = this.userForm.value;
          }
        },
        error: (err) => {
          console.log(err);
          this.toastr.error(this.translocoService.translate(`User not found`));
        },
      });
    }
  }

  /**
   *  if logged in user is a SuperAdmin &  selected role is not SuperAdmin customer id is required
   */
  customerIdValidator(): ValidatorFn {
    return (control) => {
      if (this.isSuperAdmin) {
        const selectedRole = this.selectedRole;
        if (
          !control.value &&
          selectedRole &&
          (selectedRole.customer_id ||
            (!selectedRole?.customer_id && selectedRole.name !== 'SuperAdmin'))
        ) {
          this.customerIdElement?.nativeElement?.setCustomValidity('invalid');
          return { required: true };
        }
      }
      this.customerIdElement?.nativeElement?.setCustomValidity('');
      return null;
    };
  }

  onPasswordChange() {
    if (this.passwordControl.valid) {
      this.passwordElement?.nativeElement.setCustomValidity('');
    } else {
      this.passwordElement?.nativeElement.setCustomValidity('invalid');
    }
  }

  /**
   * Filter roles based on customer id & logged in user type
   */
  filterRoles() {
    // show roles that are available to customer only  if customer_id is selected
    if (!this.isSuperAdmin) {
      // show all available roles Except SuperAdmin when user is not super admin
      this.filteredRoles = this.availableRoles.filter(
        (r) =>
          (!r.customer_id && r.name !== 'SuperAdmin') ||
          r.customer_id == this.customerIdControl.value
      );
    } else if (!!this.customerIdControl.value) {
      /*
       this is part for SuperAdmin users only
       if customer id is selected show roles available for that customer
       */
      this.filteredRoles = this.availableRoles.filter(
        (r) =>
          (!r.customer_id && r.name !== 'SuperAdmin') ||
          r.customer_id == this.customerIdControl.value
      );
    } else {
      /*
       this is part for SuperAdmin users only
       if customer id is not selected, show all roles
      */
      this.filteredRoles = this.availableRoles;
    }
  }

  handleSubmit() {
    this.userForm.markAllAsTouched();
    this.customerIdControl.updateValueAndValidity();

    if (
      this.userForm.valid &&
      (!this.setPasswordControl.value ||
        (this.setPasswordControl.value && this.passwordControl.valid))
    ) {
      this.userFormLoading = true;

      let data: any = { ...this.userForm.value };

      if (this.setPasswordControl.value) {
        data.password = this.passwordControl.value;
      }

      // set customer_id as undefined if it is empty
      if (!data.customer_id) {
        delete data.customer_id;
      }

      if (!this.id) {
        this.userService.createUser(data).subscribe({
          next: (res) => {
            this.userFormLoading = false;

            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(
                  `Neuer Benutzer wurde erfolgreich erstellt!`
                )
              );
              this.router.navigate([`/user-management/`], {
                replaceUrl: true,
                queryParams: {
                  management_type: 'users',
                },
              });
            }
          },
          error: (res) => {
            if (!!res.error?.message?.email) {
              this.toastr.error(
                this.translocoService.translate(
                  `Diese E-Mail existiert bereits`
                )
              );
            } else {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.error ||
                  this.translocoService.translate(`Something went wrong`)
              );
            }
            this.userFormLoading = false;
          },
        });
      } else {
        this.userService.updateUser(this.id, data).subscribe({
          next: (res) => {
            this.userFormLoading = false;
            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(`Ihr Profil wurde gespeichert`)
              );
              this.userForm.markAsUntouched();
              this.goBack();
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error ||
                this.translocoService.translate(`Something went wrong`)
            );
            this.userFormLoading = false;
          },
        });
      }
    }
  }

  goBackCheck() {
    if (
      JSON.stringify(this.initialData) !== JSON.stringify(this.userForm.value)
    ) {
      this.confirmBackModalTrigger?.nativeElement?.click();
    } else {
      this.goBack();
    }
  }

  goBack() {
    this._location.back();
  }
}
