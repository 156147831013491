import { Component, ElementRef, ViewChild } from '@angular/core';
import { UploadProofService } from './upload-proof.service';

@Component({
  selector: 'app-upload-proof-modal',
  templateUrl: './upload-proof-modal.component.html',
  styleUrls: ['./upload-proof-modal.component.scss'],
})
export class UploadProofModalComponent {
  @ViewChild('toggleUploadProofModalBtn')
  toggleUploadProofModalBtn?: ElementRef;
  @ViewChild('showUploadProofModalBtn')
  showUploadProofModalBtn?: ElementRef;
  @ViewChild('hideUploadProofModalBtn')
  hideUploadProofModalBtn?: ElementRef;

  constructor(public uploadProofService: UploadProofService) {
    this.uploadProofService.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          this.showUploadProofModalBtn?.nativeElement!.click();
          break;
        case 'close':
          this.hideUploadProofModalBtn?.nativeElement!.click();
          break;
      }
    });
  }

  closeModal() {
    this.uploadProofService.modalEvents.next({ type: 'close' });
  }

  onFileChange(event: any) {
    const newFile = event.target.files[0];

    this.uploadProofService.modalEvents.next({
      type: 'upload-file',
      file: newFile,
    });
  }
}
