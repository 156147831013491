import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RequestViewPageService } from '../request-view-page.service';

@Component({
  selector: 'app-selected-section-info-files',
  templateUrl: './selected-section-info-files.component.html',
  styleUrls: ['./selected-section-info-files.component.scss'],
})
export class SelectedSectionInfoFilesComponent {
  constructor(
    public sanitizer: DomSanitizer,
    public requestViewPageService: RequestViewPageService
  ) {}
}
