import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

export type CustomerSettings = {
  enable_producers: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerSettingsService {
  public currentSettings = new BehaviorSubject<CustomerSettings>({
    enable_producers: false,
  });

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.isAuthenticated.subscribe((authenticated) => {
      if (authenticated) {
        this.loadSettings();
        this.updateSettings();
      } else {
        this.deleteLocalSettings();
      }
    });
  }

  updateSettings() {
    this.fetchSettings().subscribe((res) => {
      const settings: any = res.data;
      delete settings['id'];
      delete settings['created_at'];
      delete settings['updated_at'];

      this.saveLocalSettings(settings);
      this.loadSettings();
    });
  }

  loadSettings() {
    const settings = localStorage.getItem('customer_settings');
    if (settings) {
      this.currentSettings.next(JSON.parse(settings));
    }
  }

  saveLocalSettings(settings: CustomerSettings) {
    localStorage.setItem('customer_settings', JSON.stringify(settings));
  }

  deleteLocalSettings() {
    localStorage.removeItem('customer_settings');
  }

  fetchSettings() {
    return this.http.get<{ success: boolean; data: CustomerSettings }>(
      '/api/customer_settings/current'
    );
  }

  submitSettings(data: CustomerSettings) {
    return this.http
      .post<{ success: boolean; data: CustomerSettings }>(
        '/api/customer_settings/current',
        data
      )
      .pipe(tap(() => this.updateSettings()));
  }
}
