import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileUploadModalV4Service } from '../file-upload-modal-v4.service';

@Component({
  selector: 'app-upload-cancel-confirm-modal',
  templateUrl: './upload-cancel-confirm-modal.component.html',
  styleUrls: ['./upload-cancel-confirm-modal.component.scss'],
})
export class UploadCancelConfirmModalComponent {
  @ViewChild('toggleFileUploadCancelConfirmModalBtn')
  toggleFileUploadModalBtn?: ElementRef;
  @ViewChild('showFileUploadCancelConfirmModalBtn')
  showFileUploadModalBtn?: ElementRef;
  @ViewChild('hideFileUploadCancelConfirmModalBtn')
  hideFileUploadModalBtn?: ElementRef;

  constructor(private fileUploadModalV4Service: FileUploadModalV4Service) {
    this.fileUploadModalV4Service.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'init-upload-cancel':
          this.showFileUploadModalBtn?.nativeElement?.click();
          break;
        case 'close':
        case 'upload-cancel':
          this.hideFileUploadModalBtn?.nativeElement?.click();
          break;
      }
    });
  }

  closeModal() {
    this.hideFileUploadModalBtn?.nativeElement?.click();
  }

  cancelUpload() {
    this.fileUploadModalV4Service.modalEvents.next({ type: 'upload-cancel' });
  }
}
