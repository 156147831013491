<button
  data-modal-target="request-file-upload-cancel-confirm-modal"
  data-modal-toggle="request-file-upload-cancel-confirm-modal"
  class="hidden"
  #toggleFileUploadCancelConfirmModalBtn
></button>
<button
  data-modal-target="request-file-upload-cancel-confirm-modal"
  data-modal-show="request-file-upload-cancel-confirm-modal"
  class="hidden"
  #showFileUploadCancelConfirmModalBtn
></button>
<button
  data-modal-target="request-file-upload-cancel-confirm-modal"
  data-modal-hide="request-file-upload-cancel-confirm-modal"
  class="hidden"
  #hideFileUploadCancelConfirmModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full font-Montserrat"
  id="request-file-upload-cancel-confirm-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div
    class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-2/4 max-w-[600px] max-h-full"
  >
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow-2xl px-7">
      <!-- Modal header -->
      <div class="flex flex-col py-8">
        <h1
          class="font-bold text-xl leading-6 text-primary mb-6"
          transloco="Upload & Datencheck abbrechen?"
        >
          Upload & Datencheck abbrechen?
        </h1>
        <div
          class="text-base font-normal leading-25.6px text-primary mb-7"
          transloco="Wenn Sie den Vorgang jetzt abbrechen werden Ihre Daten nicht hochgeladen und auch nicht überprüft."
        >
          Wenn Sie den Vorgang jetzt abbrechen werden Ihre Daten nicht
          hochgeladen und auch nicht überprüft.
        </div>
        <div class="flex gap-3">
          <button
            class="flex-grow tw-btn-secondary-outline"
            (click)="closeModal()"
            transloco="Upload fortsetzen"
          >
            Upload fortsetzen
          </button>
          <button
            class="flex-grow tw-btn-secondary"
            (click)="cancelUpload()"
            transloco="Upload abbrechen"
          >
            Upload abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
