import { Component, Input, OnInit } from '@angular/core';
import {
  AppRequest,
  RequestUploadedFile,
  SectionGroup,
} from '../../backend-services/request.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-request-list-file-preview',
  templateUrl: './request-list-file-preview.component.html',
  styleUrls: ['./request-list-file-preview.component.scss'],
})
export class RequestListFilePreviewComponent implements OnInit {
  @Input() request!: AppRequest;

  files: RequestUploadedFile[] = [];
  maxPreviewCount = 2;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.request.section_groups?.forEach((group: SectionGroup) => {
      group.sections?.forEach((section) => {
        this.files.push(...section.uploaded_files.filter((f) => f.is_active));
      });
    });

    if (window.innerWidth >= 1280) {
      this.maxPreviewCount = 2;
    }else {
      this.maxPreviewCount = 1;
    }

  }

  get selectedImages() {
    if (this.files.length <= this.maxPreviewCount) {
      return this.files;
    }

    return this.files.slice(0, this.maxPreviewCount);
  }

  get remainingCount() {
    return this.files.length - this.maxPreviewCount;
  }
}
