<div
  class="container d-flex flex-column justify-content-between"
  style="min-height: 90vh"
>
  <form class="grid grid-cols-1 lg:grid-cols-2 gap-8 pb-5" novalidate [formGroup]="customerForm">
    <div class="">
      <div class="card box-shadow">
        <h3 class="p-2 text-uppercase border-bottom mb-0" transloco="KUNDENDATEN">KUNDENDATEN</h3>
        <div class="d-flex justify-content-center w-full">
          <span class="user-ava d-block pt-4 pb-4">
            <img src="/assets/imgs/dummy/company4.png" alt="" />
          </span>
        </div>
        <!-- form -->
        <div class="block__personal-data p-2 pb-5 ps-2 pe-2">
          <div
            class="form-group mb-1 d-flex flex-column"
            [ngClass]="{
              'was-validated': labelControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="kunde"
              id="kunde"
              formControlName="label"
              required
            />
            <label class="order-0" for="kunde"><span transloco="Kunde">Kunde</span></label>
            <div class="order-2 invalid-feedback" transloco="Ungültiges Kunde">Ungültiges Kunde</div>
          </div>
          <!-- end form group -->
          <div
            class="form-group mb-1 d-flex flex-column"
            [ngClass]="{
              'was-validated': companyControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="firme"
              id="firme"
              formControlName="company"
              required
            />
            <label class="order-0" for="firme"
              ><span transloco="Firmenbezeichnung">Firmenbezeichnung</span></label
            >
            <div class="order-2 invalid-feedback" transloco="Ungültiges Firmenbezeichnung">
              Ungültiges Firmenbezeichnung
            </div>
          </div>
          <!-- end form group -->
          <div
            class="form-group d-flex flex-column"
            [ngClass]="{
              'was-validated': vatControl.touched
            }"
          >
            <input
              class="form-control order-1"
              name="uid"
              id="uid"
              placeholder="ATxxxxxxxxxxxx"
              formControlName="vat_nr"
              required
            />
            <label class="order-0" for="uid"><span transloco="UID">UID</span></label>
            <div class="order-2 invalid-feedback" transloco="Ungültiges UID">Ungültiges UID</div>
          </div>
        </div>
        <!-- form -->
        <h3 class="p-2 text-uppercase border-bottom mb-0" transloco="LIZENZ">LIZENZ</h3>
        <div class="pt-1 p-2 ps-2 pe-2 pb-2">
          <div class="form-group form-group-328 d-flex flex-column mb-2">
            <select
              class="form-select order-1"
              aria-label="select licens"
              name="licens"
              id="licens"
              disabled
            >
              <option value="1" transloco="Premium">Premium</option>
            </select>
            <label class="order-0" for="licens"><span transloco="Lizenz">Lizenz</span></label>
          </div>
          <div class="text-form color-text font-size-14">
            <span transloco="Laufzeit">Laufzeit:</span> 23.4.2023 - 23.4.2024
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <!-- start card -->
      <div class="card box-shadow mb-2">
        <h3 class="p-2 border-bottom mb-0">
          <span class="text-uppercase" transloco="ANSPRECHPARTNER">ANSPRECHPARTNER</span>
        </h3>
        <div class="p-2">
          <div
            class="form-group mb-1 d-flex flex-column form-group-328"
            [ngClass]="{
              'was-validated': firstNameControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="vorname"
              id="vorname"
              formControlName="first_name"
              required
            />
            <label class="order-0" for="vorname"><span transloco="Vorname">Vorname</span></label>
            <div class="order-2 invalid-feedback" transloco="Ungültiger Vorname">Ungültiger Vorname</div>
          </div>
          <!-- end form group -->
          <div
            class="form-group mb-1 d-flex flex-column form-group-328"
            [ngClass]="{
              'was-validated': lastNameControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="text"
              name="nachname"
              id="nachname"
              formControlName="last_name"
              required
            />
            <label class="order-0" for="nachname"><span transloco="Nachname">Nachname</span></label>
            <div class="order-2 invalid-feedback" transloco="Ungültiger Nachname">Ungültiger Nachname</div>
          </div>
          <!-- end form group -->
          <div
            class="form-group mb-1 d-flex flex-column form-group-328"
            [ngClass]="{
              'was-validated': emailControl.touched
            }"
          >
            <input
              class="form-control order-1"
              type="email"
              name="email"
              id="email"
              formControlName="email"
              required
            />
            <label class="order-0" for="email"><span transloco="E-Mail">E-Mail</span></label>
            <div class="order-2 invalid-feedback" transloco="Ungültige E-Mail">Ungültige E-Mail</div>
          </div>
          <!-- end form group -->
        </div>
      </div>
      <!-- end card -->
      <!-- start card -->
      <div class="card box-shadow mb-2">
        <h3 class="p-2 border-bottom mb-0">
          <span class="text-uppercase" transloco="RECHNUNGSDATEN">RECHNUNGSDATEN</span>
        </h3>
        <div class="p-2">
          <div class="w-full flex flex-wrap">
            <div class="col-sm-8 pe-sm-0">
              <div
                class="form-group d-flex flex-column"
                [ngClass]="{
                  'was-validated': billingStreetControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="text"
                  name="strasse"
                  id="strasse"
                  formControlName="billing_street"
                  required
                />
                <label class="order-0" for="strasse"><span transloco="Straße">Straße</span></label>
                <div class="order-2 invalid-feedback" transloco="Ungültige Straße">Ungültige Straße</div>
              </div>
            </div>
            <!-- end form group -->
            <div class="col-sm-4 ps-sm-1">
              <div
                class="form-group d-flex flex-column"
                [ngClass]="{
                  'was-validated': billingHouseNumberControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="text"
                  name="mustermann"
                  id="billing_house_number"
                  formControlName="billing_house_number"
                  required
                />
                <label class="order-0" for="vorname"><span transloco="Nr">Nr</span></label>
                <div class="order-2 invalid-feedback" transloco="Ungültige Nr">Ungültige Nr</div>
              </div>
            </div>
            <!-- end form group -->
            <div class="col-sm-4 pe-sm-0">
              <div
                class="form-group d-flex flex-column"
                [ngClass]="{
                  'was-validated': billingZipControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="text"
                  name="plz"
                  id="plz"
                  formControlName="billing_zip"
                  required
                />
                <label class="order-0" for="plz"><span transloco="PLZ">PLZ</span></label>
                <div class="order-2 invalid-feedback" transloco="Ungültige PLZ">Ungültige PLZ</div>
              </div>
            </div>
            <!-- end form group -->
            <div class="col-sm-8 ps-sm-1">
              <div
                class="form-group d-flex flex-column"
                [ngClass]="{
                  'was-validated': billingCityControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="text"
                  name="ort"
                  id="ort"
                  formControlName="billing_city"
                  required
                />
                <label class="order-0" for="ort"><span transloco="Ort">Ort</span></label>
                <div class="order-2 invalid-feedback" transloco="Ungülteger Ort">Ungülteger Ort</div>
              </div>
            </div>
            <!-- end form group -->
            <div class="col-sm-8 pe-sm-0">
              <div
                class="form-group d-flex flex-column"
                [ngClass]="{
                  'was-validated': billingEmailControl.touched
                }"
              >
                <input
                  class="form-control order-1"
                  type="email"
                  name="email2"
                  id="email2"
                  formControlName="billing_email"
                  required
                />
                <label class="order-0" for="email2"><span transloco="E-Mail">E-Mail</span></label>
                <div class="order-2 invalid-feedback" transloco="Ungültige E-Mail">Ungültige E-Mail</div>
              </div>
            </div>
            <!-- end form group -->
          </div>
        </div>
        <div class="p-2 d-flex justify-content-between align-items-center">
          <label
            class="form-check-label mb-0 text-normal font-size-14 color-text"
            for="switchCh0"
            transloco="Rechnung per E-Mail"
            >Rechnung per E-Mail</label
          >
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-check form-switch ms-1">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="switchCh0"
                formControlName="invoice_by_mail"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
  </form>
  <div class="action-btn-container">
    <div class="container">
      <div class="d-flex justify-content-center justify-content-lg-end flex-wrap pt-lg-4 pt-3">
        <button
          data-bs-toggle="modal"
          data-bs-target="#confirmBackModal"
          class="d-none"
          #confirmBackModalTrigger
        ></button>
        <button
          class="btn btn-white btn-circle mb-1"
          (click)="goBackCheck()"
          [disabled]="customerFormLoading"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M18.3334 10.0837H7.17758L12.3017 4.95949L11.0001 3.66699L3.66675 11.0003L11.0001 18.3337L12.2926 17.0412L7.17758 11.917H18.3334V10.0837Z"
              fill="#fda855"
            />
          </svg>
        </button>
        <button
          (click)="handleSubmit()"
          [disabled]="customerFormLoading"
          class="btn btn-indigo btn-indigo-2 ms-2"
          transloco="Daten speichern"
        >
          Daten speichern
        </button>
      </div>
    </div>
  </div>

  <!--  Modal-->
  <div
    class="modal fade"
    id="confirmBackModal"
    tabindex="-1"
    aria-labelledby="passwordChangeModal"
    aria-hidden="true"
    #confirmBackModalBackdrop
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card box-shadow p-2 border-0 modal-content-inner">
          <!-- end top block -->
          <p class="fs-5 fw-bold my-2 c-line-height-1" transloco="Ihre Daten wurden noch nicht gespeichert - Möchten Sie fortfahren? ">
            Ihre Daten wurden noch nicht gespeichert - Möchten Sie fortfahren?
          </p>
          <div class="d-flex justify-content-between w-100 gap-4 mt-4">
            <button
              class="btn btn-indigo-outline w-100"
              data-bs-dismiss="modal"
              transloco="Nein"
            >
              Nein
            </button>
            <button
              class="btn btn-indigo w-100"
              (click)="goBack()"
              data-bs-dismiss="modal"
              transloco="Ja"
            >
              Ja
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
