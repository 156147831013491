<div class="bg-neutral_gray-100 min-h-screen">
  <app-public-navbar></app-public-navbar>
  <div class="mt-4 mb-16">
    <!-- Header -->
    <div
      class="sticky top-0 z-40 mb-20 duration-200"
      [ngClass]="{ 'collapsed-topbar': collapseTopBar }"
    >
      <div class="mx-auto container pt-5 pb-10">
        <div
          class="w-full grid grid-cols-1 lg:grid-cols-2 items-start mb-5 gap-y-5"
        >
          <div class="w-full flex">
            <h1
              class="font-black text-4xl text-black"
              (click)="openFileUploadModal()"
            >
              {{ requestData?.title }}
            </h1>
          </div>
          <div class="w-full flex lg:justify-end">
            <span
              class="badge inline-flex items-center text-gray-700 bg-white text-xs text-center font-medium px-2.5 py-0.5 rounded-full dark:bg-white dark:text-gray-600"
            >
              <span class="w-2 h-2 me-1 bg-gray-500 rounded-full"></span>
              Neu
            </span>
            <button
              class="flex ml-12 cursor-pointer"
              (click)="openCommentsModal()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="message-notification-square">
                  <path
                    id="Vector"
                    d="M11 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H15.2C16.8802 18 17.7202 18 18.362 17.673C18.9265 17.3854 19.3854 16.9265 19.673 16.362C20 15.7202 20 14.8802 20 13.2V13M20.1213 3.87868C21.2929 5.05025 21.2929 6.94975 20.1213 8.12132C18.9497 9.29289 17.0503 9.29289 15.8787 8.12132C14.7071 6.94975 14.7071 5.05025 15.8787 3.87868C17.0503 2.70711 18.9497 2.70711 20.1213 3.87868Z"
                    stroke="#FF8811"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
              <div
                class="ml-2 flex justify-center items-center gap-2 rounded-full text-primaryBtn font-bold"
              >
                Kommentare anzeigen
              </div>
            </button>
            <a
              [href]="
                '/api/requests/download_files/' + requestData?.public_hash
              "
              download
              target="_blank"
              class="flex ml-12 duration-300"
              [ngClass]="{
                'text-gray-400 pointer-events-none': waitingDownload,
                'text-primaryBtn': !waitingDownload
              }"
              (click)="addFakeDownloadDelay()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                [ngClass]="{ hidden: waitingDownload }"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM9 14L12 17M12 17L15 14M12 17V11"
                  stroke="#FF8811"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                class="ml-2 flex justify-center items-center gap-2 rounded-full font-bold w-full"
              >
                Dateien gesammelt exportieren
              </div>
            </a>
          </div>
        </div>

        <h5
          class="font-light text-base text-black mb-5 collapsable-item"
          [ngClass]="{ collapsed: collapseTopBar }"
        >
          {{ requestData?.description }}
        </h5>

        <!--  Cards  -->
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-8 collapsable-item"
          [ngClass]="{ collapsed: collapseTopBar }"
        >
          <div
            class="p-5 rounded-lg drop-shadow-md dark:bg-gray-800 dark:border-gray-700"
            [ngClass]="{
              'bg-semantic-success-50': !isPastDueDate,
              'bg-semantic-danger-50': isPastDueDate
            }"
          >
            <h5
              class="text-xs 2xl:text-sm font-bold tracking-tight text-black dark:text-white"
            >
              Abgabe bis:
            </h5>
            <div class="mt-2 flex">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2113_9721)">
                  <path
                    d="M16.5 9.75C16.5 9.55109 16.579 9.36032 16.7197 9.21967C16.8603 9.07902 17.0511 9 17.25 9H18.75C18.9489 9 19.1397 9.07902 19.2803 9.21967C19.421 9.36032 19.5 9.55109 19.5 9.75V11.25C19.5 11.4489 19.421 11.6397 19.2803 11.7803C19.1397 11.921 18.9489 12 18.75 12H17.25C17.0511 12 16.8603 11.921 16.7197 11.7803C16.579 11.6397 16.5 11.4489 16.5 11.25V9.75Z"
                    fill="#05070B"
                  />
                  <path
                    d="M5.25 0C5.44891 0 5.63968 0.0790176 5.78033 0.21967C5.92098 0.360322 6 0.551088 6 0.75V1.5H18V0.75C18 0.551088 18.079 0.360322 18.2197 0.21967C18.3603 0.0790176 18.5511 0 18.75 0C18.9489 0 19.1397 0.0790176 19.2803 0.21967C19.421 0.360322 19.5 0.551088 19.5 0.75V1.5H21C21.7956 1.5 22.5587 1.81607 23.1213 2.37868C23.6839 2.94129 24 3.70435 24 4.5V21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.316071 22.5587 0 21.7956 0 21V4.5C0 3.70435 0.316071 2.94129 0.87868 2.37868C1.44129 1.81607 2.20435 1.5 3 1.5H4.5V0.75C4.5 0.551088 4.57902 0.360322 4.71967 0.21967C4.86032 0.0790176 5.05109 0 5.25 0ZM1.5 6V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0607 22.0607C22.342 21.7794 22.5 21.3978 22.5 21V6H1.5Z"
                    fill="#05070B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2113_9721">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <h6
                class="ml-3 text-base font-semibold tracking-tight text-black dark:text-white"
                *ngIf="requestData?.due_date"
              >
                {{ requestData?.due_date | date: "dd. MMMM yyyy" }}
              </h6>
              <h6
                class="ml-3 text-base font-semibold tracking-tight text-black dark:text-white"
                *ngIf="!requestData?.due_date"
              >
                -
              </h6>
            </div>
          </div>
          <div
            class="p-5 bg-white rounded-lg drop-shadow-md dark:bg-gray-800 dark:border-gray-700"
          >
            <h5
              class="text-xs 2xl:text-sm font-bold tracking-tight text-black dark:text-white"
            >
              Angeforderte Daten hochgeladen
            </h5>
            <div class="mt-2 flex justify-between">
              <h6
                class="text-base font-semibold tracking-tight text-black dark:text-white"
              >
                {{ completedSections }} / {{ requestData?.sections?.length }}
              </h6>
              <span
                class="inline-flex items-center text-darkRed bg-semantic-danger-50 text-semantic-danger-500 text-xs text-center font-medium px-2.5 py-0.5 rounded-full"
                *ngIf="errorCount"
              >
                <span
                  class="w-2 h-2 me-1 bg-semantic-danger-500 rounded-full"
                ></span>
                {{ errorCount }} Fehler
              </span>
            </div>
          </div>
          <div
            class="p-5 bg-white rounded-lg drop-shadow-md dark:bg-gray-800 dark:border-gray-700"
          >
            <h5
              class="text-xs 2xl:text-sm font-bold tracking-tight text-black dark:text-white"
            >
              Auftraggeber
            </h5>
            <div class="mt-2 flex justify-between">
              <h6
                class="text-base font-semibold tracking-tight text-black dark:text-white"
              >
                {{ requestData?.client?.company_name }}
              </h6>
            </div>
          </div>
          <div
            class="p-5 bg-white rounded-lg drop-shadow-md dark:bg-gray-800 dark:border-gray-700"
          >
            <h5
              class="text-xs 2xl:text-sm font-bold tracking-tight text-black dark:text-white"
            >
              Kunde
            </h5>
            <div class="mt-2 flex justify-between">
              <h6
                class="text-base font-semibold tracking-tight text-black dark:text-white"
              >
                {{ requestData?.client?.customer?.label }}
              </h6>
            </div>
          </div>
        </div>

        <h4
          class="font-semibold text-lg text-black text-justify collapsable-item"
          [ngClass]="{ collapsed: collapseTopBar }"
        >
          Bitte laden Sie alle unten angeforderten Daten hoch. Ihre Daten werden
          vollautomatisch mit unserem Datencheck überprüft. Sobald alle Daten
          hochgeladen wurden und keine Fehler gefunden wurden drücken Sie auf
          Antwort senden um Ihre Daten abzuschicken.
        </h4>

        <!--        action buttons here changes if the user is logged in-->
        <div class="flex w-full" *ngIf="!authService.isAuthenticated.value">
          <button
            *ngIf="!producerHash && requestData?.request_status_id !== 4"
            type="button"
            (click)="submitRequest()"
            [disabled]="
              isSubmitting ||
              !requestData ||
              requestData?.request_status_id !== 1 ||
              !hasValidFiles() ||
              isPastDueDate
            "
            class="transition-all duration-200 text-white mt-3 w-full bg-primaryBtn font-semibold disabled:bg-gray-400 hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
          >
            Antwort senden
          </button>
          <div
            class="flex w-full"
            *ngIf="!producerHash && requestData?.request_status_id === 4"
          >
            <button
              type="button"
              (click)="rejectRequestAsClient()"
              [disabled]="
                isSubmitting ||
                !requestData ||
                requestData?.request_status_id !== 4
              "
              class="transition-all duration-200 text-primaryBtn mt-3 w-full border-primaryBtn border-2 font-semibold hover:text-white disabled:bg-gray-400 disabled:border-0 disabled:text-white hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            >
              Ablehnen und zurück zur Agentur
            </button>
            <button
              type="button"
              (click)="approveRequestAsClient()"
              [disabled]="
                isSubmitting ||
                !requestData ||
                requestData?.request_status_id !== 4
              "
              class="transition-all duration-200 text-white mt-3 w-full disabled:bg-gray-400 bg-primaryBtn font-semibold hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            >
              Bestätigen und weiter zum Kunden
            </button>
          </div>
          <div class="flex w-full" *ngIf="producerHash">
            <button
              type="button"
              (click)="rejectRequestAsProducer()"
              [disabled]="
                isSubmitting ||
                !requestData ||
                requestData?.request_status_id !== 3
              "
              class="transition-all duration-200 text-primaryBtn mt-3 w-full border-primaryBtn border-2 font-semibold hover:text-white disabled:bg-gray-400 disabled:border-0 disabled:text-white hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            >
              Ablehnen und zurück zur Agentur
            </button>
            <button
              type="button"
              (click)="approveRequestAsProducer()"
              [disabled]="
                isSubmitting ||
                !requestData ||
                requestData?.request_status_id !== 3
              "
              class="transition-all duration-200 text-white mt-3 w-full disabled:bg-gray-400 bg-primaryBtn font-semibold hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
            >
              Bestätigen und weiter zum Kunden
            </button>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 px-2 lg:px-0 gap-2"
          *ngIf="authService.isAuthenticated.value"
        >
          <button
            type="button"
            (click)="rejectRequest()"
            [disabled]="
              isSubmitting ||
              !requestData ||
              requestData?.request_status_id !== 2
            "
            class="transition-all duration-200 text-primaryBtn mt-3 w-full border-primaryBtn border-2 font-semibold hover:text-white disabled:bg-gray-400 disabled:border-0 disabled:text-white hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
          >
            Ablehnen und zurück zum Kunden
          </button>
          <button
            *ngIf="
              customerSettingsService.currentSettings.value.enable_producers
            "
            type="button"
            (click)="openProducerPickerModal()"
            [disabled]="
              isSubmitting ||
              !requestData ||
              requestData?.request_status_id !== 2
            "
            class="transition-all duration-200 text-white mt-3 w-full disabled:bg-gray-400 bg-primaryBtn font-semibold hover:bg-primaryBtnHover rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
          >
            Bestätigen and weiter zum Produzen
          </button>
          <button
            type="button"
            (click)="directlyApproveRequestAsCustomer()"
            [disabled]="
              isSubmitting ||
              !requestData ||
              requestData?.request_status_id !== 2
            "
            class="transition-all duration-200 text-white mt-3 w-full disabled:bg-gray-400 bg-primary font-semibold hover:opacity-90 transition-all duration-300 rounded-lg text-xs 2xl:text-sm px-5 py-2.5 text-center inline-flex items-center justify-center me-2 dark:bg-primaryBtn dark:hover:bg-primaryBtn dark:focus:ring-primaryBtn"
          >
            Bestätigen und abschließen
          </button>
        </div>
      </div>
    </div>
    <div class="w-full container mx-auto">
      <div
        class="w-full mb-16 grid grid-cols-1 lg:grid-cols-2 gap-5 items-start"
      >
        <div
          class="w-full grid grid-cols-1 gap-8"
          *ngIf="requestData?.sections?.length"
        >
          <ng-container *ngFor="let section of requestData?.sections!">
            <app-request-section
              [section]="section"
              [requestPublicHash]="requestPublicHash"
              [sectionState]="sectionStates.get(section.id)"
              (removeFileEvent)="removeUploadedFile($event)"
            ></app-request-section>
          </ng-container>
        </div>
        <div class="flex items-center justify-center w-full sticky top-[250px]">
          <div
            class="flex flex-col items-center justify-center w-full min-h-[24rem] border-2 border-gray-300 border-dashed rounded-lg p-3 2xl:p-4"
          >
            <div
              class="flex flex-col items-center justify-center"
              *ngIf="!requestFilePreviewService.selectedFile"
            >
              <svg
                width="80"
                height="81"
                viewBox="0 0 80 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="bs:images">
                  <g id="Vector">
                    <path
                      d="M22.5098 45.5C24.4989 45.5 26.4065 44.7098 27.8131 43.3033C29.2196 41.8968 30.0098 39.9891 30.0098 38C30.0098 36.0109 29.2196 34.1032 27.8131 32.6967C26.4065 31.2902 24.4989 30.5 22.5098 30.5C20.5206 30.5 18.613 31.2902 17.2065 32.6967C15.7999 34.1032 15.0098 36.0109 15.0098 38C15.0098 39.9891 15.7999 41.8968 17.2065 43.3033C18.613 44.7098 20.5206 45.5 22.5098 45.5Z"
                      fill="#C4D2DB"
                    />
                    <path
                      d="M70.0098 65.5C70.0098 68.1522 68.9562 70.6957 67.0808 72.5711C65.2055 74.4464 62.6619 75.5 60.0098 75.5H10.0098C7.3576 75.5 4.81406 74.4464 2.9387 72.5711C1.06333 70.6957 0.00976563 68.1522 0.00976563 65.5V25.5C0.0097643 22.8496 1.06196 20.3075 2.93516 18.4325C4.80836 16.5574 7.34933 15.5027 9.99977 15.5C9.99977 12.8478 11.0533 10.3043 12.9287 8.42893C14.8041 6.55357 17.3476 5.5 19.9998 5.5H69.9998C72.6519 5.5 75.1955 6.55357 77.0708 8.42893C78.9462 10.3043 79.9998 12.8478 79.9998 15.5V55.5C79.9998 58.1504 78.9476 60.6925 77.0744 62.5675C75.2012 64.4426 72.6602 65.4973 70.0098 65.5ZM69.9998 10.5H19.9998C18.6737 10.5 17.4019 11.0268 16.4642 11.9645C15.5265 12.9021 14.9998 14.1739 14.9998 15.5H60.0098C62.6619 15.5 65.2055 16.5536 67.0808 18.4289C68.9562 20.3043 70.0098 22.8478 70.0098 25.5V60.5C71.3341 60.4974 72.6033 59.9694 73.5388 59.032C74.4744 58.0946 74.9998 56.8244 74.9998 55.5V15.5C74.9998 14.1739 74.473 12.9021 73.5353 11.9645C72.5976 11.0268 71.3259 10.5 69.9998 10.5ZM10.0098 20.5C8.68368 20.5 7.41191 21.0268 6.47423 21.9645C5.53655 22.9021 5.00977 24.1739 5.00977 25.5V65.5L18.2398 53.73C18.6484 53.3228 19.1853 53.0695 19.7595 53.013C20.3336 52.9565 20.9096 53.1003 21.3898 53.42L34.6898 62.285L53.2398 43.735C53.6105 43.3639 54.0886 43.1188 54.6064 43.0344C55.1242 42.9501 55.6554 43.0307 56.1248 43.265L65.0098 53V25.5C65.0098 24.1739 64.483 22.9021 63.5453 21.9645C62.6076 21.0268 61.3358 20.5 60.0098 20.5H10.0098Z"
                      fill="#C4D2DB"
                    />
                  </g>
                </g>
              </svg>
              <h3 class="text-3xl text-gray-300 font-bold mt-5">Vorschau</h3>
              <h4 class="text-lg text-gray-300 font-bold mt-2">
                Sie haben noch keine Daten hochgeladen
              </h4>
            </div>
            <div
              class="flex flex-col items-center justify-center"
              *ngIf="requestFilePreviewService.selectedFile"
            >
              <img [src]="requestFilePreviewService.selectedFile.preview_url" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-file-upload-modal></app-file-upload-modal>
<app-comments-modal></app-comments-modal>
<app-producer-picker-modal></app-producer-picker-modal>
<app-reject-request-modal></app-reject-request-modal>
