<footer
  [ngClass]="{'bg-white text-primary border-gray7 border-dashed': lightFooter, 'bg-primary text-white border-gray-200': !lightFooter, 'border-t': !isMinimal}"
  class="mt-8 relative bottom-0 left-0 z-20 w-full p-3 xl:p-0 shadow md:flex md:items-center md:justify-between md:p-6">
  <div class="pt-10 w-full">
    <div class="container mx-auto sm:flex sm:items-center sm:justify-between" *ngIf="!isMinimal">
      <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
        <img *ngIf="!lightFooter" src="assets/imgs/ahoidata_footer.svg" class="h-16" alt="AHOIDATA Logo"/>
        <img *ngIf="lightFooter" src="assets/imgs/ahoidata_footer_black.svg" class="h-16" alt="AHOIDATA Logo"/>
      </a>
      <div class=" text-xs 2xl:text-sm">
        <h5 class="text-lg font-black">Rentation GmbH</h5>
        <h6>creativehub</h6>
        <h6>Kienzlstraße 17</h6>
        <h6>A-4600 Wels</h6>
      </div>
      <div class=" text-xs 2xl:text-sm">
        <h5 class="text-lg font-black" transloco="Geschäftsführung">Geschäftsführung</h5>
        <h6>Manfred Nareyka</h6>
        <h6>Markus Tückmantel</h6>
      </div>
      <div class=" text-xs 2xl:text-sm">
        <h6 transloco="UID Nummer ATU75330125">UID Nummer ATU75330125</h6>
        <h6 transloco="Firmenbuchnummer FN 530129 b">Firmenbuchnummer FN 530129 b</h6>
        <h6 transloco="Firmenbuchgericht Landesgericht Wels">Firmenbuchgericht Landesgericht Wels</h6>
        <h6 transloco="Finanzamt Grieskirchen Wels<">Finanzamt Grieskirchen Wels<</h6>
      </div>
    </div>
    <div class="flex justify-center items-start mt-8 opacity-[0.8]  py-4" [ngClass]="{'bg-gray10': lightFooter}">
      <div class=" text-xs 2xl:text-sm">
        <a href="https://rentation.net/impressum.html" transloco="Impressum">Impressum</a>
      </div>
      <div class=" text-xs 2xl:text-sm mx-4">|</div>
      <div class=" text-xs 2xl:text-sm">
        <a href="https://rentation.net/doc/rentation_agb.pdf" transloco="AGB">AGB</a>
      </div>
      <div class=" text-xs 2xl:text-sm mx-4">|</div>
      <div class=" text-xs 2xl:text-sm">
        <a href="https://rentation.net/doc/rentation_datenschutz.pdf" transloco="Datenschutz">Datenschutz</a>
      </div>
    </div>
  </div>
</footer>
