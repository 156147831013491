import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerSettingsService } from '../backend-services/customer-settings.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss'],
})
export class CustomerSettingsComponent {
  customerSettingsForm = new FormGroup({
    enable_producers: new FormControl(false),
  });

  constructor(
    private customerSettingsService: CustomerSettingsService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.customerSettingsService.currentSettings.subscribe((settings) => {
      this.customerSettingsForm.setValue(settings);
    });
  }

  handleSubmit() {
    this.customerSettingsService
      .submitSettings(this.customerSettingsForm.value)
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translocoService.translate(`Settings Updated`)
          );
        },
        error: ({ error }) =>
          this.toastr.error(error?.message || JSON.stringify(error)),
      });
  }
}
