import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsSuperAdminDirective } from './is-super-admin.directive';
import { HasCapabilityDirective } from './has-capability.directive';



@NgModule({
  declarations: [IsSuperAdminDirective, HasCapabilityDirective],
  exports: [IsSuperAdminDirective, HasCapabilityDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}
