import { Component, OnInit } from '@angular/core';
import {RequestViewPageService} from "../request-view-page.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-selected-section-file-history',
  templateUrl: './selected-section-file-history.component.html',
  styleUrls: ['./selected-section-file-history.component.scss']
})
export class SelectedSectionFileHistoryComponent {

  constructor(public requestViewPageService: RequestViewPageService, public sanitizer: DomSanitizer) { }

  formatedFileName(str: string): string {
    if (str.length > 100) {
      return str.substr(0, 98) + '...';
    }else {
      return str
    }
  }

  shouldShowStatus(statusId: number): boolean {
    if (statusId === 202 || statusId === 302 || statusId === 4 || statusId === 402) {
      return true;
    }

    return false;
  }
}
