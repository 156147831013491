import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackageUpgradeViewComponent } from './package-upgrade-view/package-upgrade-view.component';
import {PipesModule} from "../pipes/pipes.module";
import {MatIconModule} from "@angular/material/icon";
import {TranslocoModule} from "@ngneat/transloco";
import {DirectivesModule} from "../directives/directives.module";



@NgModule({
  declarations: [PackageUpgradeViewComponent],
  imports: [
    CommonModule,
    PipesModule,
    MatIconModule,
    TranslocoModule,
    DirectivesModule,
  ],
})
export class PackageManagementModule {}
