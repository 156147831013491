import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { ComponentsModule } from './layouts/admin-layout/components/components.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './account/profile/profile.component';
import { SettingsComponent } from './account/settings/settings.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BackendServicesModule } from './backend-services/backend-services.module';
import { AuthModule } from './auth/auth.module';
import { UsersListViewPageComponent } from './user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from './user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from './user-management/customer/manage-single-customer.component';
import { RoleNameDisplayComponent } from './user-management/role-name-display/role-name-display.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { DirectivesModule } from './directives/directives.module';
import { ClientManagementModule } from './client-management/client-management.module';
import { RequestManagementModule } from './request-management/request-management.module';
import { RequestViewPageComponent } from './request-view-page/request-view-page.component';
import { RequestSectionComponent } from './request-view-page/request-section/request-section.component';
import { RequestCommentsComponent } from './request-view-page/request-comments/request-comments.component';
import { FileUploadModalComponent } from './request-view-page/file-upload-modal/file-upload-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommentsModalComponent } from './request-view-page/comments-modal/comments-modal.component';
import { PipesModule } from './pipes/pipes.module';
import { ProducerPickerModalComponent } from './request-view-page/producer-picker-modal/producer-picker-modal.component';
import { RejectRequestModalComponent } from './request-view-page/reject-request-modal/reject-request-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { RequestViewPageV4Component } from './request-view-page-v4/request-view-page-v4.component';
import { RequestViewPageHeaderComponent } from './request-view-page-v4/request-view-page-header/request-view-page-header.component';
import { SectionListItemComponent } from './request-view-page-v4/section-list-item/section-list-item.component';
import { FileUploadModalV4Component } from './request-view-page-v4/file-upload-modal-v4/file-upload-modal-v4.component';
import { UploadCancelConfirmModalComponent } from './request-view-page-v4/file-upload-modal-v4/upload-cancel-confirm-modal/upload-cancel-confirm-modal.component';
import { UploadedFilePreviewSectionComponent } from './request-view-page-v4/uploaded-file-preview-section/uploaded-file-preview-section.component';
import { UploadedFileValidationDataComponent } from './request-view-page-v4/uploaded-file-validation-data/uploaded-file-validation-data.component';
import { FileDeleteConfirmationModalComponent } from './request-view-page-v4/file-delete-confirmation-modal/file-delete-confirmation-modal.component';
import { FloatingNavigatorComponent } from './request-view-page-v4/floating-navigator/floating-navigator.component';
import { FileSectionActionsBarComponent } from './request-view-page-v4/file-section-actions-bar/file-section-actions-bar.component';
import { SectionApprovalModalComponent } from './request-view-page-v4/section-approval-modal/section-approval-modal.component';
import { SectionRejectionModalComponent } from './request-view-page-v4/section-rejection-modal/section-rejection-modal.component';
import { SelectedSectionCommentsComponent } from './request-view-page-v4/selected-section-comments/selected-section-comments.component';
import { SelectedSectionFileHistoryComponent } from './request-view-page-v4/selected-section-file-history/selected-section-file-history.component';
import { SelectedSectionInfoFilesComponent } from './request-view-page-v4/selected-section-info-files/selected-section-info-files.component';
import { ProducerPickerModalV4Component } from './request-view-page-v4/producer-picker-modal-v4/producer-picker-modal-v4.component';
import { UploadProofModalComponent } from './request-view-page-v4/upload-proof-modal/upload-proof-modal.component';
import { SelectedSectionHeaderComponent } from './request-view-page-v4/selected-section-header/selected-section-header.component';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';
import {PackageManagementModule} from "./package-management/package-management.module";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    DashboardComponent,
    ProfileComponent,
    SettingsComponent,
    PageNotFoundComponent,
    UsersListViewPageComponent,
    ManageSingleUserComponent,
    ManageSingleCustomerComponent,
    RoleNameDisplayComponent,
    RequestViewPageComponent,
    RequestSectionComponent,
    RequestCommentsComponent,
    FileUploadModalComponent,
    CommentsModalComponent,
    ProducerPickerModalComponent,
    RejectRequestModalComponent,
    CustomerSettingsComponent,
    RequestViewPageV4Component,
    RequestViewPageHeaderComponent,
    SectionListItemComponent,
    FileUploadModalV4Component,
    UploadCancelConfirmModalComponent,
    UploadedFilePreviewSectionComponent,
    UploadedFileValidationDataComponent,
    FileDeleteConfirmationModalComponent,
    FloatingNavigatorComponent,
    FileSectionActionsBarComponent,
    SectionApprovalModalComponent,
    SectionRejectionModalComponent,
    SelectedSectionCommentsComponent,
    SelectedSectionFileHistoryComponent,
    SelectedSectionInfoFilesComponent,
    ProducerPickerModalV4Component,
    UploadProofModalComponent,
    SelectedSectionHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminLayoutModule,
    ComponentsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule,
    BackendServicesModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      newestOnTop: true,
      timeOut: 4000,
      positionClass: 'toast-top-right',
    }),
    SharedComponentsModule,
    DirectivesModule,
    ClientManagementModule,
    RequestManagementModule,
    ClipboardModule,
    PipesModule,
    MatProgressSpinnerModule,
    TranslocoRootModule,
    TranslocoModule,
    PackageManagementModule
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  bootstrap: [AppComponent],
})
export class AppModule {}
