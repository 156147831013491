import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ClientService } from '../../backend-services/client.service';
import { ToastrService } from 'ngx-toastr';
import { AdminRoutes } from '../../layouts/admin-layout/admin-routes';
import { EditClientModalService } from '../edit-client-modal/edit-client-modal.service';
import { EditRequestModalService } from '../../request-management/edit-request-modal/edit-request-modal.service';
import {AppRequest, RequestService} from 'src/app/backend-services/request.service';
import {debounceTime, distinctUntilChanged} from "rxjs";
import {FormControl} from "@angular/forms";
import {SendRequestModalService} from "../../request-management/send-request-modal/send-request-modal.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-client-detail-view-page',
  templateUrl: './client-detail-view-page.component.html',
  styleUrls: ['./client-detail-view-page.component.scss'],
})
export class ClientDetailViewPageComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private router: Router,
    private editClientModalService: EditClientModalService,
    private editRequestModalService: EditRequestModalService,
    private sendRequestModalService: SendRequestModalService,
    private clipboard: Clipboard,
    private translocoService: TranslocoService
  ) {}

  isProducer = false;

  clientId: number = parseInt(this.activatedRoute.snapshot.params.id);
  client?: Client;
  requests: AppRequest[] = [];
  totalRequestsCount = 0;

  searchControl = new FormControl('');

  private subscriptions: any[] = [];
  queryParams: any = {};

  ngOnInit() {
    this.isProducer = window.location.pathname.startsWith('/producers');

    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe({
        next: (params) => {
          const id = params.get('id');

          if (id) {
            this.clientId = parseInt(id);
            this.fetchClient();
            this.fetchRequestsForClient();
          }
        },
      })
    );

    this.activatedRoute.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      this.queryParams = queryParams;

      this.searchControl.setValue(this.queryParams.search);

      this.fetchRequestsForClient();
    });

    // listen to changes in the search control
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.queryParams.search = value;
        } else {
          this.queryParams.search = undefined;
        }

        this.handleQueryParamChange();
      });

    this.subscriptions.push(
      this.editClientModalService.modalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchClient();
        }
      })
    );
    this.subscriptions.push(
      this.editRequestModalService.modalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchRequestsForClient();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  fetchClient() {
    this.clientService.getClientById(this.clientId).subscribe({
      next: (res) => {
        // set the form data
        this.client = res.data;
      },
      error: (res) => {
        console.log('err', res.error);
        this.toastr.error(res.error?.message || this.translocoService.translate(`Something went wrong`));
      },
    });
  }

  fetchRequestsForClient() {
    let params = this.queryParams;
    params.client_id = this.clientId;

    this.requestService.getRequests(params).subscribe({
      next: (res) => {
        this.requests = res.data;
        this.totalRequestsCount = res.total;
      },
      error: (res) => {
        console.log('err', res.error);
        this.toastr.error(res.error?.message || this.translocoService.translate(`Something went wrong`));
      },
    });
  }

  deleteClient() {
    this.clientService.deleteClient(this.clientId).subscribe({
      next: () => {
        this.toastr.success(this.translocoService.translate(`Client Deleted`));
        this.router.navigate([AdminRoutes.ClientManagement]);
      },
      error: (res) => {
        console.log('err', res.error);
        this.toastr.error(res.error?.message || this.translocoService.translate(`Something went wrong`));
      },
    });
  }

  editClient() {
    this.editClientModalService.modalEvents.next({
      type: 'edit',
      id: this.clientId,
    });
  }

  addNewRequest() {
    this.router.navigate(['requests/new'], {queryParams: {'client_id': this.clientId}} );
  }

  doSearch() {
    if (this.searchControl.value) {
      this.queryParams.search = this.searchControl.value;
    } else {
      this.queryParams.search = undefined;
    }

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  editRequest(id: number) {
    this.editRequestModalService.modalEvents.next({ type: 'edit', id });
  }

  openSendModal(request: AppRequest) {
    this.sendRequestModalService.modalEvents.next({
      type: 'open',
      request,
    });
  }

  copyPublicLinkToClipboard(hash: string) {
    const url = `${window.origin}/request/${hash}`;
    this.clipboard.copy(url);
    this.toastr.info(this.translocoService.translate(`Link copied`));
  }


}
