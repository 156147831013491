import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import {
  ClientContact,
  ClientService,
  CreateClientContact,
} from '../../backend-services/client.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/backend-services/auth.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { ClientSuccessorPickerModalService } from '../clients-list-view-page/client-successor-picker-modal.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class EditClientModalService {
  public readonly modalEvents = new Subject<
    | { type: 'new' }
    | { type: 'edit'; id: number }
    | { type: 'close' }
    | { type: 'edit-no-open'; id: number }
  >();

  public readonly contactModalEvents = new Subject<
    { type: 'new' } | { type: 'edit'; id: number } | { type: 'close' }
  >();

  currentClientId?: number;
  currentContactId?: number;
  editNoOpenMode = false;

  contactFormInitialValue: any = undefined;
  clientInitialValue: any = undefined;
  customerList: Customer[] = [];
  isProducer = false;

  constructor(
    private clientService: ClientService,
    private toastr: ToastrService,
    private authService: AuthService,
    private customerService: CustomerService,
    private clientSuccessorPickerModalService: ClientSuccessorPickerModalService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe({
      next: (ev) => {
        switch (ev.type) {
          case 'new':
            this.editNoOpenMode = false;
            // reset all data
            this.resetData();

            this.contactFormInitialValue = this.contactForm.value;
            this.clientInitialValue = {
              company_name: this.companyNameControl.value || '',
              contacts: this.contacts,
            };
            this.isProducer = window.location.pathname.startsWith('/producers');

            break;
          case 'edit-no-open':
            this.editNoOpenMode = true;
            this.resetData();
            this.currentClientId = ev.id;
            this.fetchClientData();

            this.isProducer = window.location.pathname.startsWith('/producers');
            break;
          case 'edit':
            this.editNoOpenMode = false;
            this.resetData();
            this.currentClientId = ev.id;
            this.fetchClientData();

            this.isProducer = window.location.pathname.startsWith('/producers');
            break;

          case 'close':
            this.editNoOpenMode = false;
            this.resetData();
            break;
        }
      },
    });

    this.contactModalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
          this.contactForm.reset();
          this.currentContactId = undefined;
          this.contactFormInitialValue = this.contactForm.value;
          break;
        case 'edit':
          this.contactForm.reset();
          this.currentContactId = event.id;

          // fill the data if contact id is present
          if (this.currentClientId && this.currentContactId) {
            this.contactForm.disable();
            // fetch data from api
            this.clientService
              .getClientContactById(this.currentClientId, this.currentContactId)
              .subscribe({
                next: (res) => {
                  // set the form data
                  this.salutationControl.setValue(res.data.salutation);
                  this.firstNameControl.setValue(res.data.first_name);
                  this.lastNameControl.setValue(res.data.last_name);
                  this.emailControl.setValue(res.data.email);

                  this.contactFormInitialValue = this.contactForm.value;

                  this.contactForm.enable();
                },
                error: (res) => {
                  console.log('err', res.error);
                  this.toastr.error(
                    res.error?.error || this.translocoService.translate(`Something went wrong`)
                  );
                  this.contactForm.enable();
                  this.contactModalEvents.next({ type: 'close' });
                },
              });
          } else if (this.currentContactId != undefined) {
            //edit the local contact
            const contact = this.contacts[this.currentContactId];
            if (!contact) {
              this.toastr.error(this.translocoService.translate(`Contact not found`));
              this.contactModalEvents.next({ type: 'close' });
            }

            // set the form data
            this.salutationControl.setValue(contact.salutation);
            this.firstNameControl.setValue(contact.first_name);
            this.lastNameControl.setValue(contact.last_name);
            this.emailControl.setValue(contact.email);
          }

          break;

        case 'close':
          this.contactForm.reset();
          this.contactFormInitialValue = undefined;
          this.fetchClientData();
      }
    });

    this.clientSuccessorPickerModalService.modalEvents.subscribe((event) => {
      if (event.type === 'close') {
        this.fetchClientData();
      }
    });

    this.authService.isSuperAdmin.subscribe({
      next: (isSuperAdmin) => {
        // get customer list if logged in user is SuperAdmin
        if (isSuperAdmin) {
          this.customerService.getCustomers().subscribe({
            next: (res) => {
              if (res.success) {
                this.customerList = res.data;
              }
            },
          });
        }
      },
    });

    // trim whitespaces for the email field
    this.emailControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.emailControl.setValue(value.trim());
        }
      });
  }

  clientForm = new FormGroup({
    company_name: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
  });

  contactForm = new FormGroup({
    salutation: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(255),
    ]),
  });

  customerIdControl = new FormControl('', [Validators.required]);

  contacts: CreateClientContact[] | ClientContact[] = [];
  showContactRequiredErr = false;

  fetchClientData() {
    if (this.currentClientId) {
      this.clientForm.disable();
      this.clientService.getClientById(this.currentClientId).subscribe({
        next: (res) => {
          this.companyNameControl.setValue(res.data.company_name);
          if (res.data.contacts) {
            this.contacts = res.data.contacts;
          }
          this.customerIdControl.setValue(res.data.customer_id);
          this.clientForm.enable();

          this.contactFormInitialValue = this.contactForm.value;
          this.clientInitialValue = {
            company_name: this.companyNameControl.value,
            contacts: this.contacts,
          };
        },
        error: (res) => {
          console.log('err', res.error);
          this.toastr.error(
            res.error?.error || this.translocoService.translate(`Something went wrong`)
          );
          this.clientForm.enable();
        },
      });
    }
  }

  get salutationControl() {
    return this.contactForm.get('salutation') as AbstractControl;
  }

  get firstNameControl() {
    return this.contactForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.contactForm.get('last_name') as AbstractControl;
  }

  get emailControl() {
    return this.contactForm.get('email') as AbstractControl;
  }

  get companyNameControl() {
    return this.clientForm.get('company_name') as AbstractControl;
  }

  resetData() {
    this.contactForm.reset();
    this.clientForm.reset();
    this.contacts = [];
    this.customerIdControl.reset();

    this.currentContactId = undefined;
    this.currentClientId = undefined;

    this.clientInitialValue = undefined;
    this.contactFormInitialValue = undefined;
    this.showContactRequiredErr = false;
  }

  handleContactFormSubmit() {
    this.contactForm.markAllAsTouched();

    if (!this.contactForm.valid) {
      return;
    }

    if (this.currentClientId && this.currentContactId) {
      // call the api to save the new contact details
      this.contactForm.disable();
      this.clientService
        .updateClientContact(
          this.currentClientId,
          this.currentContactId,
          this.contactForm.value
        )
        .subscribe({
          next: () => {
            this.toastr.success(this.translocoService.translate(`The contact has been updated!`));
            this.fetchClientData();
            this.contactModalEvents.next({ type: 'close' });
            this.contactForm.enable();
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error || this.translocoService.translate(`Something went wrong`)
            );
            this.contactForm.enable();
          },
        });
    } else if (this.currentClientId && this.currentContactId == undefined) {
      // add the new contact to the client
      this.contactForm.disable();
      this.clientService
        .createClientContact(this.currentClientId, this.contactForm.value)
        .subscribe({
          next: () => {
            this.toastr.success(this.translocoService.translate(`The contact has been created!`));
            this.fetchClientData();
            this.contactModalEvents.next({ type: 'close' });
            this.contactForm.enable();
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error || this.translocoService.translate(`Something went wrong`)
            );
            this.contactForm.enable();
          },
        });
    } else if (
      this.currentClientId == undefined &&
      this.currentContactId != undefined
    ) {
      // modify the contact in local array
      this.contacts[this.currentContactId] = this.contactForm.value;
      this.contactModalEvents.next({ type: 'close' });
    } else {
      // creating a new contact, submition will be handled on client modal
      this.contacts.push(this.contactForm.value);
      this.contactForm.reset();

      this.contactModalEvents.next({ type: 'close' });
    }
  }

  handleClientFormSubmit() {
    this.clientForm.markAllAsTouched();
    this.customerIdControl.markAsTouched();
    this.showContactRequiredErr = true;

    if (this.clientForm.invalid || !this.contacts.length) {
      return;
    }

    if (this.authService.isSuperAdmin.value && this.customerIdControl.invalid) {
      return;
    }

    if (!this.currentClientId) {
      const data: any = {
        company_name: this.companyNameControl.value,
        contacts: this.contacts,
      };

      if (this.isProducer) {
        data.is_producer = true;
      }

      if (this.authService.isSuperAdmin.value) {
        data.customer_id = this.customerIdControl.value;
      }

      // creating a new client
      this.clientForm.disable();
      this.clientService.createClient(data).subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate(`The client has been created!`));
          this.modalEvents.next({ type: 'close' });
          this.clientForm.enable();
        },
        error: (res) => {
          console.log('err', res.error);
          this.toastr.error(
            res.error?.error || this.translocoService.translate(`Something went wrong`)
          );
          this.clientForm.enable();
        },
      });
    } else {
      // update client
      this.clientService
        .updateClient(this.currentClientId, {
          company_name: this.companyNameControl.value,
        })
        .subscribe({
          next: () => {
            this.toastr.success(this.translocoService.translate(`The client has been updated!`));
            this.modalEvents.next({ type: 'close' });
            this.clientForm.enable();
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error || this.translocoService.translate(`Something went wrong`)
            );
            this.clientForm.enable();
          },
        });
    }
  }

  deleteClient() {
    if (this.currentClientId) {
      this.clientForm.disable();

      this.clientService.deleteClient(this.currentClientId).subscribe({
        next: () => {
          this.toastr.success(this.translocoService.translate(`The client has been deleted!`));
          this.modalEvents.next({ type: 'close' });
          this.clientForm.enable();
        },
        error: (res) => {
          console.log('err', res.error);
          this.toastr.error(
            res.error?.error || this.translocoService.translate(`Something went wrong`)
          );
          this.clientForm.enable();
        },
      });
    }
  }

  deleteContact() {
    if (this.currentClientId && this.currentContactId) {
      this.clientSuccessorPickerModalService.modalEvents.next({ type: 'open' });
      this.contactModalEvents.next({ type: 'close' });

      this.currentContactId = undefined;
    } else if (this.currentContactId != undefined) {
      this.contacts.splice(this.currentContactId, 1);
      this.currentContactId = undefined;
      this.contactModalEvents.next({ type: 'close' });
    }
  }
}
