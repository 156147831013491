import { Component, OnInit } from '@angular/core';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { InfoUploadModalService } from '../info-upload-modal/info-upload-modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import {
  FileType,
  FileTypeService,
} from '../../../backend-services/file-type.service';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-selected-section-view',
  templateUrl: './selected-section-view.component.html',
  styleUrls: ['./selected-section-view.component.scss'],
})
export class SelectedSectionViewComponent {
  showCustomFileTypeForm = false;
  customExtensionControl = new FormControl('');
  fileTypeFilterText = new FormControl('');
  debouncedFilterText?: string = '';

  constructor(
    public editRequestModalService: EditRequestModalService,
    private infoUploadModalService: InfoUploadModalService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    public fileTypeService: FileTypeService
  ) {
    this.fileTypeFilterText.valueChanges
      .pipe(debounceTime(300))
      .subscribe((v: any) => (this.debouncedFilterText = v));
  }

  get selectedSectionGroup() {
    return this.editRequestModalService.sectionCollectionsFormArr.at(
      this.editRequestModalService.selectedCollectionIndex
    ) as FormGroup;
  }

  get selectedSection() {
    return (this.selectedSectionGroup.get('sections') as FormArray).at(
      this.editRequestModalService.selectedSectionIndex
    ) as FormGroup;
  }

  get currentInfoFiles() {
    return this.selectedSection.get('info_files') as FormArray;
  }

  toggleUploadInfoPDFModal() {
    this.infoUploadModalService.modalEvents.next({
      type: 'open',
      sectionIndex: this.editRequestModalService.selectedSectionIndex,
      sectionCollectionIndex:
        this.editRequestModalService.selectedCollectionIndex,
    });
  }

  public canAddInfoFiles() {
    return (this.selectedSection.controls.info_files as FormArray).length < 4;
  }

  public removeInfoFile(infoFileIndex: number) {
    (this.selectedSection.controls.info_files as FormArray).removeAt(
      infoFileIndex
    );
  }

  initAddCustomFileType() {
    this.showCustomFileTypeForm = true;
  }

  selectFileType(fileTypeId: number) {
    this.resetCustomFileTypeForm();
    this.selectedSection.get('expected_file_picker')!.setValue(fileTypeId);
  }

  resetCustomFileTypeForm() {
    this.showCustomFileTypeForm = false;
    this.customExtensionControl.setValue('');
  }

  addCustomFileType() {
    const customExtension = this.customExtensionControl.value;
    if (customExtension) {
      (this.selectedSection.get('expected_files') as FormArray).insert(
        0,
        this.editRequestModalService.buildExpectedFileFormGroup(
          undefined,
          undefined,
          { is_custom_type: true, name: customExtension }
        )
      );
      this.resetCustomFileTypeForm();
    } else {
      this.toastr.error('File extension is required');
    }
  }

  public remainingExpectedFileTypeList(filterText?: string) {
    const groupedMap: Map<string, FileType[]> = new Map();

    this.editRequestModalService.availableFileTypes
      .filter(
        (fileType) =>
          this.selectedSection
            .get('expected_files')
            ?.value.find(
              (expectedFile: any) => expectedFile.file_type_id === fileType.id
            ) === undefined &&
          (filterText
            ? fileType.mime_type
                ?.toLowerCase()
                .includes(filterText.toLowerCase()) ||
              fileType.name.toLowerCase().includes(filterText.toLowerCase()) ||
              fileType.group_name
                .toLowerCase()
                .includes(filterText.toLowerCase())
            : true)
      )
      .forEach((fileType) => {
        if (groupedMap.has(fileType.group_name)) {
          groupedMap.get(fileType.group_name)!.push(fileType);
        } else {
          groupedMap.set(fileType.group_name, [fileType]);
        }
      });

    return groupedMap;
  }

  public removeExpectedFile(expectedFileIndex: number) {
    (this.selectedSection.controls.expected_files as FormArray).removeAt(
      expectedFileIndex
    );
  }
}
