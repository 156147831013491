import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileDeleteConfirmationModalService } from './file-delete-confirmation-modal.service';

@Component({
  selector: 'app-file-delete-confirmation-modal',
  templateUrl: './file-delete-confirmation-modal.component.html',
  styleUrls: ['./file-delete-confirmation-modal.component.scss'],
})
export class FileDeleteConfirmationModalComponent {
  @ViewChild('toggleFileDeleteConfirmModalBtn')
  toggleFileDeleteConfirmModalBtn?: ElementRef;
  @ViewChild('showFileDeleteConfirmModalBtn')
  showFileDeleteConfirmModalBtn?: ElementRef;
  @ViewChild('hideFileDeleteConfirmModalBtn')
  hideFileDeleteConfirmModalBtn?: ElementRef;

  constructor(
    public fileDeleteConfirmationModalService: FileDeleteConfirmationModalService
  ) {
    this.fileDeleteConfirmationModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showFileDeleteConfirmModalBtn!.nativeElement!.click();
          break;
        case 'close':
          this.hideFileDeleteConfirmModalBtn?.nativeElement!.click();
      }
    });
  }
}
