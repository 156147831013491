import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { PushNotificationsService } from '../push-notifications.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { CustomerService, License } from './customer.service';

export const AUTH_TOKEN_KEY = 'auth_token';

export type UserCapabilities = {
  EDIT_CUSTOMER?: string;
  MANAGE_USERS?: string;
  SET_PASSWORD?: string;
  EDIT_CLIENT?: string;
  MANAGE_CONTACTS?: string;
  EDIT_REQUEST?: string;
};

export type UserProfile = {
  id: number;
  created_at: string;
  customer_id?: number;
  email: string;
  email_verified_at?: string;
  enabled: boolean;
  first_name: string;
  last_name: string;
  role_id?: number;
  role?: {
    capabilities?: UserCapabilities;
  };
  updated_at: string;
};

@Injectable()
export class AuthService {
  public isAuthenticated = new BehaviorSubject(
    !!localStorage.getItem(AUTH_TOKEN_KEY)
  );
  public profile = new BehaviorSubject<UserProfile | undefined>(undefined);
  public license = new BehaviorSubject<License | undefined>(undefined);
  public isSuperAdmin = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private pushNotificationService: PushNotificationsService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private customerService: CustomerService
  ) {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
    if (authToken) {
      this.isAuthenticated.next(true);
      this.getProfile().subscribe();
      this.customerService.getCurrentCustomerLicense().subscribe((l: any) => {
        this.license.next(l.data);
      });
    }

    // subscribe to profile changes and see if user is super admin
    this.profile.subscribe({
      next: (profile) => {
        this.isSuperAdmin.next(!!profile && !profile.customer_id);
      },
    });
  }

  getProfile() {
    return this.http
      .get<{ success: boolean; data: UserProfile }>('/api/auth')
      .pipe((res) => {
        const output = new Subject();
        res.subscribe({
          next: (res) => {
            output.next(res);
            if (!res.success) {
              localStorage.removeItem(AUTH_TOKEN_KEY);
              localStorage.removeItem('');
              this.profile.next(undefined);
            } else {
              this.isAuthenticated.next(true);
              this.profile.next(res.data);

              // subscribe to push notifications
              this.pushNotificationService.subscribe();
            }
          },
          error: (err) => {
            if (err.status === 429) {
              this.toastrService.error(
                this.translocoService.translate(`Too many http requests`)
              );
            } else {
              output.error(err);
              this.isAuthenticated.next(false);
              localStorage.removeItem(AUTH_TOKEN_KEY);
              this.profile.next(undefined);
            }
          },
        });

        return output;
      });
  }

  login(data: { email: string; password: string }) {
    return this.http
      .post<{ success: boolean; data: string }>('/api/auth', data)
      .pipe((res) => {
        const output = new Subject<{
          success: boolean;
          data: string;
        }>();

        res.subscribe({
          next: (res) => {
            output.next(res);
            // save token in local storage
            if (res.success) {
              localStorage.setItem(AUTH_TOKEN_KEY, res.data);
              this.isAuthenticated.next(true);

              this.getProfile();
            }
          },
          error: (err) => output.error(err),
        });

        return output;
      });
  }

  logout() {
    this.http.delete('/api/auth').subscribe({});

    localStorage.removeItem(AUTH_TOKEN_KEY);
    this.isAuthenticated.next(false);

    location.reload();
  }

  forgotPassword(data: { email: string }) {
    const url = new URL(
      `${window.location.protocol}//${window.location.host}/reset-password`
    );
    url.searchParams.set('email', data.email);

    return this.http.post('/api/auth/password', {
      email: data.email,
      url: url.toString(),
    });
  }

  resetPasswordWithToken(data: { new_password: string; token: string }) {
    return this.http.put<{ success: boolean; data: string }>(
      '/api/auth/password',
      data
    );
  }

  updateProfile(data: {
    first_name: string;
    last_name: string;
    email: string;
  }) {
    // check if user profile exits or throw error
    if (!this.profile.value) {
      throw new Error(this.translocoService.translate(`'User not logged in'`));
    }

    return this.http
      .put<{ success: boolean }>(`/api/users/${this.profile.value!.id}`, data)
      .pipe((res) => {
        const output = new Subject();

        res.subscribe({
          next: (res) => {
            output.next(res);

            if (res.success) {
              this.getProfile();
            }
          },
          error: (err) => output.error(err),
        });

        return output;
      });
  }

  changePassword(data: { current_password: string; password: string }) {
    // check if user profile exits or throw error
    if (!this.profile.value) {
      throw new Error(this.translocoService.translate(`'User not logged in'`));
    }

    return this.http.put<{ success: boolean }>(
      `/api/users/${this.profile.value!.id}`,
      data
    );
  }
}
