import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  RequestComment,
  RequestService,
} from '../../backend-services/request.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';

const LocalCommentDataStoreKey = 'request_comment_data';

@Injectable({
  providedIn: 'root',
})
export class CommentsModalService {
  public readonly modalEvents = new Subject<
    | { type: 'open' }
    | { type: 'close' }
    | {
        type: 'add_comment';
        first_name: string;
        last_name: string;
        content: string;
      }
    | { type: 'refresh_data' }
    | {
        type: 'data_updated';
        request_hash: string;
        comments: RequestComment[];
      }
  >();

  comments: RequestComment[] = [];
  request_hash = '';
  showNameInput = true;

  // current form
  commentForm = new FormGroup({
    first_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    last_name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
    content: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
    ]),
  });

  constructor(
    private requestService: RequestService,
    private authService: AuthService
  ) {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'data_updated':
          this.comments = event.comments;
          this.request_hash = event.request_hash;
          this.getCommentUserData();
      }
    });
  }

  get firstNameControl() {
    return this.commentForm.get('first_name') as FormControl;
  }

  get lastNameControl() {
    return this.commentForm.get('last_name') as FormControl;
  }

  get contentControl() {
    return this.commentForm.get('content') as FormControl;
  }

  getCommentUserData() {
    const commentData = localStorage.getItem(LocalCommentDataStoreKey);

    // check if user is logged in
    if (this.authService.isAuthenticated && !!this.authService.profile.value) {
      const profile = this.authService.profile.value;
      this.firstNameControl.setValue(profile.first_name);
      this.lastNameControl.setValue(profile.last_name);
      this.showNameInput = false;
    } else if (commentData) {
      const data = JSON.parse(commentData);
      this.firstNameControl.setValue(data.first_name);
      this.lastNameControl.setValue(data.last_name);
      this.showNameInput = false;
    } else {
      this.showNameInput = true;
    }
  }

  resetCommentForm() {
    this.commentForm.reset();
    this.getCommentUserData();
  }

  submitComment() {
    this.commentForm.markAllAsTouched();

    if (!this.commentForm.valid) {
      return;
    }

    this.commentForm.disable({ emitEvent: true });

    const formData = this.commentForm.value;

    // save local values
    const localSaveData = JSON.stringify({
      first_name: formData.first_name,
      last_name: formData.last_name,
    });
    localStorage.setItem(LocalCommentDataStoreKey, localSaveData);

    this.requestService
      .addComment({ ...formData, request_hash: this.request_hash })
      .subscribe({
        next: (res) => {
          this.resetCommentForm();
          this.modalEvents.next({
            type: 'refresh_data',
          });
          this.commentForm.enable({ emitEvent: true });
        },
        error: (res) => {
          console.log(res);
          this.commentForm.enable({ emitEvent: true });
        },
      });
  }
}
