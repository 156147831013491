import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, tap } from 'rxjs';

export type Client = {
  id: number;
  company_name: string;
  customer?: {
    id: number;
    label: string;
  };
  contacts?: ClientContact[];
  created_at: string;
  updated_at: string;
  customer_id: number;
  open_requests?: number;
};

export type ClientContact = {
  id: number;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at?: string;
  updated_at?: string;
  client_id?: number;
};

export type CreateClientContact = {
  id?: number;
  salutation: string;
  first_name: string;
  last_name: string;
  email: string;
};

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {
    this.updateAvailableClients();
  }

  availableClients: Client[] = [];
  public readonly availableClientsEvents = new Subject<{ type: 'updated' }>();
  public localClientNameMap = new Map<number, string>();

  updateAvailableClients() {
    this.getClients({ _listing: 'full' }).subscribe((res) => {
      this.availableClients = res.data;
      this.availableClients.forEach((c) => {
        this.localClientNameMap.set(c.id, c.company_name);
      });
      this.availableClientsEvents.next({ type: 'updated' });
    });
  }

  getClients(params: any) {
    return this.http.get<{ success: boolean; total: number; data: Client[] }>(
      '/api/clients/',
      { params }
    );
  }

  getClientById(id: number) {
    return this.http.get<{ success: boolean; data: Client }>(
      `/api/clients/${id}`
    );
  }

  createClient(data: any) {
    return this.http
      .post<{ success: boolean; data: any }>('/api/clients', data)
      .pipe(
        tap((response) => {
          if (response.success) {
            this.updateAvailableClients();
          }
        })
      );
  }

  updateClient(id: number, data: any) {
    return this.http
      .put<{ success: boolean; data: any }>(`/api/clients/${id}`, data)
      .pipe(
        tap((response) => {
          if (response.success) {
            this.updateAvailableClients();
          }
        })
      );
  }

  deleteClient(id: number) {
    return this.http
      .delete<{ success: boolean; data: any }>(`/api/clients/${id}`)
      .pipe(
        tap((response) => {
          if (response.success) {
            this.updateAvailableClients();
          }
        })
      );
  }

  getClientContactById(clientId: number, contactId: number) {
    return this.http.get<{ success: boolean; data: ClientContact }>(
      `/api/clients/${clientId}/contacts/${contactId}`
    );
  }

  createClientContact(clientId: number, data: any) {
    return this.http
      .post<{ success: boolean; data: ClientContact }>(
        `/api/clients/${clientId}/contacts/`,
        data
      )
      .pipe(
        tap((response) => {
          if (response.success) {
            this.updateAvailableClients();
          }
        })
      );
  }

  updateClientContact(clientId: number, contactId: number, data: any) {
    return this.http.put<{ success: boolean; data: ClientContact }>(
      `/api/clients/${clientId}/contacts/${contactId}`,
      data
    );
  }

  deleteClientContact(clientId: number, contactId: number, successorId?: number) {
    return this.http.delete<{ success: boolean; data: ClientContact }>(
      `/api/clients/${clientId}/contacts/${contactId}?successorId=${successorId}`
    );
  }

  getLocalClientById(id: number) {
    return this.availableClients.find((c) => c.id == id);
  }
}
