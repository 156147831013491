import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SendRequestModalService } from './send-request-modal.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-send-request-modal',
  templateUrl: './send-request-modal.component.html',
  styleUrls: ['./send-request-modal.component.scss'],
})
export class SendRequestModalComponent implements OnInit {
  @ViewChild('toggleSendRequestModalBtn') toggleSendRequestModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('showSendRequestModalBtn') showSendRequestModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('hideSendRequestModalBtn') hideSendRequestModalBtn:
    | ElementRef
    | undefined;

  contactSelectInput = new FormControl('');
  emailList: string[] = [];

  constructor(public sendRequestModalService: SendRequestModalService) {
    this.sendRequestModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'open':
          this.showSendRequestModalBtn?.nativeElement?.click();
          break;
        case 'close':
          this.hideSendRequestModalBtn?.nativeElement?.click();
      }
    });

    this.contactSelectInput.valueChanges.subscribe((val) => {
      if (val) {
        this.contactSelectInput.setValue('');
        const existingList = this.sendRequestModalService.toControl.value;
        existingList.push(val);
        this.sendRequestModalService.toControl.setValue(existingList);
      }
    });

    this.sendRequestModalService.toControl.valueChanges.subscribe((v) => {
      this.emailList = v;
    });
  }

  ngOnInit(): void {}

  closeModal() {
    this.sendRequestModalService.modalEvents.next({ type: 'close' });
  }
}
