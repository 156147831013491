<app-accordian-item
  title="Infos und Vorlagen"
  iconSize="sm"
  *ngIf="requestViewPageService.selectedSection"
>
  <div class="heading ml-2 bg-gray8 rounded-lg px-2 py-1 text-xs">
    {{ requestViewPageService.selectedSection?.info_files?.length || 0 }}
  </div>
  <div class="flex flex-col gap-6 mt-6">
    <div
      *ngIf="!requestViewPageService.selectedSection?.info_files?.length"
      class="font-semibold text-13px text-primary opacity-50 leading-20.8px"
      transloco="Es gibt leider keine Vorlagen."
    >
      Es gibt leider keine Vorlagen.
    </div>
    <div
      class="flex w-full justify-between gap-3"
      *ngFor="
        let infoFile of requestViewPageService.selectedSection?.info_files
      "
    >
      <div class="flex gap-3">
        <div
          class="bg-gray10 p-2 rounded-lg flex justify-center items-center w-fit h-fit"
        >
          <img
            class="w-5 h-5"
            [src]="
              sanitizer.bypassSecurityTrustUrl(infoFile.fileType!.icon_svg!)
            "
            alt="{{ infoFile.fileType.name }}"
          />
        </div>
        <div class="flex-grow">
          <div class="font-semibold text-sm leading-22.4px text-primary">
            {{ infoFile.title }}
          </div>
          <div
            class="font-normal text-xs leading-19.2px text-primary opacity-70"
          >
            {{ infoFile.description }}
          </div>
        </div>
      </div>
      <a [href]="sanitizer.bypassSecurityTrustUrl(infoFile.url)" target="_blank">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H34C37.0376 0.5 39.5 2.96243 39.5 6V34C39.5 37.0376 37.0376 39.5 34 39.5H6C2.96243 39.5 0.5 37.0376 0.5 34V6Z"
            stroke="#BACFD7"
          />
          <path
            d="M10.625 22.375C10.7908 22.375 10.9497 22.4408 11.0669 22.5581C11.1842 22.6753 11.25 22.8342 11.25 23V26.125C11.25 26.4565 11.3817 26.7745 11.6161 27.0089C11.8505 27.2433 12.1685 27.375 12.5 27.375H27.5C27.8315 27.375 28.1495 27.2433 28.3839 27.0089C28.6183 26.7745 28.75 26.4565 28.75 26.125V23C28.75 22.8342 28.8158 22.6753 28.9331 22.5581C29.0503 22.4408 29.2092 22.375 29.375 22.375C29.5408 22.375 29.6997 22.4408 29.8169 22.5581C29.9342 22.6753 30 22.8342 30 23V26.125C30 26.788 29.7366 27.4239 29.2678 27.8928C28.7989 28.3616 28.163 28.625 27.5 28.625H12.5C11.837 28.625 11.2011 28.3616 10.7322 27.8928C10.2634 27.4239 10 26.788 10 26.125V23C10 22.8342 10.0658 22.6753 10.1831 22.5581C10.3003 22.4408 10.4592 22.375 10.625 22.375Z"
            fill="#97B7C4"
          />
          <path
            d="M19.5575 24.8175C19.6156 24.8757 19.6845 24.9219 19.7605 24.9534C19.8364 24.9849 19.9178 25.0011 20 25.0011C20.0822 25.0011 20.1636 24.9849 20.2395 24.9534C20.3155 24.9219 20.3844 24.8757 20.4425 24.8175L24.1925 21.0675C24.3099 20.9501 24.3758 20.791 24.3758 20.625C24.3758 20.459 24.3099 20.2999 24.1925 20.1825C24.0751 20.0651 23.916 19.9992 23.75 19.9992C23.584 19.9992 23.4249 20.0651 23.3075 20.1825L20.625 22.8663V11.875C20.625 11.7092 20.5592 11.5503 20.4419 11.4331C20.3247 11.3158 20.1658 11.25 20 11.25C19.8342 11.25 19.6753 11.3158 19.5581 11.4331C19.4408 11.5503 19.375 11.7092 19.375 11.875V22.8663L16.6925 20.1825C16.5751 20.0651 16.416 19.9992 16.25 19.9992C16.084 19.9992 15.9249 20.0651 15.8075 20.1825C15.6901 20.2999 15.6242 20.459 15.6242 20.625C15.6242 20.791 15.6901 20.9501 15.8075 21.0675L19.5575 24.8175Z"
            fill="#97B7C4"
          />
        </svg>
      </a>
    </div>
  </div>
</app-accordian-item>
