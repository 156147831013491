import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../../shared-components/custom-modal/custom-modal.component';
import { FormGroup } from '@angular/forms';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';

@Injectable({
  providedIn: 'root',
})
export class SectionCollectionEditModalService {
  public readonly modalEvents = new Subject<ModalEvents>();

  public collectionIndex: number;
  public sectionCollection?: FormGroup;

  constructor(private editRequestModalService: EditRequestModalService) {
    this.collectionIndex = 0;

    this.modalEvents.subscribe((ev) => {
      if (ev.type === 'init-close') {
        this.handleClose();
      }
    });
  }

  openModal(collectionIndex: number, sectionCollection: FormGroup) {
    this.collectionIndex = collectionIndex;
    this.sectionCollection = sectionCollection;

    this.modalEvents.next({ type: 'open' });
  }

  handleDelete() {
    this.editRequestModalService.removeSectionCollection(this.collectionIndex);
    this.handleClose();
  }

  handleClose() {
    this.modalEvents.next({ type: 'close' });
  }
}
