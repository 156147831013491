<button
  data-modal-target="section-approval-modal"
  data-modal-toggle="section-approval-modal"
  class="hidden"
  #toggleSectionApprovalModalBtn
></button>
<button
  data-modal-target="section-approval-modal"
  data-modal-show="section-approval-modal"
  class="hidden"
  #showSectionApprovalModalBtn
></button>
<button
  data-modal-target="section-approval-modal"
  data-modal-hide="section-approval-modal"
  class="hidden"
  #hideSectionApprovalModalBtn
></button>

<div
  aria-hidden="true"
  class="hidden overflow-y-auto fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full font-Montserrat"
  id="section-approval-modal"
  data-modal-backdrop="static"
  tabindex="-1"
>
  <div
    class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-2/4 max-w-[600px] max-h-full"
  >
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow-2xl px-7">
      <!-- Modal header -->
      <div class="flex flex-col py-8">
        <h1 class="font-bold text-xl leading-6 text-primary mb-12" transloco="Datei wirklich freigeben?">Datei wirklich freigeben?</h1>
        <div class="flex gap-3">
          <button class="flex-grow tw-btn-secondary-outline" (click)="closeModal()" transloco="Abbrechen">Abbrechen</button>
          <button class="flex-grow tw-btn-secondary" (click)="sectionApprovalModalService.approveCurrentSection()" transloco="Freigeben">Freigeben</button>
        </div>
      </div>
    </div>
  </div>
</div>
