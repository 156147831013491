import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { RouterModule } from '@angular/router';
import { BackendServicesModule } from '../../backend-services/backend-services.module';
import { ClientManagementModule } from '../../client-management/client-management.module';
import { RequestManagementModule } from '../../request-management/request-management.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    BackendServicesModule,
    ClientManagementModule,
    RequestManagementModule,
    TranslocoModule,
  ],
})
export class AdminLayoutModule {}
